<template>
    <svg version="1.1" id="MonitoringSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70 30" style="enable-background:new 0 0 70 30;" xml:space="preserve">
        <path class="st0" d="M6.14,28h58.72c0.44-1.5,0.79-3.58,0-5.58c-0.9-2.29-2.42-2.27-3.15-4.46c-0.94-2.87,1.17-4.4,0-6.69c-0.84-1.65-2.15-1.27-3.15-3.35c-0.71-1.48-0.25-2.09-1.05-3.35c-0.94-1.47-2.45-1.99-3.15-2.23c-1.07-0.37-2.41-0.56-5.24,0c-4.56,0.91-4.5,2.09-7.34,2.23C39.3,4.7,36.85,3.91,35.5,3.47c-1.26-0.41-2.35-0.89-4.19-1.12c-1.19-0.14-2.06-0.25-3.15,0C26.09,2.83,26,3.98,23.97,4.58c-1.45,0.43-2.69,0.24-4.19,0c-2.52-0.4-2.54-1.08-4.19-1.12c-2.82-0.06-4.88,1.88-5.24,2.23c-1.43,1.4-1.98,2.97-2.1,3.35c-0.92,2.82,0.5,3.8,0,6.69c-0.56,3.26-2.65,3.61-3.15,6.69C4.73,24.7,5.5,26.73,6.14,28z"/>
        <g>
            <g>
                <path class="st1" d="M55.05,25.15c0,0.01,0,0.02,0,0.03v1.63c0,0.2,0.18,0.36,0.39,0.36c0.22,0,0.39-0.16,0.39-0.36v-1.63c0-0.01,0-0.02,0-0.03h0.56v2.44h-1.91v-2.44H55.05z"/>
                <path class="st2" d="M54.65,25.15c0,0.01,0,0.02,0,0.03v1.63c0,0.2,0.18,0.36,0.39,0.36c0.22,0,0.39-0.16,0.39-0.36v-1.63c0-0.01,0-0.02,0-0.03H56v2.44h-1.91v-2.44H54.65z"/>
                <rect x="52.42" y="27.51" class="st1" width="5.79" height="0.49"/>
                <g>
                    <path class="st2" d="M14.97,25.15c0,0.01,0,0.02,0,0.03v1.63c0,0.2-0.18,0.36-0.39,0.36c-0.22,0-0.39-0.16-0.39-0.36v-1.63c0-0.01,0-0.02,0-0.03h-0.56v2.44h1.91v-2.44H14.97z"/>
                    <path class="st1" d="M15.37,25.15c0,0.01,0,0.02,0,0.03v1.63c0,0.2-0.18,0.36-0.39,0.36c-0.22,0-0.39-0.16-0.39-0.36v-1.63c0-0.01,0-0.02,0-0.03h-0.56v2.44h1.91v-2.44H15.37z"/>
                    <path class="st3" d="M28.38,15.26c0,0.01,0,0.02,0,0.03v3.14c0,0.2-0.18,0.36-0.39,0.36c-0.22,0-0.39-0.16-0.39-0.36v-3.14c0-0.01,0-0.02,0-0.03h-0.56v3.96h1.91v-3.96H28.38z"/>
                    <rect x="12.08" y="27.51" class="st1" width="5.79" height="0.49"/>
                </g>
            </g>
            <g>
                <g>
                    <path class="st4" d="M34.64,7.27H21.32c-0.19,0-0.35,0.16-0.35,0.36V16c0,0.2,0.16,0.36,0.35,0.36h13.32c0.19,0,0.35-0.16,0.35-0.36V7.63C34.99,7.43,34.84,7.27,34.64,7.27z"/>
                    <rect x="21.7" y="7.99" class="st5" width="12.58" height="7.59"/>
                </g>
                <rect x="22.27" y="8.43" class="st6" width="5.54" height="2.84"/>
                <rect id="window1" x="28.26" y="8.43" class="st7" width="5.54" height="2.84"/>
                <rect x="22.27" y="11.6" class="st8" width="5.54" height="2.84"/>
                <rect x="28.26" y="11.6" class="st8" width="5.54" height="2.84"/>
            </g>
            <g>
                <g>
                    <path class="st4" d="M49.01,7.27H35.69c-0.19,0-0.35,0.16-0.35,0.36V16c0,0.2,0.16,0.36,0.35,0.36h13.32c0.19,0,0.35-0.16,0.35-0.36V7.63C49.36,7.43,49.2,7.27,49.01,7.27z"/>
                    <rect x="36.06" y="7.99" class="st5" width="12.58" height="7.59"/>
                </g>
                <rect x="36.64" y="8.43" class="st7" width="5.54" height="2.84"/>
                <rect x="42.62" y="8.43" class="st9" width="5.54" height="2.84"/>
                <rect id="window2" x="36.64" y="11.6" class="st7" width="5.54" height="2.84"/>
                <rect x="42.62" y="11.6" class="st10" width="5.54" height="2.84"/>
            </g>
            <g>
		        <path class="st4" d="M34.64,16.63H21.32c-0.19,0-0.35,0.16-0.35,0.36v8.37c0,0.2,0.16,0.36,0.35,0.36h13.32c0.19,0,0.35-0.16,0.35-0.36v-8.37C34.99,16.8,34.84,16.63,34.64,16.63z"/>
                <rect x="21.7" y="17.35" class="st5" width="12.58" height="7.59"/>
                <rect x="22.27" y="17.79" class="st11" width="5.54" height="2.84"/>
                <rect x="28.26" y="17.79" class="st6" width="5.54" height="2.84"/>
                <rect x="22.27" y="20.96" class="st6" width="5.54" height="2.84"/>
                <rect x="28.26" y="20.96" class="st12" width="5.54" height="2.84"/>
	        </g>
            <g>
		        <path class="st4" d="M49.01,16.63H35.69c-0.19,0-0.35,0.16-0.35,0.36v8.37c0,0.2,0.16,0.36,0.35,0.36h13.32c0.19,0,0.35-0.16,0.35-0.36v-8.37C49.36,16.8,49.2,16.63,49.01,16.63z"/>
                <rect x="36.06" y="17.35" class="st5" width="12.58" height="7.59"/>
                <rect x="36.64" y="17.79" class="st6" width="5.54" height="2.84"/>
                <rect x="42.62" y="17.79" class="st6" width="5.54" height="2.84"/>
                <rect id="window3" x="36.64" y="20.96" class="st7" width="5.54" height="3.04"/>
                <rect id="window4" x="42.62" y="20.96" class="st7" width="5.54" height="3.04"/>
	        </g>
            <g>
		        <path class="st4" d="M59.75,16.63h-9.66c-0.14,0-0.25,0.16-0.25,0.36v8.37c0,0.2,0.11,0.36,0.25,0.36h9.66c0.14,0,0.25-0.16,0.25-0.36v-8.37C60,16.8,59.89,16.63,59.75,16.63z"/>
                <rect x="50.36" y="17.35" class="st5" width="9.12" height="7.59"/>
                <rect x="50.78" y="17.79" class="st6" width="4.02" height="2.84"/>
                <rect id="window5" x="55.12" y="17.79" class="st8" width="4.02" height="2.84"/>
                <rect x="50.78" y="20.96" class="st11" width="4.02" height="2.84"/>
                <rect x="55.12" y="20.96" class="st12" width="4.02" height="2.84"/>
	        </g>
            <g>
		        <rect x="10.52" y="17.35" class="st5" width="9.12" height="7.59"/>
                <rect id="window6" x="10.94" y="17.79" class="st8" width="4.02" height="2.84"/>
                <rect x="15.28" y="17.79" class="st11" width="4.02" height="2.84"/>
                <rect x="10.94" y="20.96" class="st11" width="4.02" height="2.84"/>
                <rect x="15.28" y="20.96" class="st12" width="4.02" height="2.84"/>
	        </g>
            <g>
		        <rect x="23.55" y="10.2" class="st13" width="3.43" height="0.8"/>
                <path class="st14" d="M26.45,9.56c-0.03,0-0.06-0.02-0.06-0.04c0-0.02,0.02-0.03,0.04-0.04c0.04-0.01,0.07-0.02,0.11-0.04c0.01,0,0.02-0.01,0.02-0.01c0.01-0.02,0.02-0.06,0.02-0.08c0-0.03,0-0.06,0-0.09c0,0,0.02,0,0.02-0.01c0,0,0,0,0,0c0.01-0.02,0.03-0.03,0.05-0.04c0,0,0.01,0,0.01,0c0.01,0,0.01,0,0.02-0.01c0.02-0.02,0.05-0.03,0.08-0.01c0.01,0.01,0.01,0.01,0.01,0.02c-0.01,0.03-0.02,0.05-0.02,0.08c0,0.03,0.01,0.05,0.01,0.08c0,0.01,0.01,0.04,0.02,0.05c0.03,0.01,0.06,0.03,0.09,0.04c0.02,0,0.02,0.01,0.03,0.02c0,0.01,0.01,0.01,0.01,0.02c0.01,0.01,0.01,0.03-0.01,0.04c-0.01,0.01-0.02,0.01-0.02,0.03c0,0.01-0.01,0.01-0.01,0.01c-0.01,0.01-0.03,0.01-0.04,0.01c-0.04,0-0.08,0-0.11,0c-0.01,0-0.01,0-0.01,0.01c0,0.02-0.01,0.03-0.02,0.04c-0.01,0.01-0.02,0.02-0.03,0.03c-0.04,0.03-0.11,0.02-0.14-0.01c-0.02-0.02-0.03-0.05-0.01-0.07c0-0.01,0.01-0.01,0.01-0.02c0.01-0.01,0-0.01,0-0.02C26.48,9.56,26.46,9.56,26.45,9.56z"/>
                <path class="st15" d="M26.88,9c-0.01-0.07-0.03-0.12-0.07-0.17c-0.03-0.03-0.07-0.06-0.11-0.08c0,0-0.01,0-0.01-0.01c-0.05-0.01-0.11-0.02-0.17-0.02c0,0-0.01,0-0.01,0c-0.05,0.01-0.1,0.03-0.15,0.04c-0.01,0-0.01,0.01-0.02,0.01c-0.04,0.04-0.07,0.08-0.09,0.13c-0.01,0.04-0.01,0.08-0.01,0.12c0.01,0.07,0.03,0.14,0.08,0.21c0.03,0.04,0.06,0.08,0.12,0.1c0.03,0.02,0.07,0.02,0.13,0.01c0.06-0.01,0.19-0.08,0.21-0.09c0.03-0.02,0.05-0.04,0.06-0.07c0.02-0.03,0.02-0.07,0.03-0.11c0-0.01,0-0.01,0-0.02C26.87,9.04,26.87,9.02,26.88,9z"/>
                <path class="st16" d="M26.8,9.3c0.04-0.01,0.09-0.03,0.12-0.05c0.02-0.01,0.04-0.03,0.05-0.05c0.01-0.02,0.04-0.09,0.04-0.13c0-0.04,0.01-0.21-0.12-0.33c-0.06-0.05-0.15-0.07-0.24-0.08c-0.05,0-0.1,0-0.14,0c-0.05,0.01-0.1,0.03-0.14,0.05c-0.01,0-0.01,0.01-0.02,0.01c-0.06,0.02-0.1,0.05-0.13,0.11c-0.02,0.04,0,0.17,0.02,0.25c0,0.02,0.07,0.15,0.06,0.11c-0.01-0.02-0.01-0.04-0.02-0.06c0-0.02,0-0.04,0-0.06c0-0.03,0-0.06,0.01-0.08c0-0.02,0-0.04,0.01-0.06c0.01-0.04,0.03-0.07,0.08-0.1c0,0,0.01-0.01,0.02-0.01c0.01,0,0.02,0,0.02,0c0.03,0.05,0.09,0.07,0.16,0.09c0.05,0.02,0.11,0.03,0.17,0.03c0.05,0.01,0.06,0.04,0.07,0.06c0,0.01,0,0.03,0.01,0.04c0,0,0,0.01,0.01,0.01c0.01,0,0.01,0,0.01-0.01c0-0.01,0-0.01,0-0.01c0.01,0,0.03,0,0.04,0c0,0,0,0,0,0c0,0.02,0,0.04,0,0.06c0,0.01,0,0.01-0.01,0.01c-0.01,0-0.01,0.01-0.01,0.01c-0.02,0-0.02,0-0.02,0.01c0,0.01-0.01,0.02-0.01,0.04c0,0,0,0.01,0,0.01c0,0.04-0.02,0.08-0.04,0.11c0,0-0.01,0.01-0.01,0.01C26.76,9.31,26.79,9.31,26.8,9.3z"/>
                <g>
			        <path class="st17" d="M26.25,9.04c0,0,0,0.01,0,0.02c0,0.01,0.04,0.02,0.08,0.02s0.08-0.01,0.08-0.02c0-0.01,0-0.02,0-0.02"/>
                    <path class="st18" d="M26.25,9.04c0,0,0,0.01,0,0.02c0,0.01,0.04,0.02,0.08,0.02s0.08-0.01,0.08-0.02c0-0.01,0-0.02,0-0.02"/>
		        </g>
                <path class="st19" d="M26.71,10.22C26.71,10.22,26.71,10.22,26.71,10.22c0.1,0,0.18,0,0.27,0.01c0.02,0,0.03,0,0.02-0.02c0,0,0,0,0,0c0-0.03-0.01-0.07-0.01-0.1c0-0.01,0-0.02,0.01-0.03c0-0.02,0.01-0.03,0.01-0.05c0.01-0.04,0.02-0.08,0.04-0.11c0.02-0.08,0.04-0.15,0.04-0.23c0-0.04-0.01-0.08-0.03-0.12c-0.01-0.02-0.03-0.03-0.04-0.04c-0.03-0.03-0.13-0.08-0.23-0.1c0,0-0.01,0-0.01,0c0,0.01,0,0.02,0,0.03c0,0.02,0,0.03-0.01,0.05c-0.02,0.05-0.03,0.1-0.05,0.14c-0.01,0.05-0.03,0.1-0.03,0.15c0,0.04,0,0.08,0,0.12c0,0.03,0,0.06,0.01,0.08c0,0,0,0.01,0,0.01c-0.01,0-0.02,0-0.02,0c-0.05-0.01-0.11-0.02-0.16-0.03c0,0-0.01,0-0.01,0c-0.03-0.01-0.05-0.01-0.07,0.01c-0.03,0.02-0.04,0.05-0.03,0.08c0,0.01,0,0.02,0.01,0.03c0,0.02,0.02,0.03,0.04,0.03c0.06,0.02,0.12,0.03,0.18,0.04c0.02,0,0.05,0.01,0.07,0.01c0.01,0,0.01,0,0.01,0.01C26.7,10.19,26.7,10.21,26.71,10.22z"/>
                <path class="st20" d="M26.39,10c0.02-0.01,0.02-0.02,0.02-0.03c0-0.01,0-0.02,0-0.03c0,0,0,0,0-0.01c0.01-0.01,0-0.02-0.01-0.02c-0.01-0.02-0.02-0.03-0.04-0.05c-0.01,0-0.02,0-0.03,0c0,0-0.01,0-0.01-0.01c0-0.01,0-0.02,0-0.03c0-0.02-0.02-0.04-0.03-0.06c-0.03-0.04-0.05-0.07-0.08-0.11c0,0-0.01,0-0.01,0c-0.09,0-0.17-0.01-0.26-0.01c-0.05,0-0.09-0.01-0.14-0.01c-0.01,0-0.02,0-0.01,0.01c0.04,0.05,0.16,0.24,0.17,0.26c0.01,0.02-0.01,0.11-0.01,0.12c0,0,0.26,0.02,0.31,0.03c0.02,0,0.03,0,0.05,0c0.01,0,0.01,0,0.01-0.01c0-0.02,0.01-0.04,0.01-0.06c0-0.01,0-0.02,0-0.03c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0-0.01,0-0.01,0-0.02c0,0,0.01,0,0.01,0c0,0,0.01,0.01,0.01,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0c0.01,0,0.02,0.01,0.02,0.02c0,0.01,0.01,0.01,0.01,0.02C26.39,9.96,26.39,9.98,26.39,10z"/>
                <g>
			        <path class="st17" d="M26.48,9.04c0,0,0,0.01,0,0.02c0,0.01,0.04,0.02,0.08,0.03c0.04,0,0.08-0.01,0.08-0.02s0-0.03,0-0.03"/>
                    <path class="st18" d="M26.48,9.04c0,0,0,0.01,0,0.02c0,0.01,0.04,0.02,0.08,0.03c0.04,0,0.08-0.01,0.08-0.02s0-0.03,0-0.03"/>
                </g>
            </g>
            <defs>
                <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="22.92" y="20" width="3.08" height="3.88">
                    <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="22.92" y="20" width="3.08" height="3.88" id="SVGID_1_">
                <g class="st21">
                    <rect x="23.15" y="21.24" class="st22" width="5.07" height="2.42"/>
                </g>
	        </mask>
            <g class="st23">
		        <rect x="24" y="20" class="st24" width="2" height="2.68"/>
                <rect x="24" y="21.39" class="st25" width="0.99" height="1.3"/>
                <rect x="22.92" y="22.38" class="st26" width="1.79" height="1.5"/>
                <rect x="24.57" y="22.38" class="st26" width="1.03" height="1.14"/>
	        </g>
            <defs>
			    <filter id="Adobe_OpacityMaskFilter_00000004523646358910157540000005067993358842461058_" filterUnits="userSpaceOnUse" x="30" y="11.5" width="3.38" height="2.82">
			        <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
		        </filter>
	        </defs>
            <mask maskUnits="userSpaceOnUse" x="30" y="11.5" width="3.38" height="2.82" id="SVGID_00000180356452942021349780000015946340292088135611_">
                <g style="filter:url(#Adobe_OpacityMaskFilter_00000004523646358910157540000005067993358842461058_);">
                    <rect x="28.26" y="11.58" class="st22" width="5.54" height="2.84"/>
                </g>
	        </mask>
            <g style="opacity:0.5;mask:url(#SVGID_00000180356452942021349780000015946340292088135611_);">
		        <rect x="30" y="11.5" class="st24" width="1.05" height="1.5"/>
                <rect x="31.16" y="11.5" class="st24" width="1.05" height="1.5"/>
                <rect x="32.33" y="11.5" class="st24" width="1.05" height="1.5"/>
                <path class="st29" d="M31.17,13.19c-0.01,0.03-0.02,0.05-0.02,0.08c0,0.02-0.01,0.04-0.01,0.05c0,0,0,0.01-0.01,0.01c-0.01,0-0.01,0-0.02,0.01c-0.01,0.01-0.03,0.01-0.04,0.01c-0.01,0-0.02-0.01-0.04-0.02c0-0.02,0.01-0.01,0.01-0.03c0,0,0-0.01,0-0.01c0-0.02,0.08-0.36,0.09-0.4c0-0.01,0.01-0.03,0.02-0.04c0,0,0.01-0.01,0.01-0.01c0.04-0.02,0.1-0.04,0.14-0.05c0.01,0,0.03,0,0.04-0.01c0-0.01,0.01-0.01,0.03-0.01c0.01,0,0.03,0.01,0.04,0.01c0.03,0.01,0.07,0.02,0.1,0.03c0.02,0,0.06,0.01,0.07,0.02c0.04,0.02,0.03,0.1,0.03,0.21c-0.01,0.18-0.04,0.4-0.04,0.42c0,0.01-0.01,0.03-0.01,0.04c0,0.01-0.01,0.01-0.01,0.01c-0.04,0-0.09,0-0.13,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03-0.01c-0.01,0.01-0.03,0-0.04,0c-0.02,0-0.05,0-0.07-0.01c-0.02,0-0.04,0-0.05,0c0,0-0.01,0-0.01-0.01c0-0.03-0.01-0.17-0.02-0.19c-0.01-0.02-0.01-0.04-0.01-0.06c0-0.02,0-0.04,0-0.06C31.18,13.17,31.17,13.19,31.17,13.19z"/>
                <path class="st20" d="M31.48,12.07c0,0,0.01-0.01,0.01-0.01c0.01-0.01,0.03-0.02,0.04-0.01c0,0,0,0.01,0.01,0.02c0.01,0,0.02,0.01,0.02,0.02c0,0.01,0,0.02-0.01,0.03c-0.01,0-0.01,0.01-0.01,0.02c0.01,0.06,0.02,0.13,0.02,0.19c0,0,0,0.01,0.01,0.01c0.05,0.01,0.06,0.05,0.04,0.08c-0.01,0.02-0.03,0.03-0.05,0.04c0,0-0.01,0.01-0.01,0.01c-0.01,0.03-0.01,0.07-0.02,0.1c-0.01,0.04-0.02,0.06-0.04,0.09c-0.01,0.01-0.06,0.08-0.13,0.08c-0.07,0-0.12-0.06-0.13-0.09c-0.02-0.04-0.04-0.08-0.05-0.12c-0.01-0.02-0.01-0.05-0.01-0.07c0-0.01,0-0.01-0.01-0.01c-0.01-0.01-0.03-0.01-0.04-0.02c-0.02-0.03-0.01-0.07,0.01-0.08c0.01-0.01,0.02-0.01,0.03-0.01c0.01,0,0.01,0,0.01-0.01c0-0.03,0-0.07,0-0.1c0-0.01,0-0.02,0-0.04c0-0.01,0-0.02,0-0.03c0-0.02,0.01-0.03,0.02-0.04c0,0,0.01-0.01,0.01-0.01c0.05-0.02,0.11-0.03,0.17-0.04c0.01,0,0.03,0,0.04,0.01c0,0,0.01,0,0.01,0c0.01,0,0.01,0,0.02,0C31.46,12.05,31.47,12.06,31.48,12.07z"/>
                <rect x="30.05" y="13.49" class="st30" width="3.31" height="0.82"/>
	        </g>
            <defs>
			    <filter id="Adobe_OpacityMaskFilter_00000036948515104146597720000008557095866609212850_" filterUnits="userSpaceOnUse" x="34.64" y="5.14" width="9" height="7.2">
			        <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="34.64" y="5.14" width="9" height="7.2" id="SVGID_00000054957827256333564330000001261786029422235577_">
                <g style="filter:url(#Adobe_OpacityMaskFilter_00000036948515104146597720000008557095866609212850_);">
                    <rect x="36.62" y="8.43" class="st22" width="5.54" height="2.84"/>
                </g>
            </mask>
            <g style="opacity:0.6;mask:url(#SVGID_00000054957827256333564330000001261786029422235577_);">
		        <rect x="35.39" y="5.14" class="st33" width="8.25" height="6.64"/>
                <path class="st34" d="M37.43,9.59V9.56c0-0.25,0.16-0.45,0.37-0.45c0.2,0,0.37,0.2,0.37,0.45v0.03"/>
                <g>
			        <path class="st35" d="M37.43,10.78c0,0,0-0.38,0-0.84s0.16-0.84,0.37-0.84c0.2,0,0.37,0.38,0.37,0.84s0,0.84,0,0.84"/>
                    <path class="st36" d="M37.43,10.78c0,0,0-0.38,0-0.84s0.16-0.84,0.37-0.84c0.2,0,0.37,0.38,0.37,0.84s0,0.84,0,0.84"/>
		        </g>
                <rect x="38.66" y="10.89" class="st37" width="0.72" height="0.88"/>
                <rect x="39.47" y="10.89" class="st37" width="0.72" height="0.88"/>
	        </g>
            <defs>
                <filter id="Adobe_OpacityMaskFilter_00000131347965929352869770000014533511301033024394_" filterUnits="userSpaceOnUse" x="43.09" y="8.53" width="3.43" height="4.77">
                    <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
	        </defs>
            <mask maskUnits="userSpaceOnUse" x="43.09" y="8.53" width="3.43" height="4.77" id="SVGID_00000114769807083972309940000016982957406367407540_">
                <g style="filter:url(#Adobe_OpacityMaskFilter_00000131347965929352869770000014533511301033024394_);">
                    <rect x="42.62" y="8.43" class="st39" width="5.54" height="2.84"/>
                </g>
            </mask>
            <g style="opacity:0.7;mask:url(#SVGID_00000114769807083972309940000016982957406367407540_);">
		        <rect x="43.09" y="10.63" class="st13" width="3.43" height="2.68"/>
                <path class="st15" d="M44.41,9.67c-0.05-0.02-0.11-0.04-0.17-0.05c0,0.01-0.01,0.02-0.01,0.03c0,0.03-0.01,0.04-0.04,0.03c-0.01,0.02-0.02,0.03-0.04,0.03c-0.02,0-0.02-0.01-0.02-0.04c-0.02-0.02-0.03-0.03-0.01-0.06c0.02-0.04,0.04-0.07,0.06-0.11c0.02-0.03,0.05-0.12,0.06-0.15c0-0.01,0-0.02,0-0.02c-0.01-0.05-0.03-0.06-0.05-0.11c-0.01-0.04-0.02-0.08-0.02-0.12c0-0.04-0.01-0.08-0.02-0.12c-0.01-0.08,0-0.16,0.01-0.24c0-0.02,0.01-0.05,0.01-0.07c0.01-0.02,0.02-0.04,0.04-0.04c0.02,0,0.03-0.01,0.04-0.02c0.07-0.06,0.16-0.08,0.25-0.05c0.09,0.03,0.16,0.09,0.19,0.17c0.03,0.06,0.05,0.13,0.07,0.19c0,0.01,0.01,0.02,0.02,0.03c0.02,0.02,0.04,0.03,0.05,0.05c0.02,0.02,0.02,0.04,0.01,0.07c-0.01,0.01-0.02,0.03-0.03,0.04c-0.01,0.01-0.01,0.01-0.01,0.02c0,0.03,0,0.06-0.01,0.1c-0.01,0.05-0.03,0.09-0.07,0.13c-0.06,0.05-0.13,0.07-0.2,0.07c-0.02,0-0.02,0-0.03,0.02c-0.01,0.01-0.01,0.03-0.02,0.04c-0.01,0.03-0.03,0.06-0.04,0.09C44.43,9.62,44.42,9.64,44.41,9.67z"/>
                <path class="st41" d="M44.58,8.96c-0.01,0-0.02-0.01-0.03-0.01c-0.04-0.01-0.09,0.01-0.1,0.05c-0.02,0.05,0,0.1,0.04,0.14c0,0.01,0.01,0.01,0.02,0.02c-0.02,0.03-0.04,0.06-0.08,0.08c-0.02,0.01-0.04,0.02-0.06,0.03C44.34,9.3,44.31,9.3,44.27,9.3c-0.04,0-0.06-0.02-0.07-0.05c-0.01-0.03-0.02-0.05-0.03-0.08c-0.01-0.04-0.03-0.07-0.04-0.11C44.1,9,44.08,8.95,44.07,8.89c0-0.01,0-0.02,0-0.02c-0.01-0.08,0.01-0.15,0.07-0.22c0.02-0.02,0.04-0.04,0.07-0.04c0,0,0.01,0,0.01-0.01c0.04-0.03,0.09-0.05,0.14-0.06c0.1-0.02,0.19-0.02,0.29,0.01c0.04,0.01,0.08,0.03,0.12,0.06c0.01,0.01,0.02,0.02,0.01,0.03c-0.01,0.03-0.03,0.07-0.05,0.1c-0.01,0.01-0.01,0.01-0.02,0.01c-0.03,0.01-0.06,0.02-0.08,0.05c-0.03,0.04-0.05,0.08-0.05,0.13C44.59,8.94,44.59,8.95,44.58,8.96C44.59,8.96,44.59,8.96,44.58,8.96z"/>
                <path class="st42" d="M44.67,10.43c0,0.01,0,0.01,0,0.02c0,0.09-0.01,0.19-0.02,0.28c-0.01,0.06-0.01,0.12-0.02,0.18c-0.01,0.05-0.01,0.1-0.02,0.15c0,0.04-0.02,0.06-0.07,0.08c-0.05,0.02-0.1,0.02-0.14,0.02c-0.21-0.01-0.45-0.05-0.52-0.07c-0.06-0.01-0.07-0.03-0.08-0.04c-0.01-0.02,0-0.03-0.01-0.05c0-0.02,0-0.03-0.01-0.05c-0.02-0.06-0.01-0.12,0-0.19c0.02-0.16,0.05-0.32,0.06-0.48c0-0.05,0-0.1,0.01-0.16c0.01-0.15,0.04-0.29,0.13-0.41c0.02-0.03,0.04-0.06,0.07-0.09c0.02-0.02,0.04-0.03,0.06-0.04c0.02,0,0.03-0.01,0.05-0.01c0.01,0,0.03,0,0.04,0c0.04,0.01,0.08,0.02,0.12,0.03c0.04,0.01,0.08,0.02,0.12,0.03c0.05,0.01,0.08,0.04,0.12,0.06c0.01,0.01,0.01,0.01,0.02,0.02c0.07,0.06,0.12,0.13,0.17,0.2c0.1,0.15,0.18,0.3,0.27,0.46c0.01,0.02,0.02,0.03,0.03,0.05c0.01,0.01,0.01,0.02,0.03,0.02c0.03,0,0.05,0.03,0.08,0.02c0.01,0,0.02,0,0.04,0c0.04,0.02,0.07,0.03,0.07,0.07c0,0.04-0.02,0.07-0.06,0.09c-0.01,0-0.02,0.01-0.03,0.01c-0.07,0.01-0.14,0.01-0.22,0.01c-0.03,0-0.06,0-0.09,0c-0.03,0-0.05-0.01-0.07-0.03c-0.04-0.05-0.08-0.1-0.12-0.15C44.69,10.44,44.68,10.43,44.67,10.43C44.68,10.42,44.67,10.42,44.67,10.43z"/>
	        </g>
            <defs>
			    <filter id="Adobe_OpacityMaskFilter_00000096740344946781466890000002751898683091980173_" filterUnits="userSpaceOnUse" x="41.24" y="21.24" width="7.31" height="5.46">
			        <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="41.24" y="21.24" width="7.31" height="5.46" id="SVGID_00000080178061392126540990000008215705933091512975_">
                <g style="filter:url(#Adobe_OpacityMaskFilter_00000096740344946781466890000002751898683091980173_);">
                    <rect x="42.6" y="20.95" class="st22" width="5.54" height="2.84"/>
                </g>
            </mask>
            <g style="opacity:0.6;mask:url(#SVGID_00000080178061392126540990000008215705933091512975_);">
		        <rect x="41.24" y="21.24" class="st33" width="6.6" height="5.01"/>
                <path class="st45" d="M45.73,24.5v-0.02c0-0.2,0.13-0.36,0.29-0.36c0.16,0,0.29,0.16,0.29,0.36v0.02"/>
                <polyline class="st46" points="45.65,22.9 45.65,21.9 46.24,21.9 46.24,22.9 		"/>
                <rect x="42.8" y="21.64" class="st47" width="0.69" height="0.24"/>
                <rect x="45.59" y="21.64" class="st47" width="0.69" height="0.24"/>
                <rect x="46.79" y="21.64" class="st47" width="0.69" height="0.24"/>
	        </g>
            <rect x="21.68" y="8.01" class="st48" width="12.58" height="7.59"/>
            <rect x="36.04" y="8.01" class="st48" width="12.58" height="7.59"/>
            <rect x="21.72" y="17.37" class="st48" width="12.58" height="7.59"/>
            <rect x="50.38" y="17.37" class="st48" width="9.12" height="7.59"/>
            <rect x="10.55" y="17.41" class="st48" width="9.12" height="7.59"/>
        </g>
        <rect x="36.04" y="17.37" class="st48" width="12.58" height="7.59"/>
    </svg>
</template>

<script>
    export default {
        name: "MonitoringSvg"
    }
</script>

<style scoped lang="scss">
    #MonitoringSvg{
        .st0{fill:#D1EBFF;}
        .st1{fill:#505656;}
        .st2{fill:#33383D;}
        .st3{fill:#31505A;}
        .st4{fill:#3F454C;}
        .st5{fill:#2872A0;stroke:#15506D;stroke-width:0.9148;stroke-miterlimit:10;}
        .st6{fill:#868686;}
        .st7{fill:#DAFFFF;}
        .st8{fill:#AAAAAA;}
        .st9{fill:#696969;}
        .st10{fill:#777777;}
        .st11{fill:#757575;stroke:#757575;stroke-width:0.3567;stroke-miterlimit:10;}
        .st12{fill:#9F9F9F;}
        .st13{fill-rule:evenodd;clip-rule:evenodd;fill:#CACACA;}
        .st14{fill-rule:evenodd;clip-rule:evenodd;fill:#D4D4D4;}
        .st15{fill-rule:evenodd;clip-rule:evenodd;fill:#F1F1F1;}
        .st16{fill-rule:evenodd;clip-rule:evenodd;fill:#626262;}
        .st17{opacity:0.3;fill-rule:evenodd;clip-rule:evenodd;fill:#F1F1F1;}
        .st18{fill:none;stroke:#505050;stroke-width:0.1762;stroke-miterlimit:10;}
        .st19{fill-rule:evenodd;clip-rule:evenodd;fill:#717171;}
        .st20{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        .st21{filter:url(#Adobe_OpacityMaskFilter);}
        .st22{fill:#DAE9F2;}
        .st23{mask:url(#SVGID_1_);}
        .st24{fill:#656565;}
        .st25{fill:#FFFFFF;}
        .st26{fill-rule:evenodd;clip-rule:evenodd;fill:#DBDBDB;}
        .st27{filter:url(#Adobe_OpacityMaskFilter_00000023275931713642785070000011108007546396618643_);}
        .st28{opacity:0.5;mask:url(#SVGID_00000016062429210800604880000007406721180565863063_);}
        .st29{fill-rule:evenodd;clip-rule:evenodd;fill:#F8F8F8;}
        .st30{fill-rule:evenodd;clip-rule:evenodd;fill:#E3E3E3;}
        .st31{filter:url(#Adobe_OpacityMaskFilter_00000042737765788069975980000000657820982294833315_);}
        .st32{opacity:0.6;mask:url(#SVGID_00000114783671048202005540000008767203780560167057_);}
        .st33{fill-rule:evenodd;clip-rule:evenodd;fill:#868686;}
        .st34{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#FBFBFB;stroke-width:5.5753;stroke-miterlimit:10;}
        .st35{fill-rule:evenodd;clip-rule:evenodd;fill:#343434;}
        .st36{fill:none;stroke:#606060;stroke-width:1.0607;stroke-miterlimit:10;}
        .st37{fill-rule:evenodd;clip-rule:evenodd;fill:#606060;}
        .st38{filter:url(#Adobe_OpacityMaskFilter_00000111186262395902268660000001090423279157968269_);}
        .st39{fill:#EADAF4;}
        .st40{opacity:0.7;mask:url(#SVGID_00000179623603940758432090000000347191067377286072_);}
        .st41{fill-rule:evenodd;clip-rule:evenodd;fill:#838383;}
        .st42{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
        .st43{filter:url(#Adobe_OpacityMaskFilter_00000005267553035210493700000009906291101698312088_);}
        .st44{opacity:0.6;mask:url(#SVGID_00000165944037122487555800000009362170772171656107_);}
        .st45{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#FBFBFB;stroke-width:4.4627;stroke-miterlimit:10;}
        .st46{fill-rule:evenodd;clip-rule:evenodd;fill:#343434;stroke:#606060;stroke-width:0.8564;stroke-miterlimit:10;}
        .st47{opacity:0.9;fill-rule:evenodd;clip-rule:evenodd;fill:#F8F8F8;}
        .st48{opacity:0.4;fill:#FFFFFF;}
    }
</style>