<template>
	<svg version="1.1" id="MailSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
		<g>
			<g>
				<path class="st0" d="M29,12.07v15.24c0,0.93-0.72,1.69-1.61,1.69H2.61C1.72,29,1,28.24,1,27.31V12.07c0-0.22,0.04-0.44,0.12-0.63c0.24-0.62,0.82-1.06,1.49-1.06h24.78c0.67,0,1.25,0.44,1.49,1.06C28.96,11.63,29,11.84,29,12.07z"/>
				<path class="st1" d="M28.88,11.43L15.66,22.98c-0.38,0.33-0.94,0.33-1.32,0L1.12,11.43c0.24-0.62,0.82-1.06,1.49-1.06h24.78C28.06,10.37,28.64,10.81,28.88,11.43z"/>
				<path class="st2" d="M19.29,2.24H6.13v8.13v5.44l8.21,7.16c0.38,0.34,0.94,0.34,1.32,0l8.21-7.16v-5.44v-5.1L19.29,2.24z"/>
				<path class="st0" d="M15,23.47c-0.29,0-0.57-0.1-0.81-0.31L5.99,16c-0.05-0.05-0.08-0.11-0.08-0.19V2.24C5.9,2.11,6.01,2,6.13,2h13.16c0.04,0,0.09,0.01,0.12,0.04l4.58,3.03c0.07,0.04,0.11,0.12,0.11,0.2v10.55c0,0.07-0.03,0.14-0.08,0.19l-8.21,7.16C15.57,23.37,15.29,23.47,15,23.47z M6.36,15.7l8.12,7.09c0.3,0.26,0.73,0.26,1.03,0l8.12-7.09V5.4l-4.41-2.92H6.36V15.7z"/>
				<polygon class="st3" points="23.87,5.27 19.29,2.24 20.77,6.25"/>
				<path class="st0" d="M20.77,6.49c-0.09,0-0.18-0.06-0.21-0.15l-1.48-4.01c-0.04-0.1-0.01-0.21,0.07-0.27c0.08-0.07,0.18-0.07,0.27-0.02l4.58,3.03c0.08,0.05,0.12,0.14,0.11,0.24c-0.01,0.09-0.07,0.17-0.16,0.2l-3.09,0.98C20.82,6.49,20.79,6.49,20.77,6.49z M19.76,2.83l1.15,3.12l2.41-0.77L19.76,2.83z"/>
			</g>
			<path class="st4" d="M18.59,15.09h1.04c-0.38,0.7-0.92,1.24-1.62,1.64c-0.81,0.45-1.77,0.68-2.88,0.68c-1.08,0-1.99-0.19-2.75-0.57c-0.75-0.38-1.3-0.95-1.62-1.69c-0.33-0.75-0.46-1.56-0.39-2.44c0.07-0.96,0.35-1.86,0.85-2.69c0.5-0.83,1.13-1.46,1.92-1.87c0.78-0.42,1.66-0.63,2.63-0.63c0.82,0,1.54,0.17,2.16,0.5c0.61,0.34,1.07,0.81,1.36,1.43c0.29,0.62,0.41,1.3,0.36,2.03c-0.06,0.87-0.38,1.67-0.94,2.37c-0.71,0.89-1.56,1.34-2.57,1.34c-0.27,0-0.47-0.05-0.6-0.15c-0.13-0.1-0.21-0.25-0.24-0.44c-0.41,0.39-0.87,0.59-1.37,0.59c-0.54,0-0.98-0.2-1.3-0.59c-0.33-0.39-0.47-0.92-0.42-1.57c0.06-0.81,0.33-1.54,0.81-2.21c0.58-0.81,1.28-1.21,2.1-1.21c0.58,0,0.99,0.23,1.24,0.7l0.16-0.57h1.29l-1.01,3.69c-0.06,0.23-0.1,0.38-0.1,0.45c-0.01,0.09,0.01,0.15,0.04,0.19c0.03,0.04,0.08,0.06,0.13,0.06c0.16,0,0.37-0.1,0.63-0.3c0.35-0.26,0.65-0.61,0.88-1.05c0.24-0.44,0.37-0.89,0.41-1.36c0.06-0.84-0.18-1.55-0.72-2.12c-0.54-0.57-1.33-0.85-2.37-0.85c-0.88,0-1.65,0.19-2.29,0.57c-0.64,0.38-1.15,0.91-1.51,1.6c-0.36,0.69-0.57,1.4-0.63,2.15c-0.05,0.72,0.07,1.38,0.38,1.98c0.3,0.59,0.76,1.03,1.37,1.3c0.61,0.27,1.32,0.41,2.13,0.41c0.78,0,1.46-0.11,2.04-0.34C17.74,15.89,18.21,15.55,18.59,15.09z M13.45,12.98c-0.03,0.44,0.03,0.76,0.18,0.96c0.15,0.21,0.35,0.31,0.6,0.31c0.19,0,0.36-0.05,0.53-0.14c0.13-0.07,0.26-0.18,0.39-0.32c0.19-0.21,0.36-0.51,0.52-0.92c0.16-0.4,0.25-0.78,0.27-1.12c0.03-0.39-0.04-0.69-0.19-0.89c-0.16-0.21-0.36-0.31-0.63-0.31c-0.28,0-0.55,0.11-0.8,0.34c-0.25,0.23-0.46,0.55-0.61,0.98C13.57,12.28,13.48,12.65,13.45,12.98z"/>
		</g>
	</svg>
</template>

<script>
    export default {
        name: "MailSvg"
    }
</script>

<style scoped lang="scss">
	#MailSvg{
		.st0{fill:#66B0FF;}
		.st1{opacity:0.3;}
		.st2{fill:#FFFFFF;}
		.st3{fill:#ECECEC;}
		.st4{fill:#007CFF;}
	}
</style>