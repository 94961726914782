<template>
    <svg version="1.1" id="AdvantagesSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 250" style="enable-background:new 0 0 300 250;" xml:space="preserve">
        <path id="Background" class="st0" d="M283.95,229.17c-6.15,6.71-15.63,7.92-34.6,10.32c-20.15,2.55-21.95-1.77-64.09-3.35c0,0-24.29-0.83-44.49,1.47c-19.98,2.27-20.75,4.56-30.78,5.14c-22.59,1.31-59.61-7.92-73.26-38.52c-10.68-23.96-2.23-50.24-0.33-56.14c7.09-22.05,16.16-18.76,21.41-38.89c6.5-24.9-4.87-39.59,6.02-60.54c0.97-1.87,4.33-8.03,10.54-13.72c0.48-0.45,0.99-0.89,1.51-1.32c2-1.68,4.01-3.07,6.04-4.22c16.77-9.5,34.49-2.11,57.68-3.21c0.23-0.01,0.46-0.02,0.69-0.04c0.5-0.03,0.99-0.06,1.49-0.09c0.18-0.01,0.36-0.03,0.54-0.04c0.14-0.01,0.28-0.03,0.41-0.04c6.18-0.5,12.75-1.65,19.78-3.92c0.56-0.18,1.12-0.36,1.68-0.55c19.8-6.79,16.17-13.14,32.45-16.88c29.57-6.78,68.24,8.06,81.62,36.69c1.85,3.97,2.43,6.98,3.51,11.75c6.83,30.1-2.38,51.23-5.85,71.43C266.49,179.45,302.26,209.17,283.95,229.17z"/>
        <ellipse class="st1" cx="159" cy="233.52" rx="135" ry="12.48"/>
        <ellipse class="st2" cx="158.41" cy="230.26" rx="126.97" ry="9.21"/>
        <g>
            <g>
                <path class="st3" d="M122.71,84.47V44.3v-0.52v-5.55H56.81v0.62v1.23v184.38h8.59V51.21l31.05-3.75l0.14,0.16l0.14-0.19l15.42-1.86l0.17,0.2l0.18-0.25l8.47-1.02v39.97h-9.17v4.93h19.48v-4.93H122.71z M78.33,41.45l5.17,5.72l-10.67,1.29L78.33,41.45z M71.38,47.44l-5.02-7.37h10.8L71.38,47.44z M79.49,40.07h10.54l-5.09,6.04L79.49,40.07z M120.99,40.85v1.8l-1.34,0.16L120.99,40.85z M118.15,41.86l-1.54-1.79h2.76L118.15,41.86z M114.35,40.07l-2.11,2.85l-2.42-2.85H114.35z M107.55,40.07L105,43.52l-2.8-3.44H107.55z M99.96,40.07l-3.48,4.72l-4.11-4.72H99.96z M65.41,41.81l4.75,6.97l-4.75,0.57V41.81z M86.7,46.78l4.5-5.34l3.78,4.34L86.7,46.78z M101.09,41.52l2.61,3.21l-5.46,0.66L101.09,41.52z M106.56,44.38l2.14-2.89l2.03,2.39L106.56,44.38z M115.5,41.49l1.41,1.64l-2.88,0.35L115.5,41.49z"/>
                <path class="st3" d="M103.22,93.72H91.19v-6.17h-1.72v6.17H78.01V82h-2.29v128.88h29.8V85.7h-2.29V93.72z M89.47,103.59H78.01V96.8h11.46V103.59z M103.22,103.59H91.19V96.8h12.03V103.59z"/>
            </g>
            <g>
                <polygon class="st4" points="282.55,230 282.55,198.45 280.04,198.45 280.04,183.42 276.38,183.42 276.38,131.15 271.08,131.15 271.08,124.4 259.77,118.73 259.77,111.93 258.21,111.93 258.21,131.15 256.01,131.15 256.01,183.42 253.82,183.42 253.82,155.93 247.87,155.93 247.87,141.77 246.21,141.77 246.21,183.42 241.68,183.42 241.68,167.57 238.71,167.57 238.71,163.52 231.81,163.52 231.81,149.7 228.04,149.7 228.04,139.92 223.4,139.92 223.4,125.67 221.74,125.67 221.74,139.92 217.1,139.92 217.1,149.7 213.33,149.7 213.33,163.52 206.43,163.52 206.43,167.57 203.46,167.57 203.46,183.42 199.93,183.42 199.93,163.52 193.03,163.52 193.03,120.69 190.84,120.69 190.84,115.97 176.11,115.97 176.11,120.69 172.98,120.69 172.98,151.72 160.44,151.72 160.44,154.75 155.74,154.75 155.74,157.11 160.44,157.11 160.44,169.25 147.28,169.25 147.28,128.79 144.46,128.79 144.46,120.19 138.35,120.19 138.35,89.67 136.63,89.67 136.63,120.19 132.24,120.19 132.24,128.79 106.53,128.79 106.53,125.96 104.96,125.96 104.96,145.18 102.77,145.18 102.77,145.52 101.45,145.52 101.45,164.74 99.26,164.74 99.26,169.96 94.62,169.96 94.62,155.8 92.96,155.8 92.96,189.52 91.11,189.52 91.11,175.36 89.45,175.36 89.45,197.45 88.44,197.45 88.44,181.6 85.46,181.6 85.46,177.55 78.57,177.55 78.57,163.73 74.79,163.73 74.79,153.95 70.15,153.95 70.15,139.7 68.5,139.7 68.5,153.95 63.85,153.95 63.85,163.73 60.08,163.73 60.08,177.55 53.19,177.55 53.19,181.6 50.21,181.6 50.21,197.11 49.68,197.11 49.68,197.45 48.2,197.45 48.2,230"/>
                <g>
			        <rect x="132.16" y="130.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="133.88" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="137.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="140.29" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="143.49" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="146.7" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="149.9" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="153.1" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="178.73" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="181.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="132.16" y="194.75" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="130.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="133.88" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="137.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="140.29" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="143.49" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="146.7" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="149.9" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="153.1" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="156.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="159.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="169.12" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="178.73" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="181.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="134.98" y="194.75" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="130.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="133.88" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="137.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="140.29" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="143.49" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="146.7" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="149.9" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="153.1" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="156.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="159.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="162.71" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="165.92" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="169.12" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="178.73" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="181.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="137.8" y="185.14" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="130.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="133.88" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="137.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="140.29" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="143.49" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="146.7" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="149.9" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="153.1" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="156.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="159.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="169.12" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="140.62" y="178.73" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="130.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="133.88" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="137.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="140.29" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="143.49" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="146.7" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="149.9" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="153.1" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="156.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="159.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="169.12" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="143.44" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="132.93" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="136.13" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="139.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="142.54" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="145.74" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="148.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="152.15" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="155.35" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="158.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="161.76" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="164.96" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="168.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="171.37" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="174.57" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="177.78" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="180.98" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="184.18" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="187.39" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="190.59" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="193.79" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="197" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="200.2" class="st5" width="1.78" height="1.91"/>
                    <rect x="258.54" y="203.4" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="132.93" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="136.13" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="139.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="142.54" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="145.74" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="148.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="152.15" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="155.35" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="158.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="161.76" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="164.96" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="168.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="171.37" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="174.57" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="177.78" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="180.98" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="184.18" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="187.39" class="st5" width="1.78" height="1.91"/>
                    <rect x="261.36" y="190.59" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="132.93" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="136.13" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="139.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="142.54" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="145.74" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="148.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="152.15" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="155.35" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="158.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="161.76" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="164.96" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="168.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="171.37" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="174.57" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="177.78" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="180.98" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="184.18" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="187.39" class="st5" width="1.78" height="1.91"/>
                    <rect x="264.18" y="190.59" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="132.93" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="136.13" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="139.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="142.54" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="145.74" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="148.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="152.15" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="155.35" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="158.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="161.76" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="164.96" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="168.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="171.37" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="174.57" class="st5" width="1.78" height="1.91"/>
                    <rect x="267" y="177.78" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="132.93" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="136.13" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="139.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="142.54" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="145.74" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="148.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="152.15" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="155.35" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="158.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="161.76" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="164.96" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="168.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="269.82" y="171.37" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="132.93" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="136.13" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="139.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="142.54" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="145.74" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="148.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="152.15" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="155.35" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="158.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="161.76" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="164.96" class="st5" width="1.78" height="1.91"/>
                    <rect x="272.64" y="168.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="156.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="159.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="162.71" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="165.92" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="169.12" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="178.73" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="181.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="185.14" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="188.34" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="191.55" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="194.75" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="197.95" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="201.16" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="204.36" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="207.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="65.42" y="210.77" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="156.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="159.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="162.71" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="165.92" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="169.12" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="178.73" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="181.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="185.14" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="188.34" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="191.55" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="194.75" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="197.95" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="201.16" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="204.36" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="207.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="68.24" y="210.77" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="156.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="159.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="162.71" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="165.92" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="169.12" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="172.33" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="175.53" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="178.73" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="181.94" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="185.14" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="188.34" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="191.55" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="194.75" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="197.95" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="201.16" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="204.36" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="207.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="71.06" y="210.77" class="st5" width="1.78" height="1.91"/>
                    <rect x="54.87" y="179.69" class="st5" width="1.78" height="1.91"/>
                    <rect x="54.87" y="182.89" class="st5" width="1.78" height="1.91"/>
                    <rect x="54.87" y="186.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="54.87" y="189.3" class="st5" width="1.78" height="1.91"/>
                    <rect x="57.69" y="179.69" class="st5" width="1.78" height="1.91"/>
                    <rect x="57.69" y="182.89" class="st5" width="1.78" height="1.91"/>
                    <rect x="57.69" y="186.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="57.69" y="189.3" class="st5" width="1.78" height="1.91"/>
                    <rect x="60.51" y="179.69" class="st5" width="1.78" height="1.91"/>
                    <rect x="60.51" y="182.89" class="st5" width="1.78" height="1.91"/>
                    <rect x="60.51" y="186.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="60.51" y="189.3" class="st5" width="1.78" height="1.91"/>
                    <rect x="60.51" y="192.5" class="st5" width="1.78" height="1.91"/>
                    <rect x="60.51" y="195.71" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="180.64" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="183.85" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="187.05" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="190.25" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="193.46" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="196.66" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="199.86" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="203.07" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="206.27" class="st5" width="1.78" height="1.91"/>
                    <rect x="76.17" y="209.47" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="180.64" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="183.85" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="187.05" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="190.25" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="193.46" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="196.66" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="199.86" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="203.07" class="st5" width="1.78" height="1.91"/>
                    <rect x="78.99" y="206.27" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="180.64" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="183.85" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="187.05" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="190.25" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="193.46" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="196.66" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="199.86" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="203.07" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="212.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="215.88" class="st5" width="1.78" height="1.91"/>
                    <rect x="81.81" y="219.09" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="173.62" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="176.82" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="180.02" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="183.23" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="189.64" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="192.84" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="196.04" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="199.25" class="st5" width="1.78" height="1.91"/>
                    <rect x="108.03" y="202.45" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="164.01" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="167.21" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="170.41" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="173.62" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="176.82" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="180.02" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="199.25" class="st5" width="1.78" height="1.91"/>
                    <rect x="110.85" y="202.45" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="157.6" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="160.8" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="164.01" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="167.21" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="170.41" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="173.62" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="176.82" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="180.02" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="186.43" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="189.64" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="192.84" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="196.04" class="st5" width="1.78" height="1.91"/>
                    <rect x="113.67" y="202.45" class="st5" width="1.78" height="1.91"/>
                    <rect x="100.61" y="207.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="100.61" y="210.77" class="st5" width="1.78" height="1.91"/>
                    <rect x="100.61" y="213.97" class="st5" width="1.78" height="1.91"/>
                    <rect x="100.61" y="217.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="100.61" y="220.38" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="194.75" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="197.95" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="201.16" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="204.36" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="207.56" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="210.77" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="213.97" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="217.17" class="st5" width="1.78" height="1.91"/>
                    <rect x="103.43" y="220.38" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="172.66" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="175.87" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="179.07" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="182.27" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="185.48" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="188.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="198.29" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="201.49" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="204.7" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="207.9" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="211.1" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="214.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="151.37" y="217.51" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="172.66" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="175.87" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="179.07" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="182.27" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="185.48" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="188.68" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="198.29" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="201.49" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="204.7" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="207.9" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="211.1" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="214.31" class="st5" width="1.78" height="1.91"/>
                    <rect x="154.19" y="220.72" class="st5" width="1.78" height="1.91"/>
                </g>
            </g>
            <path class="st4" d="M120.61,35c0-7.12,3.79-9.48,6.72-9.91c4.79-0.7,8.21,3.22,8.21,3.22s2.6-9.44,12.01-9.44c9.41,0,12.3,10.32,12.3,10.32s3.66-3.13,8.93-2.18c3.69,0.66,5.89,3.59,8.27,7.99H120.61z"/>
        </g>
        <g>
            <g>
                <rect x="215.83" y="16" class="st6" width="25.47" height="24.26"/>
                <rect x="215.83" y="16" class="st7" width="25.47" height="24.26"/>
                <rect x="239.87" y="16" class="st8" width="12.74" height="24.26"/>
                <rect x="224.56" y="20.76" class="st9" width="8" height="19.5"/>
                <rect x="224.56" y="20.76" class="st6" width="2" height="19.5"/>
            </g>
            <rect x="159.29" y="37.03" class="st10" width="66.34" height="193.05"/>
            <rect x="159.29" y="37.03" class="st11" width="66.34" height="193.05"/>
            <polygon class="st8" points="224.64,37.03 224.64,65.57 210,65.57 210,71.9 224.64,71.9 224.64,96.37 210,96.37 210,102.69 224.64,102.69 224.64,127.16 210,127.16 210,133.49 224.64,133.49 224.64,157.96 210,157.96 210,164.29 224.64,164.29 224.64,188.75 210,188.75 210,195.08 224.64,195.08 224.64,230.07 256,230.07 256,37.03"/>
            <polygon class="st12" points="224.64,37.03 224.64,65.57 210,65.57 210,71.9 224.64,71.9 224.64,96.37 210,96.37 210,102.69 224.64,102.69 224.64,127.16 210,127.16 210,133.49 224.64,133.49 224.64,157.96 210,157.96 210,164.29 224.64,164.29 224.64,188.75 210,188.75 210,195.08 224.64,195.08 224.64,230.07 256,230.07 256,37.03"/>
            <g>
                <g>
                    <rect x="150" y="188.75" class="st8" width="60.82" height="6.33"/>
                    <rect x="150" y="157.96" class="st8" width="60.82" height="6.33"/>
                    <rect x="150" y="127.16" class="st8" width="60.82" height="6.33"/>
                    <rect x="150" y="96.37" class="st8" width="60.82" height="6.33"/>
                    <rect x="150" y="65.57" class="st8" width="60.82" height="6.33"/>
                </g>
                <g class="st13">
			        <rect x="150" y="188.75" class="st14" width="60.82" height="6.33"/>
                    <rect x="150" y="157.96" class="st14" width="60.82" height="6.33"/>
                    <rect x="150" y="127.16" class="st14" width="60.82" height="6.33"/>
                    <rect x="150" y="96.37" class="st14" width="60.82" height="6.33"/>
                    <rect x="150" y="65.57" class="st14" width="60.82" height="6.33"/>
                </g>
            </g>
            <rect x="173.09" y="202" class="st6" width="38.75" height="28.08"/>
            <rect x="164.91" y="44.48" class="st6" width="16.06" height="16.45"/>
            <rect x="184.06" y="44.48" class="st6" width="16.06" height="16.45"/>
            <rect x="203.21" y="44.48" class="st15" width="16.06" height="16.45"/>
            <rect x="164.91" y="75.91" class="st15" width="16.06" height="16.45"/>
            <rect x="184.06" y="75.91" class="st6" width="16.06" height="16.45"/>
            <rect x="203.21" y="75.91" class="st15" width="16.06" height="16.45"/>
            <rect x="164.91" y="106.7" class="st15" width="16.06" height="16.45"/>
            <rect x="184.06" y="106.7" class="st15" width="16.06" height="16.45"/>
            <rect x="203.21" y="106.7" class="st6" width="16.06" height="16.45"/>
            <rect x="164.91" y="137.5" class="st15" width="16.06" height="16.45"/>
            <rect x="184.06" y="137.5" class="st6" width="16.06" height="16.45"/>
            <rect x="203.21" y="137.5" class="st15" width="16.06" height="16.45"/>
            <rect x="164.91" y="168.29" class="st6" width="16.06" height="16.45"/>
            <rect x="184.06" y="168.29" class="st6" width="16.06" height="16.45"/>
            <rect x="203.21" y="168.29" class="st15" width="16.06" height="16.45"/>
            <g>
		        <rect x="164.91" y="44.48" class="st6" width="1.69" height="16.45"/>
                <rect x="184.06" y="44.48" class="st6" width="1.69" height="16.45"/>
                <rect x="203.21" y="44.48" class="st6" width="1.69" height="16.45"/>
                <rect x="164.91" y="75.91" class="st6" width="1.69" height="16.45"/>
                <rect x="184.06" y="75.91" class="st6" width="1.69" height="16.45"/>
                <rect x="203.21" y="75.91" class="st6" width="1.69" height="16.45"/>
                <rect x="164.91" y="106.7" class="st6" width="1.69" height="16.45"/>
                <rect x="184.06" y="106.7" class="st6" width="1.69" height="16.45"/>
                <rect x="203.21" y="106.7" class="st6" width="1.69" height="16.45"/>
                <rect x="164.91" y="137.5" class="st6" width="1.69" height="16.45"/>
                <rect x="184.06" y="137.5" class="st6" width="1.69" height="16.45"/>
                <rect x="203.21" y="137.5" class="st6" width="1.69" height="16.45"/>
                <rect x="164.91" y="168.29" class="st6" width="1.69" height="16.45"/>
                <rect x="184.06" y="168.29" class="st6" width="1.69" height="16.45"/>
                <rect x="203.21" y="168.29" class="st6" width="1.69" height="16.45"/>
	        </g>
            <g class="st16">
		        <rect x="164.91" y="44.48" width="1.69" height="16.45"/>
                <rect x="184.06" y="44.48" width="1.69" height="16.45"/>
                <rect x="203.21" y="44.48" width="1.69" height="16.45"/>
                <rect x="164.91" y="75.91" width="1.69" height="16.45"/>
                <rect x="184.06" y="75.91" width="1.69" height="16.45"/>
                <rect x="203.21" y="75.91" width="1.69" height="16.45"/>
                <rect x="164.91" y="106.7" width="1.69" height="16.45"/>
                <rect x="184.06" y="106.7" width="1.69" height="16.45"/>
                <rect x="203.21" y="106.7" width="1.69" height="16.45"/>
                <rect x="164.91" y="137.5" width="1.69" height="16.45"/>
                <rect x="184.06" y="137.5" width="1.69" height="16.45"/>
                <rect x="203.21" y="137.5" width="1.69" height="16.45"/>
                <rect x="164.91" y="168.29" width="1.69" height="16.45"/>
                <rect x="184.06" y="168.29" width="1.69" height="16.45"/>
                <rect x="203.21" y="168.29" width="1.69" height="16.45"/>
	        </g>
            <rect x="192.46" y="203.9" class="st17" width="17.52" height="26.18"/>
            <rect x="174.94" y="203.9" class="st15" width="17.52" height="26.18"/>
            <rect x="174.94" y="203.9" class="st6" width="1.85" height="26.18"/>
            <rect x="174.94" y="203.9" class="st16" width="1.85" height="26.18"/>
        </g>
        <g>
            <g id="shield3">
                <path class="st18" d="M281.62,61.96c-6.03-1.32-11.46-3.55-16.05-6.4c-4.59,2.85-10.02,5.08-16.05,6.4 c-2.14,0.47-4.26,0.79-6.37,1c0,0.09-0.01,0.18-0.01,0.27c0,17.86,8.84,30.32,22.43,40.07C279.16,93.55,288,81.09,288,63.23 c0-0.09-0.01-0.18-0.01-0.27C285.89,62.75,283.76,62.43,281.62,61.96z"/>
                <path class="st19" d="M265.57,55.56c-4.59,2.85-10.02,5.08-16.05,6.4c-2.14,0.47-4.26,0.79-6.37,1c0,0.09-0.01,0.18-0.01,0.27 c0,17.86,8.84,30.32,22.43,40.07V55.56z"/>
                <g class="st20">
                    <path class="st14" d="M265.57,100.06l-0.6-0.47c-12.24-9.49-18.27-20.2-18.98-33.69l-0.04-0.74l0.81-0.13 c1.15-0.18,2.3-0.39,3.41-0.64c5.38-1.17,10.4-3.04,14.92-5.56l0.48-0.27l0.48,0.27c4.52,2.52,9.54,4.39,14.92,5.56l0,0 c1.12,0.24,2.27,0.46,3.41,0.64l0.81,0.13l-0.04,0.74c-0.71,13.49-6.74,24.2-18.98,33.69L265.57,100.06z M247.88,66.56 c0.85,12.43,6.49,22.41,17.69,31.29c11.2-8.88,16.84-18.86,17.69-31.29c-0.92-0.16-1.83-0.33-2.72-0.53l0,0 c-5.38-1.17-10.41-3.02-14.97-5.49c-4.56,2.47-9.59,4.32-14.97,5.49C249.71,66.23,248.8,66.4,247.88,66.56z"/>
                </g>
                <path class="st21" d="M265.57,97.85L265.57,97.85c-11.21-8.88-16.85-18.86-17.69-31.29c0.92-0.16,1.83-0.33,2.72-0.53 c5.38-1.17,10.41-3.02,14.97-5.49l0,0v-1.96l0,0l-0.48,0.27c-4.52,2.52-9.54,4.39-14.92,5.56c-1.11,0.24-2.26,0.46-3.41,0.64 l-0.81,0.13l0.04,0.74c0.71,13.49,6.74,24.19,18.98,33.69l0.6,0.47l0,0V97.85z"/>
                <polygon class="st14" points="264.3,87.12 254.48,78.22 257.4,75.57 264.3,81.82 276.81,70.49 279.73,73.14"/>
            </g>
            <g id="shield2">
		        <path class="st18" d="M241.89,164.49h-5.96c-3.46,0-6.28-2.84-6.28-6.33v-6.01c0-3.49,2.82-6.33,6.28-6.33h5.96c3.46,0,6.28,2.84,6.28,6.33v6.01C248.16,161.65,245.35,164.49,241.89,164.49z M235.93,147.67c-2.45,0-4.45,2.01-4.45,4.48v6.01 c0,2.47,2,4.48,4.45,4.48h5.96c2.45,0,4.45-2.01,4.45-4.48v-6.01c0-2.47-2-4.48-4.45-4.48H235.93z"/>
                <g class="st22">
			        <path class="st14" d="M241.89,164.49h-5.96c-3.46,0-6.28-2.84-6.28-6.33v-6.01c0-3.49,2.82-6.33,6.28-6.33h5.96 c3.46,0,6.28,2.84,6.28,6.33v6.01C248.16,161.65,245.35,164.49,241.89,164.49z M235.93,147.67c-2.45,0-4.45,2.01-4.45,4.48v6.01 c0,2.47,2,4.48,4.45,4.48h5.96c2.45,0,4.45-2.01,4.45-4.48v-6.01c0-2.47-2-4.48-4.45-4.48H235.93z"/>
		        </g>
                <polygon class="st18" points="238.26,160.29 229.56,151.53 232.15,148.92 238.26,155.07 249.32,143.92 251.91,146.53"/>
	        </g>
            <g id="shield1">
		        <path class="st18" d="M183.3,33.67h-4.61c-2.68,0-4.86-2.16-4.86-4.82v-4.58c0-2.66,2.18-4.82,4.86-4.82h4.61 c2.68,0,4.86,2.16,4.86,4.82v4.58C188.15,31.5,185.97,33.67,183.3,33.67z M178.69,20.86c-1.9,0-3.44,1.53-3.44,3.41v4.58 c0,1.88,1.54,3.41,3.44,3.41h4.61c1.9,0,3.44-1.53,3.44-3.41v-4.58c0-1.88-1.54-3.41-3.44-3.41H178.69z"/>
                <g class="st22">
			        <path class="st14" d="M183.3,33.67h-4.61c-2.68,0-4.86-2.16-4.86-4.82v-4.58c0-2.66,2.18-4.82,4.86-4.82h4.61 c2.68,0,4.86,2.16,4.86,4.82v4.58C188.15,31.5,185.97,33.67,183.3,33.67z M178.69,20.86c-1.9,0-3.44,1.53-3.44,3.41v4.58c0,1.88,1.54,3.41,3.44,3.41h4.61c1.9,0,3.44-1.53,3.44-3.41v-4.58c0-1.88-1.54-3.41-3.44-3.41H178.69z"/>
		        </g>
                <polygon class="st18" points="180.49,30.47 173.76,23.79 175.76,21.81 180.49,26.49 189.05,18 191.05,19.99"/>
            </g>
        </g>
        <g>
            <g id="handHouse2">
                <path class="st23" d="M169.06,56.53l6.92-1.05l-3.5-7.82c0,0-6.15,1.73-5.79,5.23L169.06,56.53z"/>
                <path class="st23" d="M180.36,49.52L178.68,47c-0.67-1.01-1.95-1.37-3.02-0.85l-3.18,1.51l3.5,7.82l3.7-2.47 C180.82,52.25,181.12,50.68,180.36,49.52z"/>
                <g>
                    <path class="st14" d="M124.94,42.38c8.28,3.49,14.37,11.16,22.11,14.87c0.2,0.08,0.46,0.16,0.4,0.16c-0.06,0.01-0.06,0.02,0,0.03c0.58,0.07,1.27-0.08,2.21-0.32c5.37-1.56,10.95-4.78,16.01-7.42c0.21-0.15,0.46-0.28,1.23,0.42l1.38,1.25 c1.75,1.58,2.97,3.59,3.62,5.68c0.31,1.03,0.1,1.22-0.15,1.39c-5.54,4.61-11.15,8.51-18.05,11.14c-2.14,0.8-5.19,1.53-7.75,1.26 c-4.04-0.38-7.93-2.5-10.74-4.16c-5.49-3.16-10.24-7.54-15.74-10.19C109.88,52.63,115.22,38.78,124.94,42.38L124.94,42.38z"/>
                </g>
                <path class="st19" d="M127.49,60.09c0.82,0.57,1.65,1.16,2.51,1.76c1.88,1.32,3.83,2.68,5.96,3.97c0.08,0.04,0.15,0.09,0.24,0.14c0.35-6.82-7.71-11.66-7.71-11.66C127.74,55.98,127.45,58.01,127.49,60.09z"/>
            </g>
            <g id="handHouse1">
		        <path class="st23" d="M169.42,55.96l6.89-1.22l-3.68-7.73c0,0-6.11,1.88-5.66,5.37L169.42,55.96z"/>
                <path class="st23" d="M180.55,48.69l-1.74-2.49c-0.69-0.99-1.98-1.32-3.04-0.78l-3.14,1.59l3.68,7.73l3.63-2.55C181.07,51.4,181.34,49.82,180.55,48.69z"/>
                <g>
			        <path class="st14" d="M124.97,42.88c8.36,3.29,14.64,10.81,22.46,14.33c0.2,0.07,0.47,0.15,0.4,0.15c-0.06,0.01-0.06,0.02,0,0.03c0.58,0.05,1.27-0.12,2.2-0.37c5.33-1.69,10.83-5.04,15.83-7.8c0.21-0.16,0.45-0.29,1.24,0.39l1.41,1.21c1.79,1.54,3.06,3.51,3.76,5.59c0.34,1.02,0.13,1.22-0.12,1.39c-5.43,4.74-10.94,8.77-17.78,11.57c-2.12,0.85-5.15,1.66-7.71,1.44c-4.05-0.28-7.99-2.31-10.84-3.9c-5.56-3.02-10.42-7.29-15.98-9.8C110.16,53.49,115.17,39.51,124.97,42.88L124.97,42.88z"/>
		        </g>
                <path class="st19" d="M127.95,60.53c0.83,0.55,1.68,1.12,2.55,1.7c1.91,1.28,3.89,2.59,6.06,3.82c0.08,0.04,0.16,0.08,0.24,0.14c0.19-6.82-7.99-11.48-7.99-11.48C128.1,56.41,127.86,58.45,127.95,60.53z"/>
	        </g>
            <g>
		        <path class="st14" d="M94.69,45.86c6.93-1.82,16.4-3.16,24.09-3.35c6.92-0.17,12.85,5.09,13.72,12.13c1.55,12.66-1.16,17.15-0.77,36.85c1.99,2.05,1.14,5.48-0.24,6.01c-11.63,4.51-29.91,3.38-36,2.73c-2.68-0.29-1.62-4.05-1.62-4.05c-0.85-6.56,0.51-6.21-0.86-14.27c-2.08-7.87-2-14.01-4.87-24.44C86.74,52.4,89.72,47.16,94.69,45.86z"/>
                <path class="st24" d="M88.14,57.47c0.25,0.91,0.48,1.79,0.68,2.64c0.84,3.4,1.38,6.34,1.82,9.08c0.71,4.41,1.2,8.29,2.37,12.72c1.36,8.07,0.01,7.71,0.85,14.27c0,0-1.06,3.75,1.62,4.04c6.09,0.65,24.37,1.78,36.01-2.73c1.38-0.53,2.23-3.97,0.24-6.01c-0.39-19.7,2.33-24.19,0.77-36.85c-0.87-7.04-6.8-12.3-13.72-12.13c-7.69,0.19-17.17,1.53-24.09,3.35C89.72,47.16,86.74,52.41,88.14,57.47z"/>
                <path class="st19" d="M88.82,60.11c0.84,3.4,1.38,6.34,1.82,9.08c3.68-2.54,3.49-11.8,3.49-11.8C92.14,57.89,90.32,58.89,88.82,60.11z"/>
	        </g>
            <g>
		        <path class="st23" d="M129.19,214.02c0.45,4.23,0.85,16.49,1.75,19.48c-1.68,3.25-4.03,3.96-5.55,2.06s-4.48-18.34-4.48-18.34L129.19,214.02z"/>
                <path class="st9" d="M132.71,164.42c-1.38,21.1-1.62,56.55-1.62,56.55l-9.78,0.44c0,0-4.94-21.78-5.69-44.6c-0.07-2.14-0.1-4.28-0.09-6.42c-2.43-15.73-5.36-27.51-7.95-37.19c-2.42-9.08-4.56-16.3-5.71-23.21l28.85-9.61C132.33,105.54,135.11,127.8,132.71,164.42z"/>
                <path class="st18" d="M122.24,232.51c1.86,0.14,7.93,0.15,9.13-0.6c1.2-0.76,0.49-12.7,0.49-12.7s-10.44-0.1-11.23,0.82C119.85,220.95,122.24,232.51,122.24,232.51z"/>
                <path class="st18" d="M142.12,237.12c0,0-10-3.02-10.73-7.06c-0.07-0.4-0.4-0.66-0.81-0.59l-7.26,1.25c-0.34,0.06-0.64,0.32-0.74,0.66l-2.22,7.25l2.05,0.07l0.76-1.04l0.86,1.09c11.15,0.36,15.97,0.39,18.19-0.25C142.59,237.49,142.12,237.12,142.12,237.12z"/>
	        </g>
            <path class="st13" d="M115.62,176.81c-0.07-2.14-0.1-4.28-0.09-6.42c-2.43-15.73-5.36-27.51-7.95-37.19c3.11-9.08,7.06-15.9,11.33-16.69C118.92,116.51,126.5,155.98,115.62,176.81z"/>
            <path class="st9" d="M131.66,103.83c0.16-3.41-0.53-7.01-0.53-7.01c-0.05-0.73-33.34,0.49-36.09,0.81c-3.45,0.41,0.71,16.37,3.83,21.67C123.05,119.03,131.23,108.15,131.66,103.83z"/>
            <path class="st14" d="M130.34,97.58c-3.81-0.31-28.54,0.08-35.14,1.07c-0.19,0.03-0.34,0.17-0.39,0.35c-0.21,0.87-0.14,1.72-0.07,2.21c0.04,0.25,0.25,0.43,0.5,0.41c2.34-0.14,14.53-0.8,35.25-0.98c0.27,0,0.49-0.23,0.48-0.51c-0.03-0.67-0.13-1.61-0.19-2.13C130.74,97.77,130.56,97.6,130.34,97.58z"/>
            <path class="st9" d="M109.08,97.2c-0.25,0.54,0.03,4.65,0.03,4.65l-1.77-0.07c0,0-0.54-3.44-0.29-4.43C108.21,97.15,109.08,97.2,109.08,97.2z"/>
            <path class="st9" d="M123.84,97.1c0.45,1.35,0.25,4.25,0.25,4.25l1.57,0.02c0,0,0.26-3.35-0.06-4.23C124.39,96.88,123.84,97.1,123.84,97.1z"/>
            <g>
		        <path class="st9" d="M93.76,169.91c1.25-30.82,0.07-50.14,0.47-64.62l27.9-0.94c0.58,5.39-0.98,27.77-10.39,63.19c-5.42,20.4-12.48,55.11-12.48,55.11l-9.67-1.55C89.6,221.09,88.84,194.35,93.76,169.91z"/>
                <path class="st18" d="M89.56,231.19c1.79,0.52,6.95-0.29,8.27-0.79c1.32-0.5,2.55-10.12,2.55-10.12s-10.21-2.21-11.16-1.47C88.26,219.55,89.56,231.19,89.56,231.19z"/>
                <path class="st18" d="M109.13,237.12c0,0-10-3.02-10.73-7.06c-0.07-0.4-0.4-0.66-0.81-0.59l-7.26,1.25c-0.34,0.06-0.64,0.32-0.74,0.66l-2.22,7.25l2.05,0.07l0.76-1.04l0.86,1.09c11.15,0.36,15.97,0.39,18.19-0.25C109.6,237.49,109.13,237.12,109.13,237.12z"/>
	        </g>
            <path class="st23" d="M101.23,30c0.85,6.67,0.1,10.67-0.9,14.48c-0.1,0.36,0.03,0.76,0.33,0.97c2.77,1.97,6.8,3.34,11.97,2.73c5.1-0.6,4.6-2.2,3.17-3.88c-0.2-0.24-0.46-0.42-0.75-0.55c-3.75-1.66-4.23-4.48-3.48-6.32L101.23,30z"/>
            <path class="st16" d="M101.23,30l10.35,7.43c-0.43,1.03-0.46,2.38,0.26,3.65c-1.82,0.3-3.76-0.05-5.57-1.25c-2.65-1.76-3.77-4.11-4.8-7.5C101.41,31.59,101.33,30.82,101.23,30z"/>
            <g id="head2">
                <g>
                    <path class="st25" d="M93.72,28.36c2.34,0.02,15-4.66,17.38-7.86c2.38-3.2-11.42-4.91-15.31-1.92C91.9,21.57,91.15,28.33,93.72,28.36z"/>
                    <path class="st26" d="M93.72,28.36c2.34,0.02,15-4.66,17.38-7.86c2.38-3.2-11.42-4.91-15.31-1.92C91.9,21.57,91.15,28.33,93.72,28.36z"/>
                </g>
                <path class="st9" d="M113.84,15.64c1.86,1.71,0.35,4.45,0.69,6.34c0.34,1.89,0.99,4.31-1.74,2.03C110.06,21.73,110.98,13.3,113.84,15.64z"/>
                <path class="st23" d="M98.44,26.25c1.67,6.22,1,7.41,4.76,9.96c5.66,3.83,13.48,3.17,13.28-3.45c-0.18-5.95-1.73-16.48-8.25-17.66C101.81,13.94,96.77,20.04,98.44,26.25z"/>
                <path class="st9" d="M99.94,30.39c3.02,1.33,3.37-2.82,3.14-3.1c-1.03-1.24-1.14-2.41-0.5-5.15c0.65-2.74-4.85-5.04-8.39-3.39c-3.26,1.51,0.56,3.75,0.43,6.09C94.39,28.99,97.5,29.31,99.94,30.39z"/>
                <path class="st23" d="M96.5,31.05c0.91,1.39,2.26,2.22,3.45,2.48c1.79,0.39,2.36-1.45,1.6-3.26c-0.69-1.63-2.45-3.79-4.12-3.36C95.78,27.33,95.46,29.47,96.5,31.05z"/>
                <path class="st9" d="M106,25.33c0.13,0.53,0.38,0.53,0.72,0.44s0.64-0.23,0.51-0.76c-0.13-0.53-0.51-0.89-0.85-0.8C106.04,24.29,105.87,24.79,106,25.33z"/>
                <path class="st9" d="M112.48,24.39c0.13,0.53,0.38,0.53,0.72,0.44c0.34-0.09,0.64-0.23,0.51-0.76c-0.13-0.53-0.51-0.89-0.85-0.8C112.52,23.35,112.35,23.85,112.48,24.39z"/>
                <path class="st27" d="M111.44,24.35c0,0,1.35,2.21,2.57,3.17c-0.59,0.83-1.86,0.75-1.86,0.75L111.44,24.35z"/>
                <path class="st9" d="M104.54,23.12c-0.05,0-0.11-0.01-0.15-0.04c-0.15-0.09-0.2-0.29-0.11-0.44c0.88-1.54,2.33-1.63,2.39-1.64c0.17-0.01,0.32,0.13,0.33,0.31c0.01,0.18-0.13,0.33-0.3,0.34l0,0c-0.05,0-1.18,0.09-1.88,1.32C104.75,23.06,104.64,23.12,104.54,23.12z"/>
                <path class="st9" d="M114.5,21.36c-0.09,0.04-0.21,0.03-0.29-0.04c-1.13-0.83-2.19-0.42-2.23-0.41c-0.16,0.06-0.34-0.02-0.4-0.18s0.02-0.35,0.18-0.42c0.06-0.02,1.41-0.55,2.82,0.48c0.14,0.1,0.17,0.31,0.07,0.45C114.61,21.3,114.56,21.34,114.5,21.36z"/>
                <path class="st28" d="M107.84,29.93c0,0,1.37,2.11,5.12,1.34"/>
                <g>
			        <path d="M97.5,20.59c0,0,5.3,17.07,13.92,18.25l1.62-0.63c0,0-4.51,0.41-8.04-4.54S98.7,20.23,98.7,20.23L97.5,20.59z"/>
                    <path class="st9" d="M110.55,38.66c0,0,0.88-1.22,2.37-1.62c1.5-0.4,2.11-0.15,2.11-0.15s-0.33,1.08-1.95,1.77S110.55,38.66,110.55,38.66z"/>
		        </g>
                <g>
			        <path class="st18" d="M117.66,15.73c-4.29,5.79-16.57,2.97-20.67,6.4c-4.07,3.41-3.28,6.19-3.27,6.23c-2.93,1.14-1.56-3.64-1.56-3.64c-3.64-8.8,1.9-14.57,10.8-16.39c8.33-1.7,11.07,6.09,11.07,6.09L117.66,15.73z"/>
                    <path class="st29" d="M114.03,14.42c-1.72,3.35-13.55,3.6-17.88,5.41c-4.33,1.81-3.99,4.89-3.99,4.89c-3.64-8.8,1.9-14.57,10.8-16.39C111.3,6.63,114.03,14.42,114.03,14.42z"/>
                    <path class="st18" d="M96.89,11c3.5-1.55,7.63,0.95,9.29,2.88c1.66,1.93,4.87,0.87,4.81-1.45c-0.01-0.52-0.18-1.03-0.44-1.52c-1.1-2.07-3.48-3.2-5.96-3.04c-4.21,0.27-11.37,1.56-12.43,7.16C92.15,15.03,93.29,12.59,96.89,11z"/>
		        </g>
	        </g>
            <g id="head1">
                <g>
                    <path class="st25" d="M93.05,27.65c2.33,0.19,15.29-3.56,17.9-6.59c2.61-3.02-11.03-5.72-15.13-3.02S90.49,27.44,93.05,27.65z"/>
                    <path class="st26" d="M93.05,27.65c2.33,0.19,15.29-3.56,17.9-6.59c2.61-3.02-11.03-5.72-15.13-3.02S90.49,27.44,93.05,27.65z"/>
                </g>
                <path class="st9" d="M114.03,16.42c1.73,1.84,0.03,4.47,0.23,6.37c0.2,1.91,0.68,4.37-1.88,1.9C109.82,22.22,111.34,13.88,114.03,16.42z"/>
                <path class="st23" d="M97.9,25.89c1.21,6.32,0.46,7.46,4.03,10.27c5.37,4.23,13.22,4.14,13.5-2.48c0.25-5.95-0.53-16.57-6.95-18.21C102.16,13.86,96.69,19.57,97.9,25.89z"/>
                <path class="st9" d="M99.1,30.12c2.92,1.55,3.57-2.56,3.35-2.87c-0.94-1.32-0.97-2.49-0.12-5.17c0.85-2.69-4.47-5.37-8.12-3.99c-3.36,1.27,0.28,3.78-0.01,6.1C93.67,28.33,96.75,28.88,99.1,30.12z"/>
                <path class="st23" d="M95.62,30.54c0.81,1.45,2.09,2.38,3.26,2.72c1.76,0.51,2.46-1.28,1.83-3.14c-0.57-1.67-2.17-3.96-3.87-3.65C95.18,26.78,94.69,28.88,95.62,30.54z"/>
                <path class="st9" d="M105.51,25.52c0.09,0.54,0.34,0.56,0.68,0.49c0.34-0.06,0.66-0.18,0.56-0.72c-0.09-0.54-0.44-0.93-0.79-0.86C105.62,24.48,105.42,24.97,105.51,25.52z"/>
                <path class="st9" d="M112.04,25.05c0.09,0.54,0.34,0.56,0.68,0.49c0.34-0.06,0.66-0.18,0.56-0.72c-0.09-0.54-0.44-0.93-0.79-0.86C112.16,24.02,111.95,24.51,112.04,25.05z"/>
                <path class="st27" d="M111.01,24.94c0,0,1.19,2.3,2.34,3.35c-0.65,0.78-1.91,0.61-1.91,0.61L111.01,24.94z"/>
                <path class="st9" d="M104.21,23.21c-0.05,0-0.1-0.02-0.15-0.05c-0.14-0.1-0.18-0.3-0.08-0.45c0.99-1.47,2.45-1.46,2.51-1.46c0.17,0,0.31,0.15,0.3,0.33c-0.01,0.18-0.15,0.32-0.32,0.32l0,0c-0.05,0-1.18,0-1.97,1.18C104.43,23.17,104.32,23.22,104.21,23.21z"/>
                <path class="st9" d="M114.28,22.18c-0.1,0.03-0.21,0.01-0.29-0.06c-1.06-0.91-2.15-0.58-2.2-0.57c-0.17,0.05-0.34-0.04-0.39-0.21c-0.05-0.17,0.04-0.35,0.21-0.4c0.06-0.02,1.45-0.45,2.78,0.69c0.13,0.11,0.15,0.32,0.04,0.46C114.39,22.12,114.33,22.16,114.28,22.18z"/>
                <path class="st28" d="M107.01,30.24c0,0,1.21,2.21,5.01,1.71"/>
                <g>
			        <path d="M97.37,20.18c0,0,4.05,17.41,12.56,19.21l1.66-0.52c0,0-4.53,0.08-7.69-5.11c-3.16-5.19-5.3-13.86-5.3-13.86L97.37,20.18z"/>
                    <path class="st9" d="M109.09,39.15c0,0,0.96-1.16,2.48-1.45c1.52-0.29,2.11,0,2.11,0s-0.41,1.05-2.07,1.62C109.95,39.9,109.09,39.15,109.09,39.15z"/>
		        </g>
                <g>
			        <path class="st18" d="M117.83,16.79c-4.7,5.47-16.74,1.76-21.08,4.89c-4.3,3.11-3.72,5.94-3.71,5.97c-3.01,0.93-1.29-3.74-1.29-3.74c-2.99-9.04,2.95-14.4,11.96-15.56c8.44-1.09,10.6,6.88,10.6,6.88L117.83,16.79z"/>
                    <path class="st29" d="M114.31,15.22c-1.96,3.21-13.77,2.61-18.22,4.1c-4.45,1.49-4.33,4.59-4.33,4.59c-2.99-9.04,2.95-14.4,11.96-15.56C112.15,7.25,114.31,15.22,114.31,15.22z"/>
                    <path class="st18" d="M97.46,10.56c3.6-1.29,7.54,1.49,9.06,3.54c1.52,2.05,4.79,1.22,4.9-1.1c0.02-0.52-0.11-1.04-0.33-1.54c-0.95-2.14-3.24-3.44-5.73-3.46c-4.22-0.04-11.45,0.73-12.92,6.24C92.44,14.24,93.75,11.89,97.46,10.56z"/>
                </g>
            </g>
            <g>
		        <path class="st18" d="M91.94,80.29c0.63,2.16,1.17,3.88,1.06,4.88c-0.01,0.06-0.02,0.12-0.02,0.19c-0.06,0.63-0.17,1.88-0.3,3.42c-0.14,1.62-0.3,3.57-0.45,5.4c-0.27,3.39-0.52,6.4-0.52,6.4l25.06-1.42l1.06-5.07l1.95,5.07h13.86l-1.29-7.6l-0.21-1.23l0.23-3.93l0.21-3.35l0.31-5.16l0.11-1.8c0.49-16.54-7.23-32.82-7.23-32.82c-4.55-1.9-9.62-0.65-9.62-0.65c4.77,6.38,0,16.02,0,16.02C108,48.8,98.04,44.91,98.04,44.91c-6.17-0.1-8.48,2.68-8.48,2.68c8.5,15.97,0.76,25.77,0.76,25.77C90.66,75.87,91.35,78.27,91.94,80.29z"/>
                <path class="st24" d="M93.01,85.17c-0.01,0.06-0.02,0.12-0.02,0.19c4.22-1.13,15.97-1.92,15.97-1.92l-0.49-5.12c-6.74,0.07-14.87,1.63-16.51,1.96C92.58,82.44,93.12,84.17,93.01,85.17z"/>
                <path class="st24" d="M92.23,94.17c3.88-1.19,16.3-2.03,16.3-2.03l0.41-5.17c-5.81,0.06-13.19,1.26-16.26,1.8C92.55,90.4,92.39,92.35,92.23,94.17z"/>
                <path class="st24" d="M124.56,82.68c0,0,3.89,0.09,8.01,0.36l0.31-5.16c-2.65-0.22-5.57-0.36-8.25-0.27L124.56,82.68z"/>
                <path class="st24" d="M124.85,86.19l0.82,5.09c0,0,3.09,0.08,6.68,0.27l-0.21-1.23l0.23-3.93C129.86,86.21,127.22,86.11,124.85,86.19z"/>
                <g class="st16">
                    <path d="M116.14,58.63c0.2,1.48,0.41,2.94,0.57,4.42c0.18,1.47,0.35,2.95,0.43,4.42c0.18,2.98,0.47,5.93,0.67,8.89c0.22,2.96,0.39,5.92,0.49,8.88c0.05,1.48,0.05,2.96,0,4.44c-0.05,1.48-0.17,2.95-0.46,4.42c0.89-2.85,1.03-5.89,1.09-8.86c0.06-2.99-0.09-5.97-0.3-8.94c-0.22-2.97-0.53-5.93-0.86-8.88C117.45,64.43,116.87,61.52,116.14,58.63z"/>
                </g>
            </g>
            <g id="handRight2">
                <g>
                    <path class="st23" d="M90.75,94.71l6.91,1.08l-3.53,7.8c0,0-6.14-1.76-5.76-5.26L90.75,94.71z"/>
                    <path class="st23" d="M102.02,101.76l-1.69,2.52c-0.67,1-1.95,1.36-3.03,0.84l-3.17-1.53l3.53-7.8l3.68,2.48C102.48,99.04,102.79,100.61,102.02,101.76z"/>
                </g>
                <g>
			        <path class="st14" d="M97.79,59.86C92.8,65.29,86.84,71.72,83.84,78.1c0-0.09-0.08-0.21-0.05-0.02c0.43,2.02,1.96,4.04,3.43,6.03c2.14,2.75,4.63,5.57,7.03,8.19l0.53,0.57c0.19,0.16,0.38,0.36-0.11,1.28c-1.22,2.57-2.97,5.24-5.41,6.62c-0.92,0.56-1.15,0.41-1.38,0.21C81.02,96.03,71.51,88.05,70.36,79c-0.69-9.65,10.32-22.44,16.37-29.49C93.88,42.26,104.56,52.24,97.79,59.86L97.79,59.86z"/>
                </g>
            </g>
            <g id="handRight1">
                <g>
                    <path class="st23" d="M89.91,95.16l6.83,1.53l-4.03,7.56c0,0-6.02-2.15-5.41-5.62L89.91,95.16z"/>
                    <path class="st23" d="M100.69,102.92l-1.85,2.41c-0.74,0.96-2.04,1.23-3.08,0.64l-3.06-1.73l4.03-7.56l3.51,2.71C101.34,100.24,101.53,101.83,100.69,102.92z"/>
                </g>
                <g>
			        <path class="st14" d="M99.19,60.84c-5.33,5.09-11.7,11.12-15.1,17.3c0.01-0.09-0.06-0.22-0.05-0.03c0.3,2.04,1.69,4.16,3.03,6.24c1.96,2.88,4.26,5.86,6.49,8.63c0,0,0.5,0.6,0.5,0.6c0.18,0.17,0.35,0.39-0.19,1.27c-1.38,2.48-3.31,5.04-5.83,6.26c-0.95,0.5-1.18,0.33-1.39,0.12c-6.52-5.38-15.49-13.96-16.05-23.06c-0.06-9.67,11.76-21.73,18.25-28.36C96.43,43.02,106.45,53.67,99.19,60.84L99.19,60.84z"/>
                </g>
            </g>
            <path class="st18" d="M91.44,71.49c1.78-3.56,4.44-12.02-1.88-23.9c0,0,2.32-2.79,8.49-2.68c0,0,0.04,0.02,0.11,0.05c9.62,3.97,10.15,17.76,0.9,22.57L91.44,71.49z"/>
        </g>
        <g>
	        <polygon class="st30" points="77.63,207.43 72.4,218 70.53,217.56 76.04,207.43"/>
            <polygon class="st31" points="77.63,207.43 72.4,218 70.53,217.56 76.04,207.43"/>
            <polygon class="st30" points="76.04,197.42 70.82,208 68.94,207.55 74.45,197.42"/>
            <polygon class="st31" points="76.04,197.42 70.82,208 68.94,207.55 74.45,197.42"/>
            <polygon class="st32" points="61.02,192 38.45,192 43.74,214.04 66.31,214.04"/>
            <polygon class="st31" points="61.02,192 38.45,192 43.74,214.04 66.31,214.04"/>
            <g>
		        <rect x="42.68" y="205.7" class="st30" width="35.86" height="2.84"/>
                <rect x="42.68" y="205.7" class="st29" width="13.76" height="2.84"/>
	        </g>
            <g>
		        <rect x="40.57" y="197.35" class="st30" width="35.86" height="2.84"/>
                <rect x="40.57" y="197.35" class="st29" width="13.76" height="2.84"/>
	        </g>
            <polygon class="st30" points="38.49,192.15 37.23,194.44 41.99,214.09 43.85,214.09"/>
            <g>
		        <path class="st8" d="M87.58,203.67l2.42,0.95c-0.15-1.78-1.05-3.93-2.43-4.47c-1.57-0.62-3.15,0.81-3.74,3.13c-0.2,0.8-0.26,1.62-0.16,2.49l-14.6,13.85c-1.71-2.27-3.74-0.56-4.57,1.28l2.41,0.93c0.57,0.22,0.91,1.12,0.77,2.01c-0.14,0.89-0.72,1.43-1.28,1.21l-2.42-0.95c0.15,1.78,1.05,3.93,2.43,4.47c1.57,0.62,3.2-0.68,3.79-2.99c0.2-0.8,0.31-1.48,0.21-2.36L85,209.37c1.44,1.55,3.69,0.43,4.52-1.42l-2.44-0.99c-0.57-0.22-0.92-1.15-0.78-2.04C86.45,204.02,87.01,203.45,87.58,203.67z"/>
                <path class="st24" d="M87.58,203.67l2.42,0.95c-0.15-1.78-1.05-3.93-2.43-4.47c-1.57-0.62-3.15,0.81-3.74,3.13c-0.2,0.8-0.26,1.62-0.16,2.49l-14.6,13.85c-1.71-2.27-3.74-0.56-4.57,1.28l2.41,0.93c0.57,0.22,0.91,1.12,0.77,2.01c-0.14,0.89-0.72,1.43-1.28,1.21l-2.42-0.95c0.15,1.78,1.05,3.93,2.43,4.47c1.57,0.62,3.2-0.68,3.79-2.99c0.2-0.8,0.31-1.48,0.21-2.36L85,209.37c1.44,1.55,3.69,0.43,4.52-1.42l-2.44-0.99c-0.57-0.22-0.92-1.15-0.78-2.04C86.45,204.02,87.01,203.45,87.58,203.67z"/>
	        </g>
            <rect x="43.74" y="214.04" class="st30" width="39.03" height="17.96"/>
            <rect x="43.74" y="214.04" class="st29" width="15.35" height="17.96"/>
            <g>
		        <path class="st8" d="M30.03,227.45l-1.76-2.79c-0.46,1.64-0.39,4.22,0.62,5.81c1.14,1.81,2.96,1.87,4.23,0.37c0.44-0.52,0.76-1.17,0.96-2l17.04,0.04c0.72,3.34,3.02,3.53,4.34,2.63l-1.76-2.76c-0.41-0.65-0.41-1.69,0-2.35s1.08-0.64,1.5,0.01l1.76,2.79c0.46-1.64,0.39-4.22-0.62-5.81c-1.14-1.81-2.96-2.03-4.23-0.52c-0.44,0.52-0.76,1.01-0.96,1.85l-17.04-0.04c-0.72-2.5-3.02-3.37-4.34-2.47l1.76,2.84c0.41,0.65,0.41,1.73,0,2.38C31.12,228.08,30.45,228.1,30.03,227.45z"/>
                <path class="st24" d="M30.03,227.45l-1.76-2.79c-0.46,1.64-0.39,4.22,0.62,5.81c1.14,1.81,2.96,1.87,4.23,0.37c0.44-0.52,0.76-1.17,0.96-2l17.04,0.04c0.72,3.34,3.02,3.53,4.34,2.63l-1.76-2.76c-0.41-0.65-0.41-1.69,0-2.35s1.08-0.64,1.5,0.01l1.76,2.79c0.46-1.64,0.39-4.22-0.62-5.81c-1.14-1.81-2.96-2.03-4.23-0.52c-0.44,0.52-0.76,1.01-0.96,1.85l-17.04-0.04c-0.72-2.5-3.02-3.37-4.34-2.47l1.76,2.84c0.41,0.65,0.41,1.73,0,2.38C31.12,228.08,30.45,228.1,30.03,227.45z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "AdvantagesSvg"
    }
</script>

<style scoped lang="scss">
    #AdvantagesSvg{
        .st0{fill:#D1EBFF;}
        .st1{fill:#BBBBBC;}
        .st2{fill:#A7A7AA;}
        .st3{fill:#E1E1E1;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st4{fill:#EEEEEB;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st5{fill:#F5F5F5;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st6{fill:#8A8A8A;}
        .st7{opacity:0.3;fill:#FFFFFF;}
        .st8{fill:#5B5B5B;}
        .st9{fill:#1A2E35;}
        .st10{fill:#6F7070;}
        .st11{opacity:0.6;fill:#FFFFFF;}
        .st12{opacity:0.2;fill:#FFFFFF;}
        .st13{opacity:0.4;}
        .st14{fill:#FFFFFF;}
        .st15{opacity:0.7;fill:#FFFFFF;}
        .st16{opacity:0.2;}
        .st17{opacity:0.4;fill:#FFFFFF;}
        .st18{fill:#FFAC1A;}
        .st19{opacity:0.1;}
        .st20{opacity:0.5;}
        .st21{opacity:0.9;fill:#F0F0F0;}
        .st22{opacity:0.6;}
        .st23{fill:#FCB373;}
        .st24{opacity:0.6;fill:#FFFFFF;enable-background:new;}
        .st25{fill:#007CFF;}
        .st26{opacity:0.4;enable-background:new;}
        .st27{fill:#EC873E;}
        .st28{fill:none;stroke:#1A2E35;stroke-width:0.75;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .st29{opacity:0.2;enable-background:new;}
        .st30{fill:#8F8F8F;}
        .st31{opacity:0.3;enable-background:new;}
        .st32{fill:#8F9393;}
    }
</style>