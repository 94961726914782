<template>
	<svg version="1.1" id="ElectricitySvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70 30" style="enable-background:new 0 0 70 30;" xml:space="preserve">
		<path class="st0" d="M64.53,3H5.81c-0.44,1.5-0.79,3.58,0,5.58c0.9,2.29,2.42,2.27,3.15,4.46c0.94,2.87-1.17,4.4,0,6.69c0.84,1.65,2.15,1.27,3.15,3.35c0.71,1.48,0.25,2.09,1.05,3.35c0.94,1.47,2.45,1.99,3.15,2.23c1.07,0.37,2.41,0.56,5.24,0c4.56-0.91,4.5-2.09,7.34-2.23c2.49-0.12,4.94,0.68,6.29,1.12c1.26,0.41,2.35,0.89,4.19,1.12c1.19,0.14,2.06,0.25,3.15,0c2.08-0.48,2.16-1.62,4.19-2.23c1.45-0.43,2.69-0.24,4.19,0c2.52,0.4,2.54,1.08,4.19,1.12c2.82,0.06,4.88-1.88,5.24-2.23c1.43-1.4,1.98-2.97,2.1-3.35c0.92-2.82-0.5-3.8,0-6.69c0.56-3.26,2.65-3.61,3.15-6.69C65.94,6.3,65.17,4.27,64.53,3z"/>
		<g>
			<g>
				<rect x="36" y="7" class="st1" width="17" height="17"/>
			</g>
			<g>
				<rect x="36.71" y="7.71" class="st2" width="15.58" height="15.58"/>
			</g>
			<polygon id="wire4" class="st3" points="45.5,18.2 45.43,18.2 45.43,16.07 41.08,16.07 41.08,18.2 41.01,18.2 41.01,16 45.5,16"/>
			<polygon id="wire3" class="st4" points="47.17,18.37 47.1,18.37 47.1,17.25 43.34,17.25 43.34,14.24 40.99,14.24 40.99,12.85 41.06,12.85 41.06,14.17 43.41,14.17 43.41,17.18 47.17,17.18"/>
			<polygon id="wire2" class="st3" points="48.84,18.49 48.77,18.49 48.77,14.89 44.47,14.89 44.47,11.19 41.9,11.19 41.9,11.12 44.53,11.12 44.53,14.82 48.84,14.82"/>
			<polygon id="wire1" class="st4" points="50.51,18.08 50.44,18.08 50.44,16.28 46,16.28 46,15.32 48.41,15.32 48.41,13.23 48.49,13.23 48.49,15.39 46.07,15.39 46.07,16.21 50.51,16.21"/>
			<g>
				<ellipse transform="matrix(1 -2.242652e-03 2.242652e-03 1 -0.0253 0.0921)" class="st5" cx="41.05" cy="11.34" rx="2.64" ry="2.64"/>
				<path class="st6" d="M43.38,11.34c0,1.3-1.05,2.35-2.35,2.35c-1.3,0-2.35-1.05-2.35-2.35c0-1.3,1.05-2.35,2.35-2.35C42.33,8.99,43.38,10.04,43.38,11.34z"/>
				<path class="st7" d="M42.6,12.52h-3.16c-0.17,0-0.31-0.14-0.31-0.31v-1.74c0-0.17,0.14-0.31,0.31-0.31h3.16c0.17,0,0.31,0.14,0.31,0.31v1.74C42.91,12.38,42.77,12.52,42.6,12.52z"/>
				<rect x="39.43" y="10.46" class="st8" width="3.21" height="0.69"/>
				<rect x="39.55" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="40.2" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="40.84" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="41.49" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="42.13" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="39.43" y="11.46" class="st8" width="3.21" height="0.69"/>
				<g class="st10">
					<g>
						<path class="st11" d="M39.74,9.98c-0.16,0-0.23-0.2-0.11-0.3c0.39-0.32,0.88-0.51,1.39-0.51c0.51,0,1.01,0.18,1.39,0.51c0.12,0.1,0.05,0.3-0.11,0.3H39.74z"/>
					</g>
					<g>
						<path class="st11" d="M41.03,13.5c-0.51,0-1.01-0.18-1.39-0.51c-0.12-0.1-0.05-0.3,0.11-0.3h2.57c0.16,0,0.23,0.2,0.11,0.3C42.03,13.32,41.54,13.5,41.03,13.5z"/>
					</g>
				</g>
			</g>
			<g>
				<ellipse transform="matrix(1 -2.254876e-03 2.254876e-03 1 -0.0254 0.1091)" class="st5" cx="48.38" cy="11.34" rx="2.64" ry="2.64"/>
				<path class="st6" d="M50.71,11.34c0,1.3-1.05,2.35-2.35,2.35c-1.3,0-2.35-1.05-2.35-2.35c0-1.3,1.05-2.35,2.35-2.35 C49.66,8.99,50.71,10.04,50.71,11.34z"/>
				<path class="st7" d="M49.94,12.52h-3.16c-0.17,0-0.31-0.14-0.31-0.31v-1.74c0-0.17,0.14-0.31,0.31-0.31h3.16 c0.17,0,0.31,0.14,0.31,0.31v1.74C50.25,12.38,50.11,12.52,49.94,12.52z"/>
				<rect x="46.77" y="10.46" class="st8" width="3.21" height="0.69"/>
				<rect x="46.89" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="47.53" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="48.18" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="48.82" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="49.47" y="10.62" class="st9" width="0.41" height="0.38"/>
				<rect x="46.77" y="11.46" class="st8" width="3.21" height="0.69"/>
				<g class="st10">
					<g>
						<path class="st11" d="M47.08,9.98c-0.16,0-0.23-0.2-0.11-0.3c0.39-0.32,0.88-0.51,1.39-0.51c0.51,0,1.01,0.18,1.39,0.51 c0.12,0.1,0.05,0.3-0.11,0.3H47.08z"/>
					</g>
					<g>
						<path class="st11" d="M48.36,13.5c-0.51,0-1.01-0.18-1.39-0.51c-0.12-0.1-0.05-0.3,0.11-0.3h2.57c0.16,0,0.23,0.2,0.11,0.3 C49.37,13.32,48.88,13.5,48.36,13.5z"/>
					</g>
				</g>
			</g>
			<g>
				<circle class="st5" cx="41.05" cy="19.85" r="2.64"/>
				<path class="st6" d="M43.38,19.85c0,1.3-1.05,2.35-2.35,2.35c-1.3,0-2.35-1.05-2.35-2.35c0-1.3,1.05-2.35,2.35-2.35 C42.33,17.5,43.38,18.55,43.38,19.85z"/>
				<path class="st7" d="M42.6,21.03h-3.16c-0.17,0-0.31-0.14-0.31-0.31v-1.74c0-0.17,0.14-0.31,0.31-0.31h3.16 c0.17,0,0.31,0.14,0.31,0.31v1.74C42.91,20.89,42.77,21.03,42.6,21.03z"/>
				<rect x="39.43" y="18.97" class="st8" width="3.21" height="0.69"/>
				<rect x="39.55" y="19.13" class="st9" width="0.41" height="0.38"/>
				<rect x="40.2" y="19.13" class="st9" width="0.41" height="0.38"/>
				<rect x="40.84" y="19.13" class="st9" width="0.41" height="0.38"/>
				<rect x="41.49" y="19.13" class="st9" width="0.41" height="0.38"/>
				<rect x="42.13" y="19.13" class="st9" width="0.41" height="0.38"/>
				<rect x="39.43" y="19.98" class="st8" width="3.21" height="0.69"/>
				<g class="st10">
					<g>
						<path class="st11" d="M39.74,18.49c-0.16,0-0.23-0.2-0.11-0.3c0.39-0.32,0.88-0.51,1.39-0.51c0.51,0,1.01,0.18,1.39,0.51 c0.12,0.1,0.05,0.3-0.11,0.3H39.74z"/>
					</g>
					<g>
						<path class="st11" d="M41.03,22.02c-0.51,0-1.01-0.18-1.39-0.51c-0.12-0.1-0.05-0.3,0.11-0.3h2.57c0.16,0,0.23,0.2,0.11,0.3 C42.03,21.83,41.54,22.02,41.03,22.02z"/>
					</g>
				</g>
			</g>
			<g>
				<rect x="44.99" y="17.89" class="st12" width="0.96" height="4.12"/>
				<rect x="44.8" y="18.8" class="st13" width="1.33" height="2.32"/>
			</g>
			<g>
				<rect x="45.33" y="19.46" class="st8" width="0.27" height="0.38"/>
				<rect x="45.07" y="19.27" class="st8" width="0.79" height="0.25"/>
			</g>
			<g>
				<rect x="46.66" y="17.89" class="st12" width="0.96" height="4.12"/>
				<rect x="46.47" y="18.8" class="st13" width="1.33" height="2.32"/>
			</g>
			<g>
				<rect x="47" y="19.46" class="st8" width="0.27" height="0.38"/>
				<rect x="46.74" y="19.27" class="st8" width="0.79" height="0.25"/>
			</g>
			<g>
				<rect x="48.33" y="17.89" class="st12" width="0.96" height="4.12"/>
				<rect x="48.14" y="18.8" class="st13" width="1.33" height="2.32"/>
			</g>
			<g>
				<rect x="48.67" y="19.46" class="st8" width="0.27" height="0.38"/>
				<rect x="48.41" y="19.27" class="st8" width="0.79" height="0.25"/>
			</g>
			<g>
				<rect x="50" y="17.89" class="st12" width="0.96" height="4.12"/>
				<rect x="49.81" y="18.8" class="st13" width="1.33" height="2.32"/>
			</g>
			<g>
				<rect x="50.35" y="19.46" class="st8" width="0.27" height="0.38"/>
				<rect x="50.09" y="19.27" class="st8" width="0.79" height="0.25"/>
			</g>
			<rect x="45.33" y="19.85" class="st12" width="0.27" height="0.82"/>
			<rect x="47" y="19.85" class="st12" width="0.27" height="0.82"/>
			<rect x="48.67" y="19.85" class="st12" width="0.27" height="0.82"/>
			<rect x="50.35" y="19.85" class="st12" width="0.27" height="0.82"/>
		</g>
		<g>
			<rect x="17.19" y="3" class="st14" width="13.81" height="18"/>
			<rect x="18.47" y="4.29" class="st15" width="11.25" height="15.43"/>
			<path class="st16" d="M27.39,6.15H20.8v0c0-0.22,0.26-0.4,0.59-0.4h5.41C27.12,5.75,27.39,5.93,27.39,6.15L27.39,6.15z"/>
			<path class="st17" d="M27.39,6.15H20.8v0c0,0.11,0.26,0.2,0.59,0.2h5.41C27.12,6.35,27.39,6.26,27.39,6.15L27.39,6.15z"/>
			<path class="st16" d="M27.39,7.25H20.8v0c0-0.22,0.26-0.4,0.59-0.4h5.41C27.12,6.85,27.39,7.03,27.39,7.25L27.39,7.25z"/>
			<path class="st17" d="M27.39,7.25H20.8v0c0,0.11,0.26,0.2,0.59,0.2h5.41C27.12,7.45,27.39,7.36,27.39,7.25L27.39,7.25z"/>
			<path class="st16" d="M27.39,8.35H20.8v0c0-0.22,0.26-0.4,0.59-0.4h5.41C27.12,7.95,27.39,8.13,27.39,8.35L27.39,8.35z"/>
			<path class="st17" d="M27.39,8.35H20.8v0c0,0.11,0.26,0.2,0.59,0.2h5.41C27.12,8.55,27.39,8.46,27.39,8.35L27.39,8.35z"/>
			<path class="st16" d="M27.39,9.45H20.8v0c0-0.22,0.26-0.4,0.59-0.4h5.41C27.12,9.05,27.39,9.22,27.39,9.45L27.39,9.45z"/>
			<path class="st17" d="M27.39,9.45H20.8v0c0,0.11,0.26,0.2,0.59,0.2h5.41C27.12,9.65,27.39,9.56,27.39,9.45L27.39,9.45z"/>
			<path class="st16" d="M27.39,10.55H20.8v0c0-0.22,0.26-0.4,0.59-0.4h5.41C27.12,10.15,27.39,10.32,27.39,10.55L27.39,10.55z"/>
			<path class="st17" d="M27.39,10.55H20.8v0c0,0.11,0.26,0.2,0.59,0.2h5.41C27.12,10.75,27.39,10.66,27.39,10.55L27.39,10.55z"/>
			<g>
				<path class="st18" d="M24.09,18.19h-3.08c-0.3,0-0.49-0.33-0.34-0.59l1.54-2.68l1.54-2.68c0.15-0.26,0.53-0.26,0.68,0l1.54,2.68l1.54,2.68c0.15,0.26-0.04,0.59-0.34,0.59H24.09z"/>
				<path class="st19" d="M26.55,17.83h-4.91c-0.15,0-0.27-0.08-0.35-0.2c-0.07-0.13-0.07-0.28,0-0.4l2.46-4.27c0.07-0.13,0.2-0.2,0.35-0.2c0.15,0,0.27,0.08,0.35,0.2l2.46,4.27c0.07,0.13,0.07,0.28,0,0.4C26.82,17.75,26.69,17.83,26.55,17.83z M24.09,13.08c-0.01,0-0.04,0-0.06,0.03l-2.46,4.27c-0.02,0.03-0.01,0.06,0,0.07c0.01,0.01,0.03,0.04,0.06,0.04h4.91c0.04,0,0.05-0.02,0.06-0.04c0.01-0.01,0.02-0.04,0-0.07l-2.46-4.27C24.14,13.09,24.11,13.08,24.09,13.08z"/>
				<polygon id="zipper" class="st19" points="23.8,15.79 24.8,14.49 23.96,14.58 23.18,16.14 23.98,16.06 23.5,17.22 24.78,15.66"/>
			</g>
			<path class="st20" d="M20.35,12.92c0,0.39-0.32,0.71-0.71,0.71c-0.39,0-0.71-0.32-0.71-0.71c0-0.39,0.32-0.71,0.71-0.71C20.03,12.2,20.35,12.52,20.35,12.92z"/>
			<path class="st21" d="M19.8,12.7c0-0.09-0.07-0.16-0.16-0.16c-0.09,0-0.16,0.07-0.16,0.16c0,0.07,0.04,0.12,0.1,0.15l-0.1,0.4h0.32l-0.1-0.4C19.76,12.82,19.8,12.76,19.8,12.7z"/>
			<rect x="29.72" y="6.15" class="st16" width="0.36" height="2.49"/>
			<rect x="29.72" y="14.33" class="st16" width="0.36" height="2.49"/>
		</g>
	</svg>
</template>

<script>
    export default {
        name: "ElectricitySvg"
    }
</script>

<style scoped lang="scss">
	#ElectricitySvg{
		.st0{fill:#D1EBFF;}
		.st1{fill:#6D6C6C;}
		.st2{fill:#848484;}
		.st3{fill:#FFAC1A;stroke:#FFAC1A;stroke-width:0.25;stroke-miterlimit:10;}
		.st4{fill:#2872A0;stroke:#2872A0;stroke-width:0.25;stroke-miterlimit:10;}
		.st5{fill:#5B5B5B;}
		.st6{fill:#3F3F3F;}
		.st7{fill:#7496B3;}
		.st8{fill:#082238;}
		.st9{fill:#FFFFFF;}
		.st10{opacity:0.5;}
		.st11{fill:#103751;}
		.st12{fill:#91AFC6;}
		.st13{fill:#C3D8E5;}
		.st14{fill:#888889;}
		.st15{fill:#D6D7D8;}
		.st16{fill:#D3D3D3;}
		.st17{fill:#ADADAD;}
		.st18{fill:#FFCD76;}
		.st19{fill:#7E7F7F;}
		.st20{fill:#B2B2B2;}
		.st21{fill:#789CAD;}
	}
</style>