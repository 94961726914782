import { render, staticRenderFns } from "./SlavsSvg.vue?vue&type=template&id=c6b68298&scoped=true&"
import script from "./SlavsSvg.vue?vue&type=script&lang=js&"
export * from "./SlavsSvg.vue?vue&type=script&lang=js&"
import style0 from "./SlavsSvg.vue?vue&type=style&index=0&id=c6b68298&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b68298",
  null
  
)

export default component.exports