<template>
    <svg version="1.1" id="EvacuationSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70 30" style="enable-background:new 0 0 70 30;" xml:space="preserve">
        <path class="st0" d="M64.4,2H5.68c-0.44,1.5-0.79,3.58,0,5.58c0.9,2.29,2.42,2.27,3.15,4.46c0.94,2.87-1.17,4.4,0,6.69 c0.84,1.65,2.15,1.27,3.15,3.35c0.71,1.48,0.25,2.09,1.05,3.35c0.94,1.47,2.45,1.99,3.15,2.23c1.07,0.37,2.41,0.56,5.24,0 c4.56-0.91,4.5-2.09,7.34-2.23c2.49-0.12,4.94,0.68,6.29,1.12c1.26,0.41,2.35,0.89,4.19,1.12c1.19,0.14,2.06,0.25,3.15,0 c2.08-0.48,2.16-1.62,4.19-2.23c1.45-0.43,2.69-0.24,4.19,0c2.52,0.4,2.54,1.08,4.19,1.12c2.82,0.06,4.88-1.88,5.24-2.23 c1.43-1.4,1.98-2.97,2.1-3.35c0.92-2.82-0.5-3.8,0-6.69c0.56-3.26,2.65-3.61,3.15-6.69C65.82,5.3,65.04,3.27,64.4,2z"/>
        <g>
	        <rect x="29.02" y="2.74" class="st1" width="30.53" height="18.52"/>
            <rect x="29.2" y="2.99" class="st2" width="30.2" height="18.02"/>
            <rect x="31.89" y="5.4" class="st3" width="24.78" height="13.21"/>
            <rect x="48.18" y="5.83" class="st1" width="7.87" height="3.3"/>
            <rect x="54.59" y="9.57" class="st1" width="1.47" height="3.3"/>
            <rect x="48.34" y="11.6" class="st1" width="2.76" height="1.76"/>
            <rect x="41.31" y="11.6" class="st1" width="4.71" height="3.3"/>
            <rect x="45.1" y="11.6" class="st1" width="2.76" height="6.27"/>
            <rect x="32.88" y="11.6" class="st1" width="2.76" height="6.27"/>
            <rect x="53.3" y="15.8" class="st1" width="2.76" height="2.07"/>
            <rect x="48.38" y="15.8" class="st1" width="4.41" height="2.07"/>
            <rect x="36.82" y="16.72" class="st4" width="4.41" height="1.15"/>
            <rect x="39.32" y="16.72" class="st1" width="0.37" height="1.15"/>
            <rect x="40.86" y="16.72" class="st1" width="0.37" height="1.15"/>
            <rect x="43.93" y="5.83" class="st1" width="3.54" height="3.3"/>
            <rect x="32.88" y="5.83" class="st1" width="6.12" height="3.3"/>
            <rect x="39.71" y="5.83" class="st1" width="3.54" height="3.3"/>
            <rect x="36.82" y="16.72" class="st1" width="1.29" height="1.15"/>
            <rect x="38.54" y="16.72" class="st1" width="0.37" height="1.15"/>
            <rect x="40.09" y="16.72" class="st1" width="0.37" height="1.15"/>
            <path class="st5" d="M55.33,14.71h-5.47c-0.13,0-0.23-0.08-0.23-0.18s0.1-0.18,0.23-0.18h5.47c0.13,0,0.23,0.08,0.23,0.18 S55.45,14.71,55.33,14.71z"/>
            <path class="st5" d="M52.79,14.62c-0.13,0-0.23-0.08-0.23-0.18v-3.99H33.86c-0.13,0-0.23-0.08-0.23-0.18s0.1-0.18,0.23-0.18h19.16 v4.35C53.02,14.54,52.92,14.62,52.79,14.62z"/>
            <path class="st5" d="M38.93,16.1c-0.13,0-0.23-0.08-0.23-0.18V10.3c0-0.1,0.1-0.18,0.23-0.18c0.13,0,0.23,0.08,0.23,0.18v5.62 C39.16,16.02,39.06,16.1,38.93,16.1z"/>
            <g id="arrows">
		        <path class="st5" d="M38.9,16.16c-0.04,0-0.08-0.01-0.11-0.04l-0.9-0.71c-0.06-0.05-0.06-0.12,0-0.17c0.06-0.05,0.16-0.05,0.22,0l0.8,0.63l0.86-0.6c0.06-0.04,0.16-0.04,0.22,0.01c0.06,0.05,0.05,0.13-0.01,0.17L39,16.13C38.97,16.15,38.93,16.16,38.9,16.16z"/>
                <g>
			        <path class="st5" d="M53.38,14.51c0-0.02,0.01-0.05,0.04-0.07l0.71-0.56c0.05-0.04,0.12-0.04,0.17,0c0.05,0.04,0.05,0.1,0,0.14l-0.63,0.5l0.6,0.54c0.04,0.04,0.04,0.1-0.01,0.14c-0.05,0.03-0.13,0.03-0.17-0.01l-0.67-0.61C53.39,14.55,53.38,14.53,53.38,14.51z"/>
		        </g>
                <g>
			        <path class="st5" d="M52.86,12.01c0.03,0,0.06,0.01,0.08,0.03l0.69,0.54c0.05,0.04,0.05,0.09,0,0.13c-0.05,0.04-0.12,0.04-0.17,0 l-0.61-0.48l-0.66,0.46c-0.05,0.03-0.12,0.03-0.17-0.01c-0.04-0.04-0.04-0.1,0.01-0.13l0.74-0.51 C52.8,12.01,52.83,12.01,52.86,12.01z"/>
		        </g>
                <g>
			        <path class="st5" d="M52.44,14.55c0,0.02-0.01,0.05-0.04,0.07l-0.71,0.56c-0.05,0.04-0.12,0.04-0.17,0 c-0.05-0.04-0.05-0.1,0-0.14l0.63-0.5l-0.6-0.54c-0.04-0.04-0.04-0.1,0.01-0.14c0.05-0.03,0.13-0.03,0.17,0.01l0.67,0.61 C52.43,14.51,52.44,14.53,52.44,14.55z"/>
		        </g>
                <g>
			        <path class="st5" d="M45.84,10.21c0-0.02,0.01-0.05,0.03-0.06l0.67-0.53c0.04-0.04,0.12-0.04,0.16,0c0.04,0.04,0.04,0.09,0,0.13 l-0.59,0.47l0.56,0.5c0.04,0.04,0.04,0.09-0.01,0.13c-0.05,0.03-0.12,0.03-0.16-0.01l-0.63-0.57 C45.85,10.25,45.84,10.23,45.84,10.21z"/>
		        </g>
                <g>
			        <path class="st5" d="M36.68,10.25c0,0.02-0.01,0.05-0.03,0.06l-0.67,0.53c-0.04,0.04-0.12,0.04-0.16,0 c-0.04-0.04-0.04-0.09,0-0.13l0.59-0.47l-0.56-0.5c-0.04-0.04-0.04-0.09,0.01-0.13c0.05-0.03,0.12-0.03,0.16,0.01l0.63,0.57 C36.68,10.21,36.68,10.23,36.68,10.25z"/>
                </g>
            </g>
            <g>
		        <path class="st5" d="M39.49,3.5v0.15h-0.35v0.41h0.25V4.2h-0.25v0.45h0.35V4.8h-0.54V3.5H39.49z"/>
                <path class="st5" d="M39.78,3.5l0.19,0.96l0.19-0.96h0.19l-0.28,1.3h-0.19l-0.28-1.3H39.78z"/>
                <path class="st5" d="M40.78,3.5l0.28,1.3h-0.19l-0.04-0.19h-0.31L40.5,4.8h-0.19l0.28-1.3H40.78z M40.56,4.48h0.26l-0.13-0.65 L40.56,4.48z"/>
                <path class="st5" d="M41.33,4.56c0,0.04,0.01,0.07,0.04,0.08c0.03,0.02,0.06,0.03,0.1,0.03c0.04,0,0.08-0.01,0.1-0.03c0.03-0.02,0.04-0.05,0.04-0.08V4.53l0.18-0.04v0.07c0,0.04-0.01,0.08-0.03,0.11c-0.02,0.03-0.04,0.06-0.07,0.08c-0.03,0.02-0.06,0.04-0.1,0.05c-0.04,0.01-0.08,0.02-0.13,0.02c-0.04,0-0.09-0.01-0.13-0.02c-0.04-0.01-0.07-0.03-0.1-0.05c-0.03-0.02-0.05-0.05-0.07-0.08c-0.02-0.03-0.03-0.07-0.03-0.11V3.73c0-0.04,0.01-0.08,0.03-0.11c0.02-0.03,0.04-0.06,0.07-0.08c0.03-0.02,0.06-0.04,0.1-0.05c0.04-0.01,0.09-0.02,0.13-0.02c0.1,0,0.18,0.02,0.23,0.07c0.06,0.05,0.09,0.11,0.09,0.19v0.04h-0.18V3.74c0-0.04-0.01-0.07-0.04-0.08c-0.02-0.02-0.06-0.03-0.1-0.03c-0.04,0-0.08,0.01-0.11,0.03c-0.03,0.02-0.04,0.05-0.04,0.08V4.56z"/>
                <path class="st5" d="M42.14,3.5v1.06c0,0.04,0.01,0.07,0.04,0.09c0.03,0.02,0.06,0.03,0.1,0.03c0.04,0,0.08-0.01,0.11-0.03c0.03-0.02,0.04-0.05,0.04-0.09V3.5h0.19v1.06c0,0.04-0.01,0.08-0.03,0.11c-0.02,0.03-0.04,0.06-0.07,0.08c-0.03,0.02-0.07,0.04-0.11,0.05c-0.04,0.01-0.09,0.02-0.13,0.02c-0.05,0-0.09-0.01-0.13-0.02c-0.04-0.01-0.08-0.03-0.11-0.05c-0.03-0.02-0.05-0.05-0.07-0.08c-0.02-0.03-0.03-0.07-0.03-0.11V3.5H42.14z"/>
                <path class="st5" d="M43.21,3.5l0.28,1.3h-0.19l-0.04-0.19h-0.31L42.93,4.8h-0.19l0.28-1.3H43.21z M42.99,4.48h0.26l-0.13-0.65L42.99,4.48z"/>
                <path class="st5" d="M44.06,3.5v0.15h-0.18V4.8h-0.19V3.65H43.5V3.5H44.06z"/>
                <path class="st5" d="M44.39,3.5v1.3H44.2V3.5H44.39z"/>
                <path class="st5" d="M45.24,4.56c0,0.04-0.01,0.08-0.03,0.11c-0.02,0.03-0.04,0.06-0.07,0.08c-0.03,0.02-0.07,0.04-0.11,0.05C45,4.81,44.95,4.82,44.91,4.82c-0.05,0-0.09-0.01-0.13-0.02c-0.04-0.01-0.08-0.03-0.11-0.05c-0.03-0.02-0.05-0.05-0.07-0.08c-0.02-0.03-0.03-0.07-0.03-0.11V3.74c0-0.04,0.01-0.08,0.03-0.11c0.02-0.03,0.04-0.06,0.07-0.08c0.03-0.02,0.07-0.04,0.11-0.05c0.04-0.01,0.09-0.02,0.13-0.02c0.05,0,0.09,0.01,0.13,0.02c0.04,0.01,0.08,0.03,0.11,0.05c0.03,0.02,0.05,0.05,0.07,0.08c0.02,0.03,0.03,0.07,0.03,0.11V4.56z M44.76,4.56c0,0.04,0.01,0.07,0.04,0.09c0.03,0.02,0.06,0.03,0.11,0.03c0.04,0,0.08-0.01,0.11-0.03c0.03-0.02,0.04-0.05,0.04-0.09V3.74c0-0.04-0.01-0.07-0.04-0.09c-0.03-0.02-0.06-0.03-0.11-0.03c-0.04,0-0.08,0.01-0.11,0.03c-0.03,0.02-0.04,0.05-0.04,0.09V4.56z"/>
                <path class="st5" d="M45.64,3.5l0.28,0.89V3.5h0.17v1.3h-0.17l-0.32-0.95V4.8h-0.17V3.5H45.64z"/>
                <path class="st5" d="M47,3.5c0.1,0,0.18,0.02,0.24,0.07c0.06,0.05,0.09,0.12,0.09,0.23v0.19c0,0.1-0.03,0.18-0.09,0.23C47.18,4.26,47.1,4.28,47,4.28h-0.15V4.8h-0.19V3.5H47z M46.86,4.14h0.13c0.1,0,0.15-0.05,0.15-0.14V3.79c0-0.09-0.05-0.14-0.15-0.14h-0.13V4.14z"/>
                <path class="st5" d="M47.68,3.5v1.15h0.31V4.8h-0.5V3.5H47.68z"/>
                <path class="st5" d="M48.54,3.5l0.28,1.3h-0.19L48.6,4.61h-0.31L48.26,4.8h-0.19l0.28-1.3H48.54z M48.32,4.48h0.26l-0.13-0.65L48.32,4.48z"/>
                <path class="st5" d="M49.17,3.5l0.28,0.89V3.5h0.17v1.3h-0.17l-0.32-0.95V4.8h-0.17V3.5H49.17z"/>
            </g>
        </g>
        <g>
            <g>
                <path class="st5" d="M18.25,10.22c0,0.26-0.34,0.47-0.75,0.47c-0.41,0-0.75-0.21-0.75-0.47c0-0.26,0.34-0.47,0.75-0.47C17.91,9.74,18.25,9.95,18.25,10.22z"/>
                <rect x="10.17" y="2" class="st6" width="14.66" height="3.63"/>
                <rect x="10" y="2" class="st6" width="15" height="2.37"/>
                <path class="st6" d="M24.24,6.23c0,1.5-3.03,3.78-6.82,3.78s-6.66-2.44-6.66-3.78c0-2.14,3.02-3.87,6.74-3.87S24.24,4.09,24.24,6.23z"/>
                <rect x="10.76" y="5.63" class="st7" width="13.48" height="1.58"/>
                <rect x="16.91" y="9.9" class="st8" width="1.18" height="0.32"/>
                <rect x="10" y="4.19" class="st9" width="15" height="0.15"/>
                <rect x="11.21" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="11.85" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="12.5" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="13.15" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="13.79" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="14.44" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="15.08" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="15.73" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="16.37" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="17.02" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="17.66" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="18.31" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="18.95" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="19.6" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="20.24" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="20.89" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="21.53" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="22.18" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="22.82" y="5.62" class="st10" width="0.16" height="1.59"/>
                <rect x="23.47" y="5.62" class="st10" width="0.16" height="1.59"/>
                <path class="st11" d="M12.26,5.32c0.48,1.65,2.16,3.22,4.68,4.73c-1.92-1.75-3.25-3.41-3.16-4.89L12.26,5.32z"/>
                <path class="st11" d="M22.74,5.32c-0.48,1.65-2.16,3.22-4.68,4.73c1.92-1.75,3.25-3.41,3.16-4.89L22.74,5.32z"/>
                <polygon class="st11" points="16.97,5.13 18.32,5.2 17.52,10.06 		"/>
                <rect x="10.16" y="5.1" class="st12" width="14.68" height="0.45"/>
            </g>
            <g id="smoke1">
		        <path class="st13" d="M17.94,12.1c-0.4,1.45,0.24,2.4,0.59,3.8c0.44,1.76-0.75,3.05-0.86,4.73c-0.02,0.26,0.27,0.35,0.46,0.26c1.19-0.57,1.49-2.34,1.5-3.57c0.01-1.86-1.2-3.37-1.37-5.18C18.23,11.96,17.99,11.92,17.94,12.1L17.94,12.1z"/>
                <path class="st13" d="M20.43,13.55c-0.31,1.16-0.24,2.08,0.22,3.19c0.38,0.91,0.73,1.72,0.43,2.73c-0.21,0.75-0.74,1.3-1.14,1.94c-0.22,0.36,0.22,0.66,0.52,0.52c1.09-0.51,1.53-1.79,1.57-2.96c0.07-1.92-1.51-3.33-1.08-5.28C21.04,13.34,20.53,13.2,20.43,13.55L20.43,13.55z"/>
                <path class="st13" d="M16.6,16.2c-0.26,1.16-1.41,2.13-1.53,3.32c-0.1,0.98,0.52,1.84,1.57,2.4c0.15,0.08,0.28-0.08,0.22-0.17c-0.29-0.47-0.8-0.89-1.03-1.38c-0.31-0.66,0.01-1.32,0.35-1.94c0.4-0.75,0.72-1.38,0.75-2.19C16.95,16.09,16.64,16.06,16.6,16.2L16.6,16.2z"/>
	        </g>
            <g id="smoke2">
		        <path class="st13" d="M19.52,11.7c0.4,1.45-0.24,2.4-0.59,3.8c-0.44,1.76,0.75,3.05,0.86,4.73c0.02,0.26-0.27,0.35-0.46,0.26c-1.19-0.57-1.49-2.34-1.5-3.57c-0.01-1.86,1.2-3.37,1.37-5.18C19.22,11.55,19.47,11.51,19.52,11.7L19.52,11.7z"/>
                <path class="st13" d="M17.02,13.15c0.31,1.16,0.24,2.08-0.22,3.19c-0.38,0.91-0.73,1.72-0.43,2.73c0.21,0.75,0.74,1.3,1.14,1.94c0.22,0.36-0.22,0.66-0.52,0.52c-1.09-0.51-1.53-1.79-1.57-2.96c-0.07-1.92,1.51-3.33,1.08-5.28C16.41,12.93,16.93,12.79,17.02,13.15L17.02,13.15z"/>
                <path class="st13" d="M20.85,15.79c0.26,1.16,1.41,2.13,1.53,3.32c0.1,0.98-0.52,1.84-1.57,2.4c-0.15,0.08-0.28-0.08-0.22-0.17c0.29-0.47,0.8-0.89,1.03-1.38c0.31-0.66-0.01-1.32-0.35-1.94c-0.4-0.75-0.72-1.38-0.75-2.19C20.51,15.68,20.82,15.65,20.85,15.79L20.85,15.79z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "EvacuationSvg"
    }
</script>

<style scoped lang="scss">
    #EvacuationSvg{
        .st0{fill:#D1EBFF;}
        .st1{fill:#ADADAD;}
        .st2{fill:#E9ECEF;}
        .st3{fill:#FFFFFF;}
        .st4{fill:#D3D2D1;}
        .st5{fill:#FFAC1A;}
        .st6{fill:#999999;}
        .st7{fill:#898888;}
        .st8{fill:#939393;}
        .st9{opacity:0.5;fill:#BFBFBB;}
        .st10{opacity:0.5;fill:#5B5B5B;}
        .st11{fill:#BAB8B6;}
        .st12{fill:#9B9B9A;}
        .st13{fill:#A8A5A5;}
    }
</style>