<template>
    <svg version="1.1" id="LocationSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
        <polygon class="st0" points="19.61,3.82 10.23,1.73 0.84,3.82 0.84,29 10.23,26.91 19.61,29 29,26.91 29,1.73 "/>
        <polygon class="st1" points="19.61,3.82 10.23,1.73 0.84,3.82 0.84,29 10.23,26.91 19.61,29 29,26.91 29,1.73 "/>
        <polygon class="st0" points="28.04,2.94 28.04,26.12 19.62,28.01 13.03,26.53 10.51,25.98 10.23,25.91 1.79,27.79 1.79,4.61 4.36,4.03 6.57,3.55 10.23,2.73 19.62,4.81 22.71,4.13 25.99,3.4 "/>
        <polygon class="st2" points="28.04,14.54 27.3,14.75 28.04,17.4 28.04,21.89 26.14,15.09 11.06,19.52 13.03,26.53 10.51,25.98 8.89,20.16 1.79,22.24 1.79,19.84 8.26,17.94 6.51,11.68 1.79,13.07 1.79,11.67 6.14,10.38 4.36,4.03 6.57,3.55 10.43,17.3 18.4,14.96 16.98,9.91 16.99,9.91 16.98,9.89 22.71,4.13 25.99,3.4 19.14,10.28 20.3,14.4 28.04,12.12 "/>
        <polygon class="st3" points="10.23,1.73 10.23,26.91 19.61,29 19.61,3.82 "/>
        <path class="st4" d="M22.67,19.09c-1.16-1.19-3.04-1.19-4.19,0c-1.16,1.19-1.16,3.11,0,4.3l2.1,2.15l2.1-2.15C23.83,22.2,23.83,20.27,22.67,19.09z M21.39,22.07L21.39,22.07c-0.45,0.46-1.18,0.46-1.63,0l0,0c-0.45-0.46-0.45-1.21,0-1.67c0.45-0.46,1.18-0.46,1.63,0C21.84,20.86,21.84,21.61,21.39,22.07z"/>
    </svg>
</template>

<script>
    export default {
        name: "LocationSvg"
    }
</script>

<style scoped lang="scss">
    #LocationSvg{
        .st0{fill:#66B0FF;}
        .st1{opacity:0.4;fill:#FFFFFF;}
        .st2{opacity:0.3;fill:#FFFFFF;}
        .st3{opacity:0.2;fill:#FFFFFF;}
        .st4{fill:#FFFFFF;stroke:#007CFF;stroke-width:0.6;stroke-miterlimit:10;}
    }
</style>