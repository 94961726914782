<template>
	<svg version="1.1" id="SlavsSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
		<path class="st0" d="M7.15,8.8H4.29c-0.47,0-0.89,0.31-1.03,0.77L1.6,14.85c-0.07,0.21,0.04,0.44,0.25,0.52l0.06,0.02c0.21,0.07,0.43-0.03,0.51-0.24l1.42-3.68c0,0,0.06,2.83,0.07,3.77c0.03,1.9,0.07,7.6,0.07,7.6c0,0.23,0.18,0.41,0.4,0.41l0,0c0.21,0,0.38-0.16,0.4-0.37l0.76-7.63h0.34l0.76,7.63c0.02,0.21,0.2,0.37,0.4,0.37l0,0c0.22,0,0.4-0.18,0.4-0.41c0,0,0.04-5.7,0.07-7.6c0.01-0.94,0.07-3.77,0.07-3.77l1.42,3.68c0.08,0.21,0.31,0.31,0.51,0.24l0.06-0.02c0.21-0.07,0.32-0.3,0.25-0.52L8.18,9.57C8.04,9.11,7.62,8.8,7.15,8.8z"/>
		<path class="st0" d="M6.67,7.38c0,0.7-0.28,1.26-0.95,1.26S4.76,8.08,4.76,7.38c0-0.7,0.28-1.26,0.95-1.26S6.67,6.69,6.67,7.38z"/>
		<path class="st0" d="M25.87,8.8h-2.86c-0.47,0-0.89,0.31-1.03,0.77l-1.65,5.28c-0.07,0.21,0.04,0.44,0.25,0.52l0.06,0.02c0.21,0.07,0.43-0.03,0.51-0.24l1.42-3.68c0,0,0.06,2.83,0.07,3.77c0.03,1.9,0.07,7.6,0.07,7.6c0,0.23,0.18,0.41,0.4,0.41l0,0c0.21,0,0.38-0.16,0.4-0.37l0.76-7.63h0.34l0.76,7.63c0.02,0.21,0.2,0.37,0.4,0.37l0,0c0.22,0,0.4-0.18,0.4-0.41c0,0,0.04-5.7,0.07-7.6c0.01-0.94,0.07-3.77,0.07-3.77l1.42,3.68c0.08,0.21,0.31,0.31,0.51,0.24l0.06-0.02c0.21-0.07,0.32-0.3,0.25-0.52L26.9,9.57C26.76,9.11,26.34,8.8,25.87,8.8z"/>
		<path class="st0" d="M25.39,7.38c0,0.7-0.28,1.26-0.95,1.26c-0.68,0-0.95-0.56-0.95-1.26c0-0.7,0.28-1.26,0.95-1.26C25.12,6.13,25.39,6.69,25.39,7.38z"/>
		<path class="st1" d="M17.15,5.98h-4.22c-0.7,0-1.32,0.46-1.53,1.14l-2.44,7.81c-0.1,0.32,0.07,0.66,0.37,0.77l0.09,0.03c0.3,0.11,0.64-0.05,0.76-0.35l2.1-5.45c0,0,0.09,4.18,0.11,5.58c0.04,2.81,0.1,11.24,0.1,11.24c0,0.34,0.27,0.61,0.6,0.61l0,0c0.31,0,0.57-0.24,0.6-0.55l1.12-11.3h0.5l1.12,11.3c0.03,0.31,0.29,0.55,0.6,0.55l0,0c0.33,0,0.6-0.27,0.6-0.61c0,0,0.06-8.43,0.1-11.24c0.02-1.39,0.11-5.58,0.11-5.58l2.1,5.45c0.12,0.31,0.45,0.46,0.76,0.35l0.09-0.03c0.31-0.11,0.47-0.45,0.37-0.77l-2.44-7.81C18.47,6.44,17.85,5.98,17.15,5.98z"/>
		<path class="st1" d="M16.45,3.88c0,1.03-0.41,1.86-1.41,1.86s-1.41-0.83-1.41-1.86s0.41-1.86,1.41-1.86S16.45,2.85,16.45,3.88z"/>
	</svg>
</template>

<script>
    export default {
        name: "SlavsSvg"
    }
</script>

<style scoped lang="scss">
	#SlavsSvg{
		.st0{fill:#8EC5FE;}
		.st1{fill:#007CFF;}
	}
</style>