<template>
    <svg version="1.1" id="AboutSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 250" style="enable-background:new 0 0 300 250;" xml:space="preserve">
        <path id="Background" class="st0" d="M156,89.18c-67.93,0-123,50.19-123,112.1c0,12.86,2.38,25.22,6.76,36.72h232.48c4.38-11.51,6.76-23.86,6.76-36.72C279,139.37,223.93,89.18,156,89.18z"/>
        <ellipse class="st1" cx="154.17" cy="233.67" rx="126.83" ry="11.33"/>
        <ellipse class="st2" cx="154.5" cy="231.14" rx="120.5" ry="8.86"/>
        <g>
            <g>
                <path class="st3" d="M192.61,66.59l8.2,0.91l0.18,0.22l0.17-0.18l14.92,1.66l0.14,0.17l0.13-0.14l30.02,3.33v55.65c2.95,3.09,5.72,6.33,8.31,9.7V62.65v-1.1V61h-63.73v4.94v0.47v28.54c0.56,0.16,1.11,0.32,1.66,0.49V66.59z M246.36,70.9l-4.59-0.51l4.59-6.2V70.9z M245.44,62.65l-4.85,6.55l-5.59-6.55H245.44z M239.18,70.1l-10.32-1.15l5-5.09L239.18,70.1zM232.74,62.65l-5.28,5.37l-4.92-5.37H232.74z M225.77,68.61l-8.02-0.89l3.66-3.86L225.77,68.61z M220.28,62.65l-3.98,4.2l-3.37-4.2H220.28z M214.61,67.37l-5.28-0.59l2.52-2.86L214.61,67.37z M210.77,62.65l-2.71,3.06l-2.47-3.06H210.77z M206.56,66.48l-4.03-0.45l1.96-2.12L206.56,66.48z M203.41,62.65l-2.34,2.54l-2.04-2.54H203.41z M199.34,65.68l-2.79-0.31l1.36-1.46L199.34,65.68z M196.83,62.65l-1.49,1.59l-1.19-1.59H196.83z M192.61,63.33l1.3,1.74l-1.3-0.14V63.33z"/>
                <path class="st3" d="M246.36,226.62h8.31V137.9c-2.59-3.38-5.36-6.62-8.31-9.7V226.62z"/>
                <path class="st3" d="M182.63,102.13v4.39h18.84v-4.39h-8.87v-6.71c-0.55-0.17-1.11-0.33-1.66-0.49v7.19H182.63z"/>
                <path class="st3" d="M223.09,104.87h-1.66v3.34c0.56,0.34,1.11,0.69,1.66,1.03V104.87z"/>
                <path class="st3" d="M234.17,113.1v3.99c0.75,0.59,1.49,1.19,2.22,1.8V99.94h-2.22v10.42h-9.35c1.37,0.89,2.72,1.8,4.05,2.74H234.17z"/>
                <path class="st3" d="M209.79,110.36v-7.13h-2.22v111.33h28.82v-95.67c-0.73-0.61-1.47-1.21-2.22-1.8v2.04h-11.08v-6.03h5.78c-1.33-0.94-2.68-1.85-4.05-2.74h-1.74v-1.11c-0.55-0.35-1.1-0.69-1.66-1.03v2.14H209.79z M221.42,119.13h-11.64v-6.03h11.64V119.13z"/>
                <path class="st3" d="M261.63,202.61v-0.3h-0.5v-13.8h-2.76v-3.6h-6.4v-12.3h-3.51v-8.7h-4.31v-12.67h-1.54v12.67h-4.31v8.7h-3.51c-7.08,0.3-14.17,0.6-21.25,0.9v-17.09c-1.09-0.1-2.17-0.2-3.26-0.3c-0.49-4.86-0.97-9.72-1.46-14.58h-23.88v-7.65h-4.07v-27.14h-1.6v27.14h-5.67v7.65h-2.62v35.99c-3.86,0.22-7.86,0.38-11.98,0.47c-4.48,0.09-8.82,0.08-13,0c0,9,0,18,0,27s0,18,0,27c19.54,0.07,39.2,0.08,59,0c19.46-0.07,38.79-0.22,58-0.44C262.54,221.91,262.09,212.26,261.63,202.61z"/>
                <g>
                    <rect x="193.86" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="193.86" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="193.86" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="193.86" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="191.24" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="191.24" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="191.24" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="188.62" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="188.62" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="188.62" y="148.93" class="st3" width="1.65" height="1.7"/>
                    <rect x="188.62" y="151.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="188.62" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="188.62" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="188.62" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="186" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="186" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="186" y="148.93" class="st3" width="1.65" height="1.7"/>
                    <rect x="186" y="151.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="186" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="186" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="186" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="183.38" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="183.38" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="183.38" y="148.93" class="st3" width="1.65" height="1.7"/>
                    <rect x="183.38" y="151.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="183.38" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="183.38" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="183.38" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="180.76" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="180.76" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="180.76" y="148.93" class="st3" width="1.65" height="1.7"/>
                    <rect x="180.76" y="151.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="180.76" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="180.76" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="180.76" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="178.14" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="178.14" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="178.14" y="148.93" class="st3" width="1.65" height="1.7"/>
                    <rect x="178.14" y="151.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="178.14" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="178.14" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="178.14" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="175.52" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="175.52" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="175.52" y="148.93" class="st3" width="1.65" height="1.7"/>
                    <rect x="175.52" y="151.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="175.52" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="175.52" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="175.52" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="172.9" y="143.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="172.9" y="146.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="172.9" y="148.93" class="st3" width="1.65" height="1.7"/>
                    <rect x="172.9" y="151.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="172.9" y="154.62" class="st3" width="1.65" height="1.7"/>
                    <rect x="172.9" y="157.47" class="st3" width="1.65" height="1.7"/>
                    <rect x="172.9" y="160.32" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="166.02" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="168.87" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="171.72" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="174.57" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="177.42" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="180.27" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="183.11" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="185.96" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="188.81" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="191.66" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="194.51" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="197.36" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="200.21" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="203.06" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="205.91" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="208.76" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="211.61" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="214.45" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="217.3" class="st3" width="1.65" height="1.7"/>
                    <rect x="245.36" y="220.15" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="166.02" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="168.87" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="171.72" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="174.57" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="177.42" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="180.27" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="183.11" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="185.96" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="188.81" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="191.66" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="194.51" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="197.36" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="200.21" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="203.06" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="205.91" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="208.76" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="211.61" class="st3" width="1.65" height="1.7"/>
                    <rect x="242.74" y="214.45" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="166.02" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="168.87" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="171.72" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="174.57" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="177.42" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="180.27" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="183.11" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="185.96" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="188.81" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="191.66" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="194.51" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="197.36" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="200.21" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="203.06" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="205.91" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="208.76" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="211.61" class="st3" width="1.65" height="1.7"/>
                    <rect x="240.13" y="214.45" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="186.81" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="189.66" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="192.51" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="195.36" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="198.21" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="201.06" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="203.91" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="206.76" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="209.61" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="212.46" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="215.3" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="218.15" class="st3" width="1.65" height="1.7"/>
                    <rect x="255.16" y="221" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="186.81" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="189.66" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="192.51" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="195.36" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="198.21" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="201.06" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="203.91" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="206.76" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="209.61" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="212.46" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="215.3" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="218.15" class="st3" width="1.65" height="1.7"/>
                    <rect x="252.54" y="221" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="186.81" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="189.66" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="192.51" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="195.36" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="198.21" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="201.06" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="203.91" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="206.76" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="209.61" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="212.46" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="215.3" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="218.15" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="221" class="st3" width="1.65" height="1.7"/>
                    <rect x="249.92" y="223.85" class="st3" width="1.65" height="1.7"/>
                    <rect x="200.55" y="144.38" class="st3" width="1.65" height="1.7"/>
                    <rect x="200.55" y="147.23" class="st3" width="1.65" height="1.7"/>
                    <rect x="200.55" y="150.08" class="st3" width="1.65" height="1.7"/>
                    <rect x="200.55" y="152.92" class="st3" width="1.65" height="1.7"/>
                    <rect x="200.55" y="155.77" class="st3" width="1.65" height="1.7"/>
                    <rect x="200.55" y="158.62" class="st3" width="1.65" height="1.7"/>
                </g>
            </g>
            <path class="st4" d="M148.43,103.53c-0.02,0-0.04,0-0.06,0c-0.03-1.92-1.6-3.46-3.55-3.46c-1.28,0-2.4,0.68-3.03,1.69c-0.24-0.04-0.49-0.07-0.75-0.07c-2.24,0-4.06,1.8-4.06,4.01c0,0.2,0.02,0.39,0.04,0.58c-0.17-0.05-0.36-0.08-0.55-0.08c-1.06,0-1.92,0.85-1.92,1.9c0,1.03,0.83,1.86,1.86,1.89l0,0h12c1.8,0,3.27-1.45,3.27-3.23S150.23,103.53,148.43,103.53z"/>
            <path class="st3" d="M88.95,136.91c-0.25,0-0.48,0.04-0.71,0.11c0.04-0.26,0.06-0.52,0.06-0.78c0-2.98-2.34-5.39-5.22-5.39c-0.33,0-0.65,0.03-0.96,0.09c-0.8-1.36-2.24-2.26-3.89-2.26c-2.5,0-4.53,2.07-4.56,4.64c-0.03,0-0.05,0-0.08,0c-2.32,0-4.2,1.94-4.2,4.34c0,2.4,1.88,4.34,4.2,4.34h15.44l0,0c1.33-0.04,2.4-1.16,2.4-2.54C91.41,138.05,90.31,136.91,88.95,136.91z"/>
            <path class="st3" d="M70.15,165.56c-0.33,0-0.65,0.05-0.95,0.14c0.05-0.32,0.07-0.65,0.07-0.99c0-3.76-3.12-6.82-6.97-6.82c-0.44,0-0.87,0.04-1.28,0.12c-1.07-1.71-3-2.86-5.2-2.86c-3.34,0-6.05,2.62-6.1,5.87c-0.03,0-0.07-0.01-0.1-0.01c-3.1,0-5.62,2.46-5.62,5.49c0,3.03,2.52,5.49,5.62,5.49h20.63l0,0c1.78-0.05,3.2-1.47,3.2-3.21C73.45,167,71.97,165.56,70.15,165.56z"/>
        </g>
        <g>
            <g>
                <g>
                    <rect x="63.49" y="189.23" class="st5" width="40.95" height="44.02"/>
                    <rect x="63.49" y="189.23" class="st6" width="34.04" height="44.02"/>
                    <rect x="97.52" y="189.23" class="st7" width="6.92" height="44.02"/>
                </g>
                <g>
                    <rect x="66.41" y="191.47" class="st5" width="7.43" height="5.2"/>
                    <rect x="76.79" y="191.47" class="st5" width="7.43" height="5.2"/>
                    <rect x="87.17" y="191.47" class="st5" width="7.43" height="5.2"/>
                </g>
                <g>
                    <rect x="66.41" y="198.24" class="st5" width="7.43" height="5.2"/>
                    <rect x="76.79" y="198.24" class="st5" width="7.43" height="5.2"/>
                    <rect x="87.17" y="198.24" class="st5" width="7.43" height="5.2"/>
                </g>
                <g>
                    <rect x="66.41" y="205" class="st5" width="7.43" height="5.2"/>
                    <rect x="76.79" y="205" class="st5" width="7.43" height="5.2"/>
                    <rect x="87.17" y="205" class="st5" width="7.43" height="5.2"/>
                </g>
                <g>
                    <rect x="66.41" y="211.77" class="st5" width="7.43" height="5.2"/>
                    <rect x="76.79" y="211.77" class="st5" width="7.43" height="5.2"/>
                    <rect x="87.17" y="211.77" class="st5" width="7.43" height="5.2"/>
                </g>
                <g>
                    <rect x="66.41" y="218.54" class="st5" width="7.43" height="5.2"/>
                    <rect x="76.79" y="218.54" class="st5" width="7.43" height="5.2"/>
                    <rect x="87.17" y="218.54" class="st5" width="7.43" height="5.2"/>
                </g>
                <g>
                    <rect x="66.41" y="227.11" class="st5" width="7.43" height="6.14"/>
                    <rect x="76.79" y="225.31" class="st5" width="7.43" height="7.94"/>
                    <rect x="87.17" y="227.11" class="st5" width="7.43" height="6.14"/>
                </g>
                <rect x="67.03" y="191.47" class="st8" width="6.81" height="5.2"/>
                <rect x="77.41" y="191.47" class="st8" width="6.81" height="5.2"/>
                <rect x="87.79" y="191.47" class="st8" width="6.81" height="5.2"/>
                <rect x="67.03" y="198.24" class="st8" width="6.81" height="5.2"/>
                <rect x="77.41" y="198.24" class="st8" width="6.81" height="5.2"/>
                <rect x="87.79" y="198.24" class="st8" width="6.81" height="5.2"/>
                <rect x="67.03" y="205" class="st8" width="6.81" height="5.2"/>
                <rect x="77.41" y="205" class="st8" width="6.81" height="5.2"/>
                <rect x="87.79" y="205" class="st8" width="6.81" height="5.2"/>
                <rect x="67.03" y="211.77" class="st8" width="6.81" height="5.2"/>
                <rect x="77.41" y="211.77" class="st8" width="6.81" height="5.2"/>
                <rect x="87.79" y="211.77" class="st8" width="6.81" height="5.2"/>
                <rect x="67.03" y="218.54" class="st8" width="6.81" height="5.2"/>
                <rect x="77.41" y="218.54" class="st8" width="6.81" height="5.2"/>
                <rect x="87.79" y="218.54" class="st8" width="6.81" height="5.2"/>
                <rect x="67.03" y="227.11" class="st8" width="6.81" height="6.14"/>
                <rect x="77.41" y="225.31" class="st8" width="6.81" height="7.94"/>
                <rect x="87.79" y="227.11" class="st8" width="6.81" height="6.14"/>
                <polygon class="st5" points="62.5,189.56 109.96,189.56 111.46,187.32 61,187.32"/>
                <polygon class="st9" points="97.55,189.56 109.96,189.56 111.46,187.32 98.27,187.32"/>
            </g>
            <g>
		        <rect x="161.43" y="162.9" class="st5" width="45.86" height="70.36"/>
                <rect x="161.43" y="162.9" class="st8" width="34.89" height="70.36"/>
                <rect x="159.28" y="160.81" class="st5" width="50.16" height="2.25"/>
                <rect x="196.33" y="160.81" class="st9" width="13.12" height="2.25"/>
                <rect x="196.33" y="163.06" class="st7" width="10.97" height="70.19"/>
                <g>
                    <g>
                        <rect x="166.11" y="165.7" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="166.08" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="165.7" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="165.7" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="166.08" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="165.7" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="165.7" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="166.08" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="165.7" class="st5" width="0.85" height="5.66"/>
                    </g>
                    <g>
                        <rect x="166.11" y="174.23" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="174.61" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="174.23" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="174.23" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="174.61" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="174.23" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="174.23" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="174.61" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="174.23" class="st5" width="0.85" height="5.66"/>
                    </g>
                    <g>
                        <rect x="166.11" y="182.76" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="183.13" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="182.76" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="182.76" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="183.13" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="182.76" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="182.76" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="183.13" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="182.76" class="st5" width="0.85" height="5.66"/>
                    </g>
                    <g>
                        <rect x="166.11" y="191.28" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="191.66" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="191.28" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="191.28" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="191.66" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="191.28" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="191.28" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="191.66" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="191.28" class="st5" width="0.85" height="5.66"/>
                    </g>
                    <g>
                        <rect x="166.11" y="199.81" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="200.18" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="199.81" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="199.81" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="200.18" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="199.81" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="199.81" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="200.18" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="199.81" class="st5" width="0.85" height="5.66"/>
                    </g>
                    <g>
                        <rect x="166.11" y="208.34" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="208.71" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="208.34" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="208.34" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="208.71" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="208.34" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="208.34" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="208.71" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="208.34" class="st5" width="0.85" height="5.66"/>
                    </g>
                    <g>
                        <rect x="166.11" y="216.86" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="217.24" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="216.86" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="216.86" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="217.24" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="216.86" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="216.86" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="217.24" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="216.86" class="st5" width="0.85" height="5.66"/>
                    </g>
                    <g>
                        <rect x="166.11" y="225.39" class="st5" width="5.93" height="5.66"/>
                        <rect x="166.11" y="225.76" class="st10" width="5.93" height="5.29"/>
                        <rect x="166.11" y="225.39" class="st5" width="0.85" height="5.66"/>
                        <rect x="175.45" y="225.39" class="st5" width="5.93" height="5.66"/>
                        <rect x="175.45" y="225.76" class="st10" width="5.93" height="5.29"/>
                        <rect x="175.45" y="225.39" class="st5" width="0.85" height="5.66"/>
                        <rect x="184.79" y="225.39" class="st5" width="5.93" height="5.66"/>
                        <rect x="184.79" y="225.76" class="st10" width="5.93" height="5.29"/>
                        <rect x="184.79" y="225.39" class="st5" width="0.85" height="5.66"/>
                    </g>
                </g>
            </g>
            <g>
		        <rect x="120.84" y="123" class="st11" width="0.8" height="13.85"/>
                <rect x="138.32" y="123" class="st11" width="0.8" height="13.85"/>
                <g>
			        <rect x="127.08" y="129.31" class="st5" width="27.18" height="11.48"/>
                    <rect x="127.08" y="129.31" class="st12" width="27.18" height="11.48"/>
                    <rect x="111.36" y="129.31" class="st5" width="27.18" height="11.48"/>
                    <rect x="111.36" y="129.31" class="st8" width="27.18" height="11.48"/>
		        </g>
                <rect x="107.42" y="146.95" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="158.56" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="166.43" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="174.29" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="182.15" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="190.02" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="198.69" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="207.35" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="216.02" class="st13" width="1.36" height="4.5"/>
                <rect x="107.42" y="224.68" class="st13" width="1.36" height="4.5"/>
                <rect x="119.43" y="140.96" class="st5" width="42.46" height="92.29"/>
                <rect x="103.71" y="140.96" class="st5" width="42.46" height="92.29"/>
                <rect x="146.18" y="142.23" class="st7" width="15.72" height="91.02"/>
                <rect x="103.71" y="140.96" class="st13" width="42.46" height="92.29"/>
                <g>
                    <g>
                        <rect x="136.94" y="145" class="st5" width="5.21" height="86.05"/>
                        <rect x="126.9" y="145" class="st5" width="5.21" height="86.05"/>
                        <rect x="116.87" y="145" class="st5" width="5.21" height="86.05"/>
                        <rect x="106.83" y="145" class="st5" width="5.21" height="86.05"/>
                    </g>
                    <g>
				        <rect x="136.94" y="146.09" class="st14" width="5.21" height="84.96"/>
                        <rect x="126.9" y="146.09" class="st14" width="5.21" height="84.96"/>
                        <rect x="116.87" y="146.09" class="st14" width="5.21" height="84.96"/>
                        <rect x="106.83" y="146.09" class="st14" width="5.21" height="84.96"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="157.9" y="145" class="st5" width="2.18" height="86.05"/>
                        <rect x="153.69" y="145" class="st5" width="2.18" height="86.05"/>
                        <rect x="149.48" y="145" class="st5" width="2.18" height="86.05"/>
                    </g>
                    <g class="st15">
				        <rect x="157.9" y="146.09" class="st8" width="2.18" height="84.96"/>
                        <rect x="153.69" y="146.09" class="st8" width="2.18" height="84.96"/>
                        <rect x="149.48" y="146.09" class="st8" width="2.18" height="84.96"/>
                    </g>
                </g>
                <rect x="146.18" y="138.34" class="st16" width="14.81" height="1.73"/>
                <rect x="103.71" y="137.92" class="st17" width="57.28" height="2.15"/>
                <rect x="102.51" y="140.07" class="st16" width="60.49" height="2.15"/>
                <rect x="102.51" y="136.19" class="st16" width="60.49" height="2.15"/>
                <rect x="102.51" y="140.07" class="st18" width="60.49" height="2.15"/>
                <rect x="102.51" y="136.19" class="st18" width="60.49" height="2.15"/>
                <rect x="146.18" y="140.07" class="st9" width="16.82" height="2.15"/>
                <rect x="146.18" y="136.19" class="st9" width="16.82" height="2.15"/>
	        </g>
            <g>
		        <rect x="205.52" y="131.67" class="st16" width="29.33" height="6.44"/>
                <rect x="205.52" y="131.67" class="st19" width="29.33" height="6.44"/>
                <rect x="210.48" y="127.41" class="st16" width="19.41" height="4.26"/>
                <rect x="210.48" y="127.41" class="st20" width="19.41" height="4.26"/>
                <rect x="214.04" y="124.71" class="st16" width="12.29" height="2.7"/>
                <rect x="214.04" y="124.71" class="st21" width="12.29" height="2.7"/>
                <rect x="217" y="123.36" class="st16" width="6.37" height="1.4"/>
                <rect x="217" y="123.36" class="st22" width="6.37" height="1.4"/>
                <rect x="202.34" y="138.11" class="st16" width="34.78" height="95.89"/>
                <rect x="202.34" y="138.11" class="st23" width="34.78" height="95.89"/>
                <rect x="202.34" y="138.11" class="st24" width="11.01" height="95.89"/>
                <g>
			        <rect x="215.63" y="141.3" class="st25" width="4.66" height="92.04"/>
                    <rect x="205.52" y="141.3" class="st25" width="4.66" height="92.04"/>
                    <rect x="222.91" y="141.3" class="st25" width="4.66" height="92.04"/>
                    <rect x="230.19" y="141.3" class="st25" width="4.66" height="92.04"/>
		        </g>
                <g>
			        <rect x="205.52" y="131.67" class="st26" width="7.84" height="6.44"/>
                    <rect x="210.48" y="127.41" class="st26" width="5.19" height="4.26"/>
                    <rect x="214.04" y="124.71" class="st26" width="3.28" height="2.7"/>
                    <rect x="217" y="123.36" class="st26" width="1.7" height="1.4"/>
		        </g>
                <g>
                    <g>
                        <rect x="213.36" y="150.18" class="st5" width="25.64" height="1.32"/>
                        <rect x="213.36" y="150.18" class="st21" width="25.64" height="1.32"/>
                        <rect x="213.36" y="150.18" class="st26" width="3.55" height="1.32"/>
                    </g>
                    <g>
				        <rect x="200" y="150.18" class="st5" width="10.18" height="1.32"/>
                        <rect x="200" y="150.18" class="st21" width="10.18" height="1.32"/>
                        <rect x="208.77" y="150.18" class="st26" width="1.41" height="1.32"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="213.36" y="161.93" class="st5" width="25.64" height="1.32"/>
                        <rect x="213.36" y="161.93" class="st21" width="25.64" height="1.32"/>
                        <rect x="213.36" y="161.93" class="st26" width="3.55" height="1.32"/>
                    </g>
                    <g>
				        <rect x="200" y="161.93" class="st5" width="10.18" height="1.32"/>
                        <rect x="200" y="161.93" class="st21" width="10.18" height="1.32"/>
                        <rect x="208.77" y="161.93" class="st26" width="1.41" height="1.32"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="213.36" y="173.68" class="st5" width="25.64" height="1.32"/>
                        <rect x="213.36" y="173.68" class="st21" width="25.64" height="1.32"/>
                        <rect x="213.36" y="173.68" class="st26" width="3.55" height="1.32"/>
                    </g>
                    <g>
				        <rect x="200" y="173.68" class="st5" width="10.18" height="1.32"/>
                        <rect x="200" y="173.68" class="st21" width="10.18" height="1.32"/>
                        <rect x="208.77" y="173.68" class="st26" width="1.41" height="1.32"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="213.36" y="185.43" class="st5" width="25.64" height="1.32"/>
                        <rect x="213.36" y="185.43" class="st21" width="25.64" height="1.32"/>
                        <rect x="213.36" y="185.43" class="st26" width="3.55" height="1.32"/>
                    </g>
                    <g>
				        <rect x="200" y="185.43" class="st5" width="10.18" height="1.32"/>
                        <rect x="200" y="185.43" class="st21" width="10.18" height="1.32"/>
                        <rect x="208.77" y="185.43" class="st26" width="1.41" height="1.32"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="213.36" y="197.18" class="st16" width="25.64" height="1.32"/>
                        <rect x="213.36" y="197.18" class="st21" width="25.64" height="1.32"/>
                        <rect x="213.36" y="197.18" class="st26" width="3.55" height="1.32"/>
                    </g>
                    <g>
				        <rect x="200" y="197.18" class="st5" width="10.18" height="1.32"/>
                        <rect x="200" y="197.18" class="st21" width="10.18" height="1.32"/>
                        <rect x="208.77" y="197.18" class="st26" width="1.41" height="1.32"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="213.36" y="208.93" class="st16" width="25.64" height="1.32"/>
                        <rect x="213.36" y="208.93" class="st21" width="25.64" height="1.32"/>
                        <rect x="213.36" y="208.93" class="st26" width="3.55" height="1.32"/>
                    </g>
                    <g>
				        <rect x="200" y="208.93" class="st5" width="10.18" height="1.32"/>
                        <rect x="200" y="208.93" class="st21" width="10.18" height="1.32"/>
                        <rect x="208.77" y="208.93" class="st26" width="1.41" height="1.32"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="213.36" y="220.68" class="st5" width="25.64" height="1.32"/>
                        <rect x="213.36" y="220.68" class="st21" width="25.64" height="1.32"/>
                        <rect x="213.36" y="220.68" class="st26" width="3.55" height="1.32"/>
                    </g>
                    <g>
				        <rect x="200" y="220.68" class="st5" width="10.18" height="1.32"/>
                        <rect x="200" y="220.68" class="st21" width="10.18" height="1.32"/>
                        <rect x="208.77" y="220.68" class="st26" width="1.41" height="1.32"/>
                    </g>
                </g>
            </g>
        </g>
        <g id="broadcastingGroup">
	        <path class="st27" d="M77.74,74.58c2.96,17-8.81,33.97-25.62,37.07c-18.8,3.47-37.51-11.62-37.9-30.89c-0.36-18.14,15.63-33.3,33.43-32.6C62.28,48.74,75.18,59.92,77.74,74.58z"/>
            <g id="broadcastingLines">
		        <path class="st28" d="M22.55,60.18c0.07-0.03,0.13-0.08,0.19-0.15c3-3.57,6.63-6.42,10.78-8.47l0.08-0.04c0.27-0.14,0.36-0.44,0.23-0.71c-0.12-0.27-0.48-0.36-0.76-0.22c-4.3,2.12-8.05,5.07-11.16,8.77c-0.2,0.24-0.17,0.58,0.05,0.77C22.14,60.26,22.36,60.27,22.55,60.18z"/>
                <path class="st28" d="M16.24,91.89c0.02-0.01,0.03-0.01,0.05-0.02c0.25-0.12,0.38-0.42,0.29-0.68c-1.44-4.23-1.95-8.86-1.47-13.38c0.03-0.3-0.18-0.56-0.48-0.58c-0.3-0.02-0.57,0.21-0.6,0.51c-0.5,4.68,0.03,9.47,1.52,13.84C15.65,91.85,15.96,91.99,16.24,91.89z"/>
                <path class="st28" d="M18.04,67.07c0.11-0.05,0.2-0.14,0.26-0.26c0.75-1.52,1.63-3.01,2.62-4.42c0.13-0.18,0.25-0.36,0.38-0.53c0.18-0.25,0.13-0.59-0.11-0.76c-0.24-0.17-0.58-0.11-0.76,0.14c-0.13,0.18-0.26,0.36-0.39,0.55c-1.02,1.46-1.93,2.99-2.71,4.57c-0.14,0.27-0.03,0.6,0.24,0.72C17.73,67.15,17.9,67.15,18.04,67.07z"/>
                <path class="st28" d="M44.55,48.37c1.88-0.19,3.78-0.22,5.65-0.07c0.1,0.01,0.2-0.01,0.29-0.06c0.17-0.08,0.29-0.25,0.32-0.45c0.04-0.3-0.17-0.56-0.47-0.59c-1.93-0.15-3.9-0.13-5.85,0.07c-0.3,0.03-0.53,0.3-0.51,0.6C44,48.19,44.25,48.4,44.55,48.37z"/>
                <path class="st28" d="M64.74,53.4c0,0,0.04,0.03,0.04,0.03l0.6-0.94c-3.84-2.62-8.12-4.31-12.73-5.01c-0.3-0.04-0.58,0.16-0.64,0.46c-0.06,0.3,0.13,0.58,0.43,0.62C56.9,49.24,61.04,50.87,64.74,53.4z"/>
                <path class="st28" d="M72.18,98.34c0.08-0.04,0.16-0.1,0.21-0.18c0.12-0.17,0.24-0.34,0.36-0.51c1.03-1.47,1.96-3.02,2.74-4.62c0.14-0.27,0.03-0.6-0.24-0.72c-0.27-0.12-0.6,0-0.73,0.27c-0.76,1.54-1.66,3.04-2.65,4.46c-0.12,0.17-0.23,0.33-0.35,0.49c-0.18,0.25-0.13,0.59,0.11,0.76C71.8,98.42,72.01,98.43,72.18,98.34z"/>
                <path class="st28" d="M40.4,111.92c0.16-0.08,0.28-0.22,0.31-0.41c0.06-0.3-0.13-0.58-0.43-0.62c-4.49-0.68-8.67-2.31-12.4-4.85c0,0-0.08-0.05-0.08-0.05l-0.32,0.46l-0.24,0.51c3.86,2.63,8.18,4.32,12.83,5.02C40.19,111.99,40.3,111.97,40.4,111.92z"/>
                <path class="st28" d="M76.35,67.52c-0.29,0.11-0.44,0.43-0.34,0.71c1.45,4.23,1.97,8.85,1.49,13.38c-0.03,0.3,0.18,0.56,0.48,0.58c0.1,0.01,0.19-0.02,0.28-0.06c0.17-0.08,0.3-0.25,0.32-0.46c0.49-4.68-0.05-9.47-1.54-13.84C76.94,67.55,76.63,67.41,76.35,67.52z"/>
                <path class="st28" d="M17.38,93.27c-0.12-0.27-0.43-0.39-0.71-0.26c-0.28,0.14-0.41,0.46-0.29,0.73c0.09,0.21,0.18,0.42,0.28,0.63c0.7,1.54,1.53,3.03,2.46,4.43c0.15,0.22,0.44,0.29,0.67,0.17c0.03-0.01,0.05-0.03,0.08-0.05c0.25-0.18,0.32-0.52,0.16-0.76c-0.91-1.35-1.71-2.79-2.38-4.28C17.56,93.68,17.47,93.47,17.38,93.27z"/>
                <path class="st28" d="M73.46,60.61c-0.16-0.24-0.5-0.3-0.75-0.12c-0.25,0.18-0.32,0.52-0.16,0.76c0.91,1.36,1.72,2.81,2.4,4.31c0.09,0.19,0.17,0.38,0.25,0.57c0.12,0.27,0.43,0.39,0.71,0.26c0,0,0.01,0,0.01,0c0.27-0.13,0.39-0.45,0.28-0.72c-0.08-0.2-0.17-0.39-0.26-0.59C75.24,63.52,74.4,62.02,73.46,60.61z"/>
                <path class="st28" d="M42.4,112.24c1.94,0.15,3.91,0.12,5.87-0.08c0.07-0.01,0.13-0.03,0.19-0.06c0.2-0.1,0.34-0.31,0.32-0.54c-0.02-0.3-0.27-0.52-0.58-0.49c-1.89,0.2-3.8,0.22-5.68,0.08c-0.3-0.02-0.57,0.2-0.61,0.5C41.88,111.95,42.1,112.21,42.4,112.24z"/>
                <path class="st28" d="M68.69,102.45c0.77-0.76,1.52-1.56,2.22-2.4c0.2-0.24,0.18-0.58-0.05-0.77c-0.23-0.19-0.57-0.15-0.77,0.09c-3.02,3.59-6.67,6.45-10.86,8.51l-0.04,0.02c-0.28,0.14-0.38,0.45-0.26,0.72c0.12,0.27,0.46,0.37,0.74,0.23c3.02-1.48,5.76-3.38,8.2-5.64l8.97,9.47c0.3-0.22,0.6-0.45,0.9-0.67L68.69,102.45z"/>
                <path class="st28" d="M91.17,130.26c0.52,1.13,1.88,1.59,3.04,1.02c1.16-0.57,1.68-1.95,1.17-3.09c-0.52-1.13-1.88-1.59-3.04-1.02c-0.06,0.03-0.1,0.06-0.15,0.1l-14.43-15.24c-0.3,0.22-0.6,0.45-0.9,0.67l14.52,15.34C90.95,128.69,90.84,129.52,91.17,130.26zM92.78,128.14c0.61-0.3,1.33-0.06,1.6,0.54c0.27,0.6,0,1.32-0.61,1.62c-0.61,0.3-1.33,0.06-1.6-0.54C91.9,129.17,92.17,128.44,92.78,128.14z"/>
	        </g>
            <g id="broadcasting">
		        <path class="st28" d="M28.29,76.99c-1.21,0.52-2.06,1.54-2.24,2.74c-0.12,0.8-0.02,1.82,0.52,3.07c1.69,3.86,4.92,2.62,7.2,2.36c2.41-0.27,5.03-0.79,6.92-1.28c0.83-0.22,0.71-0.54,0.71-0.54s-3.6-9.18-4.25-9.57C36.63,73.45,30.52,76.03,28.29,76.99z"/>
                <path class="st28" d="M37.53,94.62c0.26-0.65-0.58-10.58-0.58-10.58l-6.29,0.66c-0.04,0.13,1.33,9.72,2.29,10.35C33.91,95.68,37.28,95.27,37.53,94.62z"/>
                <path class="st29" d="M40.71,83.7c3.44-0.49,6.58,1.13,6.58,1.13c-1.77-1.89-2.64-4.35-3.87-7.3c-1.75-4.16-1.44-8.54-1.51-8.55c-0.55,2.82-4.75,4.79-4.75,4.79s-2.99,1.68-1.04,6.07C38.06,84.22,40.71,83.7,40.71,83.7z"/>
                <path class="st30" d="M40.71,83.7c3.44-0.49,6.58,1.13,6.58,1.13c-1.77-1.89-2.64-4.35-3.87-7.3c-1.75-4.16-1.44-8.54-1.51-8.55c-0.55,2.82-4.75,4.79-4.75,4.79s-2.99,1.68-1.04,6.07C38.06,84.22,40.71,83.7,40.71,83.7z"/>
                <polygon class="st15" points="36.95,84.69 32.68,85.33 37.02,85.58"/>
                <path class="st11" d="M37.61,89.5l0.72-0.17l-0.1-0.58c0,0-1.12-0.54-1.32-1.98l-1.35-0.04l0.44,2.77C36.01,89.5,36.69,89.59,37.61,89.5z"/>
                <path class="st15" d="M37.61,89.5l-0.15-0.72c0,0-1.2-0.36-1.33-2.03l-0.57-0.02l0.44,2.77C36.01,89.5,36.89,89.58,37.61,89.5z"/>
                <ellipse transform="matrix(0.911 -0.4125 0.4125 0.911 -26.129 28.806)" class="st31" cx="53.66" cy="74.93" rx="10.72" ry="15.83"/>
                <ellipse transform="matrix(0.911 -0.4125 0.4125 0.911 -26.129 28.806)" class="st32" cx="53.66" cy="74.93" rx="10.72" ry="15.83"/>
                <path class="st24" d="M58.92,89.79c5.22-1.28,7.64-7.73,5.97-14.98c0.29,1.44,1.04,7.5-6.8,8.98c-8.91,1.68-15.63-16.61-15.63-16.61l-0.22,0.18c-0.62,2.96-0.37,6.52,0.92,10.16C46.06,85.72,53.12,91.22,58.92,89.79z"/>
                <path class="st28" d="M42.37,74.3l7.54-0.76l1.52,4.82l-6.75,2.67C44.68,81.02,42.9,78.7,42.37,74.3z"/>
                <path class="st28" d="M52.28,75.6c0.44,1.36-0.07,2.65-1.12,2.89c-1.06,0.23-2.26-0.68-2.7-2.05c-0.44-1.36,0.07-2.65,1.12-2.89C50.63,73.32,51.84,74.24,52.28,75.6z"/>
                <path class="st22" d="M52.28,75.6c0.44,1.36-0.07,2.65-1.12,2.89c-1.06,0.23-2.26-0.68-2.7-2.05c-0.44-1.36,0.07-2.65,1.12-2.89C50.63,73.32,51.84,74.24,52.28,75.6z"/>
                <ellipse transform="matrix(0.9127 -0.4087 0.4087 0.9127 -25.9079 28.5291)" class="st33" cx="53.81" cy="74.89" rx="10.57" ry="15.83"/>
            </g>
        </g>
        <g id="personsGroup">
	        <path class="st27" d="M151.8,48.18c-3.44,13.81-16.51,23.28-29.35,24.16C104.71,73.56,85.56,58.49,86.07,38c0.47-19.05,17.72-33.88,34.94-32.95C141.32,6.14,156.62,28.86,151.8,48.18z"/>
            <g id="personsLines">
		        <path class="st28" d="M111.96,7.42c0.08,0.02,0.16,0.02,0.24,0c4.67-1,9.41-0.99,14.1,0.03l0.09,0.02c0.31,0.07,0.56-0.13,0.63-0.44c0.06-0.3-0.18-0.61-0.49-0.68c-4.85-1.05-9.76-1.06-14.59-0.03c-0.31,0.07-0.5,0.36-0.43,0.67C111.57,7.21,111.75,7.37,111.96,7.42z"/>
                <path class="st28" d="M87.37,29.54c0.02,0.01,0.04,0.01,0.05,0.01c0.29,0.06,0.57-0.1,0.66-0.38c1.39-4.44,3.8-8.61,6.96-12.04c0.21-0.23,0.19-0.58-0.05-0.79c-0.23-0.21-0.6-0.2-0.81,0.03c-3.27,3.55-5.76,7.86-7.2,12.45C86.9,29.13,87.07,29.44,87.37,29.54z"/>
                <path class="st28" d="M104.02,10.18c0.12,0.03,0.26,0.01,0.38-0.05c1.55-0.77,3.19-1.42,4.87-1.95c0.21-0.07,0.43-0.13,0.64-0.19c0.3-0.09,0.47-0.4,0.38-0.7c-0.09-0.3-0.41-0.47-0.72-0.38c-0.22,0.06-0.44,0.13-0.66,0.2c-1.73,0.54-3.43,1.22-5.04,2.01c-0.28,0.14-0.39,0.48-0.24,0.75C103.71,10.04,103.86,10.15,104.02,10.18z"/>
                <path class="st28" d="M137.38,12.02c1.68,1.07,3.27,2.29,4.72,3.63c0.08,0.07,0.17,0.12,0.27,0.14c0.19,0.04,0.39-0.01,0.54-0.16c0.21-0.22,0.2-0.58-0.03-0.79c-1.51-1.39-3.15-2.65-4.88-3.76c-0.27-0.17-0.62-0.1-0.79,0.16C137.04,11.5,137.12,11.84,137.38,12.02z"/>
                <path class="st28" d="M151.02,29.36c0,0,0.02,0.05,0.02,0.05l1.07-0.38c-1.57-4.67-4.09-8.87-7.47-12.46c-0.22-0.23-0.58-0.25-0.81-0.04c-0.23,0.21-0.24,0.56-0.03,0.79C147.07,20.8,149.5,24.85,151.02,29.36z"/>
                <path class="st28" d="M135,68.06c-1.57,0.78-3.22,1.44-4.91,1.97c-0.2,0.06-0.39,0.12-0.59,0.18c-0.3,0.09-0.47,0.4-0.38,0.7c0.06,0.2,0.23,0.35,0.43,0.39c0.09,0.02,0.19,0.02,0.28-0.01c0.2-0.06,0.41-0.12,0.61-0.18c1.75-0.54,3.46-1.23,5.08-2.03c0.28-0.14,0.39-0.48,0.24-0.75C135.63,68.03,135.28,67.92,135,68.06z"/>
                <path class="st28" d="M88.34,48.85c0,0-0.03-0.1-0.03-0.1l-0.54,0.17l-0.51,0.26c1.58,4.69,4.11,8.89,7.51,12.5c0.08,0.09,0.19,0.15,0.3,0.17c0.18,0.04,0.37,0,0.51-0.13c0.23-0.21,0.24-0.56,0.03-0.79C92.3,57.44,89.86,53.38,88.34,48.85z"/>
                <path class="st28" d="M152,48.63c-0.31-0.09-0.62,0.07-0.71,0.36c-1.38,4.45-3.78,8.61-6.94,12.05c-0.21,0.23-0.19,0.58,0.05,0.79c0.08,0.07,0.17,0.11,0.26,0.13c0.19,0.04,0.4-0.01,0.54-0.17c3.26-3.55,5.75-7.87,7.18-12.46C152.47,49.04,152.3,48.72,152,48.63z"/>
                <path class="st28" d="M87.47,31.43c0.07-0.3-0.12-0.6-0.43-0.68c-0.32-0.07-0.61,0.11-0.68,0.41c-0.05,0.24-0.11,0.47-0.16,0.71c-0.36,1.73-0.59,3.5-0.67,5.27c-0.01,0.28,0.19,0.52,0.46,0.58c0.03,0.01,0.06,0.01,0.09,0.01c0.32,0.02,0.58-0.22,0.6-0.53c0.08-1.71,0.29-3.42,0.64-5.09C87.37,31.88,87.42,31.66,87.47,31.43z"/>
                <path class="st28" d="M153.83,41.03c0.01-0.31-0.23-0.58-0.55-0.59c-0.32-0.02-0.58,0.22-0.6,0.53c-0.07,1.72-0.29,3.45-0.65,5.14c-0.05,0.21-0.09,0.43-0.14,0.64c-0.07,0.3,0.12,0.6,0.43,0.68c0,0,0.01,0,0.01,0c0.3,0.07,0.6-0.12,0.67-0.42c0.05-0.22,0.1-0.44,0.15-0.66C153.53,44.6,153.75,42.81,153.83,41.03z"/>
                <path class="st28" d="M102.01,66.23c-1.68-1.07-3.27-2.29-4.73-3.63c-0.23-0.21-0.59-0.2-0.81,0.02c-0.21,0.22-0.2,0.58,0.03,0.79c1.51,1.38,3.15,2.65,4.89,3.75c0.06,0.04,0.12,0.06,0.19,0.08c0.23,0.05,0.47-0.04,0.6-0.24C102.36,66.74,102.28,66.4,102.01,66.23z"/>
                <path class="st28" d="M124.58,72.43c1.11-0.13,2.21-0.3,3.31-0.54c0.31-0.07,0.5-0.36,0.43-0.67c-0.07-0.3-0.38-0.49-0.69-0.43c-4.7,1.01-9.48,1-14.2-0.02l-0.05-0.01c-0.31-0.07-0.59,0.13-0.65,0.43c-0.06,0.3,0.16,0.61,0.47,0.67c3.4,0.74,6.83,0.95,10.25,0.67l1.1,9.29c0.37-0.08,0.75-0.16,1.13-0.24L124.58,72.43z"/>
                <path class="st28" d="M126.13,110.07c-0.27,1.27,0.58,2.54,1.88,2.82c1.31,0.28,2.59-0.52,2.86-1.8c0.27-1.27-0.58-2.54-1.88-2.82c-0.06-0.01-0.12-0.01-0.19-0.02l-3.14-26.66c-0.38,0.08-0.75,0.15-1.13,0.24l3.13,26.53C126.91,108.62,126.31,109.25,126.13,110.07z M129.75,110.86c-0.14,0.67-0.82,1.09-1.5,0.95c-0.69-0.15-1.13-0.82-0.99-1.48c0.14-0.67,0.82-1.09,1.5-0.95C129.45,109.52,129.89,110.19,129.75,110.86z"/>
	        </g>
            <g id="persons">
                <g>
                    <path class="st34" d="M128.86,39.66l-19,1.09l-0.02-0.32c-0.28-4.75,3.48-8.83,8.39-9.11l1.23-0.07c4.91-0.28,9.11,3.34,9.38,8.09L128.86,39.66z"/>
                    <path class="st35" d="M128.7,36.75l0.17,2.91l-19.01,1.1l-0.17-2.91c-0.16-2.78,1.97-5.26,5-6.1c1.06,1.15,2.6,1.84,4.28,1.74c1.68-0.1,3.13-0.96,4.05-2.22C126.14,31.75,128.54,33.96,128.7,36.75z"/>
                    <path class="st34" d="M118.9,32.29L118.9,32.29c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C123.74,30.14,121.62,32.13,118.9,32.29z"/>
                    <path class="st36" d="M118.9,32.29L118.9,32.29c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C123.74,30.14,121.62,32.13,118.9,32.29z"/>
                    <path class="st29" d="M119.75,19.35l-0.03-0.59l-3.18,0.18l0.03,0.59c-3.76,0.96-4.05,5.02-4.05,5.02s2.24,0.53,5.61,0.34c0.13-0.01,0.23-0.04,0.34-0.06c0.11,0.01,0.22,0.03,0.35,0.02c3.38-0.19,5.54-0.98,5.54-0.98S123.59,19.87,119.75,19.35z"/>
                    <path class="st26" d="M118.43,24.08L118.43,24.08c-0.88,0.05-1.62-0.5-1.67-1.24l-0.23-3.9l3.18-0.18l0.23,3.9C119.98,23.39,119.31,24.03,118.43,24.08z"/>
                </g>
                <g>
                    <g>
                        <path class="st34" d="M141.83,45.93l-19,1.09l-0.02-0.32c-0.28-4.75,3.48-8.83,8.39-9.11l1.23-0.07c4.91-0.28,9.11,3.34,9.38,8.09L141.83,45.93z"/>
                        <path class="st22" d="M141.67,43.01l0.17,2.91l-19.01,1.1l-0.17-2.91c-0.16-2.78,1.97-5.26,5-6.1c1.06,1.15,2.6,1.84,4.28,1.74c1.68-0.1,3.13-0.96,4.05-2.22C139.11,38.01,141.51,40.23,141.67,43.01z"/>
                        <path class="st34" d="M131.87,38.55L131.87,38.55c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C136.71,36.4,134.59,38.4,131.87,38.55z"/>
                        <path class="st22" d="M131.87,38.55L131.87,38.55c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C136.71,36.4,134.59,38.4,131.87,38.55z"/>
                        <path class="st29" d="M132.72,25.61l-0.03-0.59l-3.18,0.18l0.03,0.59c-3.76,0.96-4.05,5.02-4.05,5.02s2.24,0.53,5.61,0.34c0.13-0.01,0.23-0.04,0.34-0.06c0.11,0.01,0.22,0.03,0.35,0.02c3.38-0.19,5.54-0.98,5.54-0.98S136.56,26.13,132.72,25.61z"/>
                        <path class="st26" d="M131.4,30.34L131.4,30.34c-0.88,0.05-1.62-0.5-1.67-1.24l-0.23-3.9l3.18-0.18l0.23,3.9C132.95,29.65,132.28,30.29,131.4,30.34z"/>
                    </g>
		        </g>
                <g>
			        <path class="st34" d="M129.67,53.63l-19,1.09l-0.02-0.32c-0.28-4.75,3.48-8.83,8.39-9.11l1.23-0.07c4.91-0.28,9.11,3.34,9.38,8.09L129.67,53.63z"/>
                    <path class="st21" d="M129.51,50.72l0.17,2.91l-19.01,1.1l-0.17-2.91c-0.16-2.78,1.97-5.26,5-6.1c1.06,1.15,2.6,1.84,4.28,1.74c1.68-0.1,3.13-0.96,4.05-2.22C126.95,45.72,129.35,47.93,129.51,50.72z"/>
                    <path class="st34" d="M119.71,46.26L119.71,46.26c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C124.55,44.11,122.43,46.1,119.71,46.26z"/>
                    <path class="st21" d="M119.71,46.26L119.71,46.26c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C124.55,44.11,122.43,46.1,119.71,46.26z"/>
                    <path class="st29" d="M120.56,33.32l-0.03-0.59l-3.18,0.18l0.03,0.59c-3.76,0.96-4.05,5.02-4.05,5.02s2.24,0.53,5.61,0.34c0.13-0.01,0.23-0.04,0.34-0.06c0.11,0.01,0.22,0.03,0.35,0.02c3.38-0.19,5.54-0.98,5.54-0.98S124.4,33.84,120.56,33.32z"/>
                    <path class="st26" d="M119.24,38.05L119.24,38.05c-0.88,0.05-1.62-0.5-1.67-1.24l-0.23-3.9l3.18-0.18l0.23,3.9C120.79,37.36,120.12,38,119.24,38.05z"/>
		        </g>
                <g>
			        <path class="st34" d="M116.7,47.37l-19,1.09l-0.02-0.32c-0.28-4.75,3.48-8.83,8.39-9.11l1.23-0.07c4.91-0.28,9.11,3.34,9.38,8.09L116.7,47.37z"/>
                    <path class="st22" d="M116.54,44.46l0.17,2.91l-19.01,1.1l-0.17-2.91c-0.16-2.78,1.97-5.26,5-6.1c1.06,1.15,2.6,1.84,4.28,1.74c1.68-0.1,3.13-0.96,4.05-2.22C113.98,39.46,116.38,41.67,116.54,44.46z"/>
                    <path class="st34" d="M106.74,40L106.74,40c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C111.58,37.85,109.47,39.84,106.74,40z"/>
                    <path class="st22" d="M106.74,40L106.74,40c-2.72,0.16-5.05-1.58-5.19-3.86l-0.21-3.7c-0.13-2.28,1.99-4.27,4.71-4.43l0,0c2.72-0.16,5.05,1.58,5.19,3.86l0.21,3.7C111.58,37.85,109.47,39.84,106.74,40z"/>
                    <path class="st29" d="M107.59,27.06l-0.03-0.59l-3.18,0.18l0.03,0.59c-3.76,0.96-4.05,5.02-4.05,5.02s2.24,0.53,5.61,0.34c0.13-0.01,0.23-0.04,0.34-0.06c0.11,0.01,0.22,0.03,0.35,0.02c3.38-0.19,5.54-0.98,5.54-0.98S111.43,27.58,107.59,27.06z"/>
                    <path class="st26" d="M106.27,31.79L106.27,31.79c-0.88,0.05-1.62-0.5-1.67-1.24l-0.23-3.9l3.18-0.18l0.23,3.9C107.82,31.1,107.15,31.74,106.27,31.79z"/>
                </g>
            </g>
        </g>
        <g id="deliveryGroup">
	        <path class="st27" d="M239.74,71.36c-4.91,12.84-18.3,22.85-32.87,22.34c-18.56-0.65-36-17.89-33.47-38.01c2.37-18.79,21.14-30.91,38.24-29.02c13.66,1.51,25.74,11.9,29.22,25.07C243.31,61.04,240.71,68.83,239.74,71.36z"/>
            <g id="deliveryLines">
		        <path class="st28" d="M225.23,32.22c0.04,0.07,0.1,0.13,0.18,0.17c4.17,2.57,7.64,5.91,10.32,9.93l0.05,0.08c0.18,0.27,0.51,0.3,0.78,0.13c0.27-0.17,0.32-0.57,0.14-0.83c-2.78-4.16-6.37-7.62-10.68-10.28c-0.27-0.17-0.64-0.09-0.81,0.18C225.1,31.8,225.11,32.04,225.23,32.22z"/>
                <path class="st28" d="M190.75,30.73c0.01,0.02,0.02,0.03,0.03,0.05c0.16,0.24,0.49,0.33,0.76,0.2c4.32-2.2,9.18-3.48,14.05-3.71c0.32-0.01,0.57-0.28,0.55-0.6c-0.02-0.32-0.29-0.56-0.62-0.54c-5.04,0.24-10.06,1.56-14.53,3.83C190.71,30.1,190.6,30.45,190.75,30.73z"/>
                <path class="st28" d="M217.35,28.61c0.07,0.11,0.18,0.19,0.31,0.23c1.71,0.54,3.4,1.23,5.02,2.03c0.2,0.1,0.41,0.21,0.61,0.31c0.29,0.15,0.64,0.05,0.79-0.23c0.15-0.28,0.04-0.62-0.24-0.77c-0.21-0.11-0.42-0.22-0.63-0.32c-1.67-0.83-3.42-1.54-5.19-2.1c-0.31-0.1-0.64,0.07-0.74,0.37C217.23,28.29,217.26,28.47,217.35,28.61z"/>
                <path class="st28" d="M240.47,53.38c0.44,1.94,0.69,3.93,0.77,5.91c0,0.11,0.04,0.21,0.09,0.29c0.11,0.16,0.3,0.27,0.51,0.26c0.32-0.01,0.58-0.27,0.57-0.59c-0.07-2.05-0.34-4.11-0.79-6.12c-0.07-0.31-0.38-0.5-0.7-0.44C240.6,52.76,240.4,53.07,240.47,53.38z"/>
                <path class="st28" d="M237.59,75.35c0,0-0.03,0.05-0.03,0.05l1.07,0.48c2.32-4.44,3.59-9.21,3.77-14.16c0.01-0.32-0.24-0.58-0.56-0.6c-0.32-0.01-0.6,0.23-0.61,0.55C241.05,66.46,239.82,71.06,237.59,75.35z"/>
                <path class="st28" d="M197.84,91.84c-1.74-0.55-3.45-1.24-5.09-2.05c-0.19-0.09-0.38-0.19-0.57-0.29c-0.23-0.12-0.51-0.07-0.68,0.1c2.27,0.85,4.52,1.74,6.73,2.68C198.19,92.09,198.05,91.91,197.84,91.84z"/>
                <path class="st28" d="M191.41,90.32c0.05,0.08,0.13,0.15,0.22,0.19c0.19,0.1,0.39,0.2,0.59,0.3c1.69,0.84,3.46,1.56,5.26,2.12c0.31,0.1,0.64-0.07,0.74-0.37c0.03-0.09,0.03-0.19,0.01-0.28c-2.21-0.94-4.46-1.84-6.73-2.68c-0.04,0.04-0.08,0.08-0.11,0.13C191.28,89.92,191.3,90.15,191.41,90.32z"/>
                <path class="st28" d="M177.12,45.2c0,0,0.05-0.09,0.05-0.09l-0.52-0.26l-0.57-0.17c-2.32,4.46-3.59,9.24-3.78,14.21c0,0.12,0.03,0.24,0.09,0.33c0.1,0.15,0.27,0.25,0.47,0.26c0.32,0.01,0.6-0.23,0.61-0.55C173.65,54.13,174.87,49.51,177.12,45.2z"/>
                <path class="st28" d="M209.49,94.29c0.11,0.17,0.31,0.27,0.53,0.26c5.06-0.24,10.1-1.57,14.58-3.85c0.29-0.15,0.4-0.49,0.25-0.77c-0.16-0.28-0.51-0.39-0.8-0.24c-4.33,2.2-9.21,3.49-14.1,3.72c-0.32,0.02-0.57,0.28-0.56,0.6C209.41,94.11,209.44,94.21,209.49,94.29z"/>
                <path class="st28" d="M189.42,32.15c0.27-0.17,0.36-0.52,0.19-0.79c-0.18-0.27-0.53-0.35-0.81-0.19c-0.21,0.13-0.43,0.26-0.64,0.39c-1.55,0.98-3.03,2.09-4.4,3.3c-0.22,0.19-0.25,0.5-0.1,0.73c0.02,0.03,0.04,0.05,0.06,0.07c0.22,0.24,0.59,0.25,0.83,0.04c1.33-1.17,2.76-2.24,4.26-3.19C189.01,32.4,189.22,32.27,189.42,32.15z"/>
                <path class="st28" d="M230.99,85.66c0.24-0.21,0.26-0.58,0.04-0.81c-0.22-0.23-0.59-0.25-0.83-0.04c-1.33,1.18-2.78,2.26-4.29,3.22c-0.19,0.12-0.39,0.24-0.58,0.36c-0.27,0.17-0.36,0.52-0.19,0.79c0,0,0,0.01,0.01,0.01c0.17,0.26,0.53,0.34,0.8,0.18c0.2-0.12,0.4-0.24,0.6-0.37C228.11,88,229.61,86.88,230.99,85.66z"/>
                <path class="st28" d="M174.25,67.23c-0.44-1.94-0.7-3.93-0.78-5.91c-0.01-0.32-0.28-0.56-0.61-0.55c-0.32,0.01-0.58,0.27-0.56,0.59c0.08,2.05,0.35,4.11,0.8,6.12c0.02,0.07,0.04,0.13,0.08,0.19c0.13,0.19,0.37,0.3,0.62,0.25C174.12,67.84,174.32,67.54,174.25,67.23z"/>
                <path class="st28" d="M186.6,87.33c0.47,0.36,0.96,0.7,1.45,1.04c0.72,0.25,1.44,0.5,2.16,0.76c0.01-0.02,0.03-0.02,0.04-0.04c0.17-0.27,0.09-0.62-0.19-0.79c-4.2-2.57-7.71-5.93-10.41-9.97l-0.03-0.04c-0.18-0.27-0.53-0.32-0.8-0.15c-0.27,0.17-0.34,0.54-0.16,0.81c1.95,2.91,4.31,5.47,7.02,7.66l-0.62,0.75c0.4,0.13,0.79,0.26,1.19,0.39L186.6,87.33z"/>
                <path class="st28" d="M189.43,89.26c0.26,0.16,0.59,0.09,0.77-0.13c-0.72-0.26-1.43-0.51-2.16-0.76C188.5,88.68,188.96,88.98,189.43,89.26z"/>
                <path class="st28" d="M159.68,115.28c-1.15,0.72-1.47,2.22-0.72,3.33c0.75,1.12,2.29,1.44,3.44,0.72c1.15-0.72,1.47-2.22,0.72-3.33c-0.04-0.05-0.08-0.1-0.12-0.15l23.25-28.1c-0.4-0.13-0.79-0.26-1.19-0.39l-22.97,27.77C161.33,114.8,160.42,114.82,159.68,115.28z M161.75,118.38c-0.6,0.38-1.41,0.21-1.81-0.38c-0.39-0.59-0.22-1.37,0.38-1.75c0.6-0.38,1.41-0.21,1.81,0.38C162.52,117.21,162.35,118,161.75,118.38z"/>
	        </g>
            <g id="delivery">
                <g>
                    <polygon class="st37" points="211.68,75.45 184.06,74.56 184.62,57.63 212.24,58.51"/>
                    <polygon class="st38" points="211.68,75.45 184.06,74.56 184.62,57.63 212.24,58.51"/>
                    <polygon class="st14" points="196.88,74.97 184.06,74.56 184.62,57.63 197.44,58.04"/>
                    <path class="st9" d="M195.19,73.76l-0.57-0.02c-0.44-0.01-0.78-0.38-0.76-0.81l0.03-0.85c0.01-0.43,0.38-0.78,0.82-0.76l0.57,0.02c0.44,0.01,0.78,0.38,0.76,0.81L196.01,73C195.99,73.43,195.63,73.77,195.19,73.76z"/>
                    <path class="st9" d="M192.3,73.66l-0.57-0.02c-0.44-0.01-0.78-0.38-0.76-0.81l0.03-0.85c0.01-0.43,0.38-0.78,0.82-0.76l0.57,0.02c0.44,0.01,0.78,0.38,0.76,0.81l-0.03,0.85C193.1,73.34,192.74,73.68,192.3,73.66z"/>
                    <polygon class="st38" points="206.25,60.09 204.68,60.04 204.74,58.27 206.3,58.32"/>
                    <polygon class="st9" points="210.26,67.9 199.51,67.56 199.58,65.35 210.33,65.69"/>
                    <polygon class="st9" points="189,61.91 185.32,61.79 185.43,58.54 189.11,58.66"/>
                </g>
                <g>
                    <polygon class="st37" points="228.59,75.99 200.97,75.1 201.53,58.17 229.15,59.06"/>
                    <polygon class="st8" points="228.59,75.99 200.97,75.1 201.53,58.17 229.15,59.06"/>
                    <polygon class="st14" points="213.78,75.51 200.97,75.1 201.53,58.17 214.35,58.58"/>
                    <path class="st9" d="M212.1,74.3l-0.57-0.02c-0.44-0.01-0.78-0.38-0.76-0.81l0.03-0.85c0.01-0.43,0.38-0.78,0.82-0.76l0.57,0.02c0.44,0.01,0.78,0.38,0.76,0.81l-0.03,0.85C212.9,73.97,212.53,74.31,212.1,74.3z"/>
                    <path class="st9" d="M209.21,74.21l-0.57-0.02c-0.44-0.01-0.78-0.38-0.76-0.81l0.03-0.85c0.01-0.43,0.38-0.78,0.82-0.76l0.57,0.02c0.44,0.01,0.78,0.38,0.76,0.81l-0.03,0.85C210.01,73.88,209.64,74.22,209.21,74.21z"/>
                    <polygon class="st38" points="223.15,60.63 221.59,60.58 221.65,58.82 223.21,58.87"/>
                    <polygon class="st9" points="227.16,68.44 216.41,68.1 216.49,65.89 227.24,66.23"/>
                    <polygon class="st9" points="205.9,62.45 202.23,62.34 202.34,59.08 206.01,59.2"/>
                </g>
                <g>
			        <polygon class="st37" points="219.59,58.75 191.98,57.87 192.54,40.93 220.16,41.82"/>
                    <polygon class="st14" points="204.79,58.28 191.98,57.87 192.54,40.93 205.35,41.34"/>
                    <path class="st9" d="M203.1,57.06l-0.57-0.02c-0.44-0.01-0.78-0.38-0.76-0.81l0.03-0.85c0.01-0.43,0.38-0.78,0.82-0.76l0.57,0.02c0.44,0.01,0.78,0.38,0.76,0.81l-0.03,0.85C203.91,56.73,203.54,57.08,203.1,57.06z"/>
                    <path class="st9" d="M200.21,56.97l-0.57-0.02c-0.44-0.01-0.78-0.38-0.76-0.81l0.03-0.85c0.01-0.43,0.38-0.78,0.82-0.76l0.57,0.02c0.44,0.01,0.78,0.38,0.76,0.81l-0.03,0.85C201.02,56.64,200.65,56.98,200.21,56.97z"/>
                    <polygon class="st38" points="214.16,43.39 212.6,43.34 212.66,41.58 214.22,41.63"/>
                    <polygon class="st9" points="218.17,51.21 207.42,50.86 207.49,48.65 218.25,48.99"/>
                    <polygon class="st9" points="196.91,45.22 193.24,45.1 193.34,41.84 197.02,41.96"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "AboutSvg"
    }
</script>

<style scoped lang="scss">
    #AboutSvg{
        .st0{fill:#D1EBFF;}
        .st1{fill:#BBBBBC;}
        .st2{fill:#A7A7AA;}
        .st3{fill:#EEEEEB;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st4{fill:#F0F0F0;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st5{fill:#575757;}
        .st6{opacity:0.6;fill:#FFFFFF;enable-background:new;}
        .st7{opacity:0.3;fill:#FFFFFF;enable-background:new;}
        .st8{opacity:0.4;fill:#FFFFFF;enable-background:new;}
        .st9{opacity:0.2;enable-background:new;}
        .st10{opacity:0.2;fill:#575757;enable-background:new;}
        .st11{fill:#1A2E35;}
        .st12{opacity:0.7;fill:#FFFFFF;enable-background:new;}
        .st13{opacity:0.5;fill:#FFFFFF;enable-background:new;}
        .st14{opacity:0.2;fill:#FFFFFF;enable-background:new;}
        .st15{opacity:0.3;}
        .st16{fill:#878C8E;}
        .st17{fill:#EFEFEF;}
        .st18{opacity:0.1;enable-background:new;}
        .st19{opacity:0.5;fill:#FFFFFF;}
        .st20{opacity:0.4;fill:#FFFFFF;}
        .st21{opacity:0.3;fill:#FFFFFF;}
        .st22{opacity:0.2;fill:#FFFFFF;}
        .st23{opacity:0.7;fill:#FFFFFF;}
        .st24{opacity:0.1;}
        .st25{fill:#6A6B6B;}
        .st26{opacity:0.2;}
        .st27{fill:#E9F0F4;}
        .st28{fill:#2872A0;}
        .st29{fill:#FFAC1A;}
        .st30{opacity:0.8;fill:#FFAC1A;}
        .st31{fill:#FFFFFF;}
        .st32{opacity:0.5;fill:#B5B5B5;}
        .st33{fill:none;stroke:#5B5B5B;stroke-width:0.35;stroke-miterlimit:10;}
        .st34{fill:#B5B5B5;stroke:#5B5B5B;stroke-width:0.25;stroke-miterlimit:10;}
        .st35{opacity:0.1;fill:#FFFFFF;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
        .st36{opacity:0.1;fill:#FFFFFF;}
        .st37{fill:#FFAC1A;stroke:#8A6A33;stroke-width:0.25;stroke-miterlimit:10;}
        .st38{opacity:0.4;enable-background:new;}
    }
</style>