<template>
    <svg version="1.1" id="Favicon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
        <circle class="st0" cx="15" cy="15" r="14"/>
        <g>
	        <polygon class="st1" points="19.29,20.23 15.47,6 14.01,6 10.19,20.23 7.38,21.52 7.38,21.9 22,21.9 22,21.52 	"/>
            <rect x="7.38" y="21.52" class="st2" width="14.62" height="0.48"/>
            <polygon class="st2" points="15.47,6 14.01,6 10.14,20.19 19.24,20.19"/>
            <polygon class="st3" points="18.02,15.59 17.15,12.32 12.29,12.32 11.4,15.59"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Favicon"
    }
</script>

<style scoped lang="scss">
    #Favicon{
        .st0{fill:#66B0FF;}
        .st1{fill:#FFAC1A;}
        .st2{opacity:0.3;fill:#FFFFFF;enable-background:new;}
        .st3{opacity:0.8;fill:#FFFFFF;enable-background:new;}
    }
</style>