<template>
    <svg version="1.1" id="CertificationSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
        <rect x="5.37" y="19.71" class="st0" width="2.45" height="4.65"/>
        <rect x="9.35" y="16.32" class="st0" width="2.45" height="8.03"/>
        <rect x="13.32" y="17.59" class="st0" width="2.45" height="6.76"/>
        <rect x="17.3" y="14.21" class="st0" width="2.45" height="10.15"/>
        <rect x="21.27" y="10.83" class="st0" width="2.45" height="13.53"/>
        <rect x="2" y="24.01" class="st1" width="25" height="0.7"/>
        <polyline class="st2" points="2.79,20.29 10.57,13.4 14.55,15.96 25.49,5.6 "/>
        <polygon class="st3" points="26.13,5 24.5,5.28 25.65,6.64 "/>
    </svg>
</template>

<script>
    export default {
        name: "CertificationSvg"
    }
</script>

<style scoped lang="scss">
    #CertificationSvg{
        .st0{fill:#8EC5FE;}
        .st1{fill:#3A4649;}
        .st2{fill:none;stroke:#007CFF;stroke-miterlimit:10;}
        .st3{fill:#007CFF;stroke:#007CFF;stroke-width:0.75;stroke-miterlimit:10;}
    }
</style>