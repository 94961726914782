<template>
    <svg version="1.1" id="ServicesSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 250" style="enable-background:new 0 0 300 250;" xml:space="preserve">
        <path id="Background" class="st0" d="M267.34,228.48c-14.44,21.44-43.75,8.11-108.88,7.76c-63.5-0.34-106.73,11.94-115.29-8.55c-0.21-0.5-0.8-7.47-2.42-21.37c-2.35-20.14-3.59-30.42-6.65-40.91c-3.11-10.65-4.74-11.07-8.77-22.26c-2.14-5.95-8.81-24.45-9.29-45.25c-0.17-7.37-1.1-47.01,22.18-66.14c8.83-7.26,21.76-12.08,25.03-13.3c4.02-1.5,11.03-4.11,19.33-5.11c10.59-1.28,16.65,0.87,37.7,6.48c20.04,5.34,30.31,8.05,42.45,10.23c12.32,2.2,19.67,3.51,30.1,3.75c23.41,0.54,30.4-5.07,48.92-0.39c11.87,3,17.69,7.5,20.17,9.61c9.43,8.03,13.99,18.5,16.33,24.02c1.88,4.44,6.39,15.36,6.72,30.03c0.18,8.08-1.02,12-2.88,22.82c-4.22,24.54-1.78,28.75-5.76,61.26C272.95,208.84,271.26,222.67,267.34,228.48z"/>
        <ellipse class="st1" cx="150.56" cy="233.07" rx="122.13" ry="10.93"/>
        <ellipse class="st2" cx="149.18" cy="232.03" rx="120.04" ry="8.55"/>
        <g>
	        <path class="st3" d="M44.03,45.58v182.9h11.04V45.58H44.03z"/>
            <path class="st4" d="M114.45,45.58v182.9h11.04V45.58H114.45z"/>
            <path class="st5" d="M53.74,45.58v182.9h73.63V45.58H53.74z M124.72,48.73v32.8H56.39v-32.8H124.72z M56.39,153.43v-32.8h68.33v32.8H56.39z M124.72,156.58v32.8H56.39v-32.8H124.72z M56.39,117.48v-32.8h68.33v32.8H56.39z M56.39,225.33v-32.8h68.33v32.8H56.39z"/>
            <g>
		        <rect x="77.71" y="128.28" class="st4" width="2.55" height="25.45"/>
                <rect x="75.17" y="128.28" class="st3" width="2.55" height="25.45"/>
                <rect x="80.26" y="130.07" class="st4" width="5.09" height="23.66"/>
                <rect x="87.9" y="128.28" class="st5" width="3.29" height="25.45"/>
                <rect x="85.35" y="128.28" class="st6" width="2.55" height="25.45"/>
                <rect x="107.22" y="128.28" class="st4" width="3.29" height="25.45"/>
                <rect x="104.67" y="128.28" class="st6" width="2.55" height="25.45"/>
                <rect x="96.28" y="128.28" class="st4" width="3.29" height="25.45"/>
                <rect x="93.74" y="128.28" class="st3" width="2.55" height="25.45"/>
                <rect x="110.51" y="128.88" class="st6" width="5.09" height="24.84"/>
                <rect x="115.6" y="128.88" class="st3" width="2.55" height="24.84"/>
                <rect x="99.58" y="128.88" class="st3" width="3.4" height="24.84"/>
                <rect x="102.97" y="128.88" class="st5" width="1.7" height="24.84"/>
                <rect x="91.19" y="130.07" class="st7" width="5.09" height="23.66"/>
                <rect x="64.23" y="128.28" class="st6" width="3.29" height="25.45"/>
                <rect x="61.69" y="128.28" class="st4" width="2.55" height="25.45"/>
                <rect x="67.53" y="128.88" class="st3" width="5.09" height="24.84"/>
                <rect x="72.62" y="128.88" class="st6" width="2.55" height="24.84"/>
                <rect x="56.59" y="128.88" class="st6" width="3.4" height="24.84"/>
                <rect x="59.99" y="128.88" class="st8" width="1.7" height="24.84"/>
	</g>
            <g>
			    <rect x="94.48" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 191.5112 354.5412)" class="st4" width="2.55" height="25.45"/>
                <rect x="97.03" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 196.6046 354.5412)" class="st3" width="2.55" height="25.45"/>
                <rect x="89.39" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 183.8711 352.7529)" class="st4" width="5.09" height="23.66"/>
                <rect x="83.55" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 170.3928 354.5412)" class="st5" width="3.29" height="25.45"/>
                <rect x="86.84" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 176.2308 354.5412)" class="st6" width="2.55" height="25.45"/>
                <rect x="64.23" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 131.7605 354.5412)" class="st4" width="3.29" height="25.45"/>
                <rect x="67.53" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 137.5985 354.5412)" class="st6" width="2.55" height="25.45"/>
                <rect x="75.17" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 153.6233 354.5412)" class="st4" width="3.29" height="25.45"/>
                <rect x="78.46" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 159.4614 354.5412)" class="st3" width="2.55" height="25.45"/>
                <rect x="59.14" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 123.3756 353.9384)" class="st6" width="5.09" height="24.84"/>
                <rect x="56.59" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 115.7355 353.9384)" class="st3" width="2.55" height="24.84"/>
                <rect x="71.77" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 146.9362 353.9384)" class="st3" width="3.4" height="24.84"/>
                <rect x="70.07" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 141.8428 353.9384)" class="st5" width="1.7" height="24.84"/>
                <rect x="78.46" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 162.008 352.7529)" class="st7" width="5.09" height="23.66"/>
                <rect x="107.22" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 217.723 354.5412)" class="st6" width="3.29" height="25.45"/>
                <rect x="110.51" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 223.5611 354.5412)" class="st4" width="2.55" height="25.45"/>
                <rect x="102.12" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 209.3381 353.9384)" class="st3" width="5.09" height="24.84"/>
                <rect x="99.58" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 201.698 353.9384)" class="st6" width="2.55" height="24.84"/>
                <rect x="114.75" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 232.8987 353.9384)" class="st6" width="3.4" height="24.84"/>
                <rect x="113.05" y="164.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 227.8053 353.9384)" class="st8" width="1.7" height="24.84"/>
	        </g>
            <g>
		        <rect x="77.71" y="200.81" class="st4" width="2.55" height="25.45"/>
                <rect x="75.17" y="200.81" class="st3" width="2.55" height="25.45"/>
                <rect x="80.26" y="202.6" class="st4" width="5.09" height="23.66"/>
                <rect x="87.9" y="200.81" class="st5" width="3.29" height="25.45"/>
                <rect x="85.35" y="200.81" class="st6" width="2.55" height="25.45"/>
                <rect x="107.22" y="200.81" class="st4" width="3.29" height="25.45"/>
                <rect x="104.67" y="200.81" class="st6" width="2.55" height="25.45"/>
                <rect x="96.28" y="200.81" class="st4" width="3.29" height="25.45"/>
                <rect x="93.74" y="200.81" class="st3" width="2.55" height="25.45"/>
                <rect x="110.51" y="201.41" class="st6" width="5.09" height="24.84"/>
                <rect x="115.6" y="201.41" class="st3" width="2.55" height="24.84"/>
                <rect x="99.58" y="201.41" class="st3" width="3.4" height="24.84"/>
                <rect x="102.97" y="201.41" class="st5" width="1.7" height="24.84"/>
                <rect x="91.19" y="202.6" class="st7" width="5.09" height="23.66"/>
                <rect x="64.23" y="200.81" class="st6" width="3.29" height="25.45"/>
                <rect x="61.69" y="200.81" class="st4" width="2.55" height="25.45"/>
                <rect x="67.53" y="201.41" class="st3" width="5.09" height="24.84"/>
                <rect x="72.62" y="201.41" class="st6" width="2.55" height="24.84"/>
                <rect x="56.59" y="201.41" class="st6" width="3.4" height="24.84"/>
                <rect x="59.99" y="201.41" class="st8" width="1.7" height="24.84"/>
	        </g>
            <g>
                <g>
                    <polygon class="st5" points="94.63,81.93 95.78,66.2 79.03,66.2 77.88,81.93"/>
                    <polygon class="st8" points="93.04,80.33 93.96,67.81 80.62,67.81 79.7,80.33"/>
                    <polygon class="st6" points="92.02,79.3 92.78,68.84 81.65,68.84 80.88,79.3"/>
                </g>
                <g>
                    <g>
                        <polygon class="st6" points="113.51,81.93 96.68,81.93 98.55,56.52 115.38,56.52"/>
                        <polygon class="st3" points="113.51,81.93 114.31,81.93 116.18,56.52 115.38,56.52"/>
                        <polygon class="st3" points="111.98,79.34 98.59,79.34 100.08,59.11 113.47,59.11"/>
                        <polygon class="st8" points="111,77.67 99.82,77.67 101.06,60.78 112.24,60.78"/>
                    </g>
                    <path class="st6" d="M102.56,69.49l1.86,2.22c0.76,0.91,1.99,0.91,2.75,0l1.86-2.22c0.76-0.91,0.76-2.37,0-3.28l-1.86-2.22c-0.76-0.91-1.99-0.91-2.75,0l-1.86,2.22C101.8,67.12,101.8,68.58,102.56,69.49z"/>
                    <path class="st4" d="M102,74.35l1.31,1.55c0.76,0.91,1.99,0.9,2.75,0l1.31-1.55c0.76-0.91,0.76-2.37,0-3.28l-1.31-1.55c-0.76-0.91-1.99-0.91-2.75,0L102,71.07C101.24,71.98,101.24,73.44,102,74.35z"/>
                </g>
            </g>
            <g>
                <ellipse class="st5" cx="69.59" cy="64.72" rx="7.82" ry="9.31"/>
                <g>
                    <path class="st6" d="M69.04,81.17h1.09V75h-0.55c-4.76,0-8.64-4.61-8.64-10.28c0-5.67,3.88-10.29,8.64-10.29v-1.3c-5.37,0-9.73,5.2-9.73,11.59c0,6.17,4.07,11.23,9.18,11.57V81.17z"/>
                </g>
                <path class="st6" d="M65,81.31h9.3l0,0c0-1.37-0.93-2.48-2.08-2.48h-5.13C65.93,78.83,65,79.94,65,81.31L65,81.31z"/>
            </g>
            <g>
                <g>
			        <path class="st8" d="M80.97,117.25H64.29c-1.15,0-2.08-1.72-2.08-3.84s0.93-3.84,2.08-3.84h16.69V117.25z"/>
                    <path class="st6" d="M80.97,108.94c-5.46,0-10.93-0.12-16.4,0c-3.74,0.08-3.94,8.62-0.27,8.92c2.56,0.21,5.19,0,7.75,0c2.97,0,5.94,0,8.92,0c0.67,0,0.67-1.24,0-1.24c-5.18,0-10.36,0-15.53,0c-1.2,0-2.06-0.02-2.52-1.7c-0.4-1.46-0.27-4.43,1.37-4.75c0.18-0.03,0.38,0,0.56,0c2.82,0,5.65,0,8.47,0c2.55,0,5.1,0,7.66,0C81.64,110.18,81.64,108.94,80.97,108.94L80.97,108.94z"/>
		        </g>
                <g>
			        <path class="st6" d="M84.78,107.77v-6.45c0.67,0,0.67-1.24,0-1.24c-2.06,0-4.13-0.02-6.2-0.03V109c2.07,0,4.13,0,6.2,0C85.44,109,85.45,107.77,84.78,107.77z"/>
                    <rect x="79.96" y="101.32" class="st7" width="4.81" height="6.44"/>
                    <path class="st8" d="M80.97,108.39H64.29c-1.15,0-2.08-1.72-2.08-3.84c0-2.12,0.93-3.84,2.08-3.84h16.69V108.39z"/>
                    <g>
				        <path class="st6" d="M80.97,100.08c-5.46,0-10.93-0.12-16.4,0c-3.74,0.08-3.94,8.62-0.27,8.92c2.56,0.21,5.19,0,7.75,0c2.97,0,5.94,0,8.92,0c0.67,0,0.67-1.24,0-1.24c-5.18,0-10.36,0-15.53,0c-1.2,0-2.06-0.02-2.52-1.7c-0.4-1.46-0.27-4.43,1.37-4.75c0.18-0.03,0.38,0,0.56,0c2.82,0,5.65,0,8.47,0c2.55,0,5.1,0,7.66,0C81.64,101.32,81.64,100.08,80.97,100.08L80.97,100.08z"/>
                    </g>
                </g>
            </g>
        </g>
        <g>
	        <path class="st4" d="M231.26,228.5c-4.46-6-8.92-12-13.38-18c0-3.59,0-78.99,0-82.58c0-2.64-3.05-2.64-3.05,0c0,3.59,0,78.99,0,82.58c-4.46,6-8.92,12-13.38,18c-1.39,1.87,0.77,4.77,2.15,2.9c3.74-5.03,7.48-10.07,11.22-15.1c0,4.5,0,9,0,13.5c0,2.64,3.05,2.64,3.05,0c0-4.5,0-9,0-13.5c3.74,5.03,7.48,10.07,11.22,15.1C230.5,233.26,232.65,230.37,231.26,228.5z"/>
            <rect x="162.55" y="61.08" class="st3" width="107.62" height="74.42"/>
            <path class="st6" d="M270.43,137.32H162.3c-0.52,0-0.93-0.42-0.93-0.93v-1.24c0-0.52,0.42-0.93,0.93-0.93h108.13c0.52,0,0.93,0.42,0.93,0.93v1.24C271.37,136.9,270.95,137.32,270.43,137.32z"/>
            <rect x="165.03" y="61.08" class="st8" width="102.68" height="71.57"/>
            <path class="st5" d="M272.16,64.5H160.57c-1.13,0-2.05-0.92-2.05-2.05v-2.73c0-1.13,0.92-2.05,2.05-2.05h111.59c1.13,0,2.05,0.92,2.05,2.05v2.73C274.21,63.58,273.29,64.5,272.16,64.5z"/>
        </g>
        <g>
	        <polygon class="st9" points="206.94,231.5 202.1,231.48 203.29,219.95 208.13,219.97"/>
            <path class="st10" d="M202.03,230.41l5.13-0.1c0.19,0,0.35,0.13,0.37,0.32l0.59,4.3c0.06,0.45-0.3,0.88-0.74,0.88c-1.85,0.01-4.51-0.05-6.84,0c-2.72,0.06-2.45,0.16-5.64,0.23c-1.93,0.04-2.31-1.97-1.48-2.17c3.74-0.91,4.12-0.96,7.46-3.05C201.23,230.58,201.62,230.41,202.03,230.41z"/>
            <g>
		        <path class="st10" d="M201.19,230.87c0.04-0.01,0.07-0.05,0.07-0.09c0.01-0.05-0.02-0.1-0.06-0.12c-0.22-0.11-2.12-1.06-2.69-0.72c-0.11,0.07-0.17,0.17-0.18,0.31v0c-0.01,0.23,0.06,0.42,0.22,0.55c0.52,0.46,1.87,0.24,2.63,0.07C201.18,230.87,201.18,230.87,201.19,230.87z M198.66,230.12c0.35-0.13,1.39,0.25,2.14,0.6c-1.04,0.2-1.81,0.17-2.11-0.09c-0.1-0.09-0.15-0.21-0.14-0.37c0-0.06,0.02-0.1,0.07-0.12C198.63,230.13,198.65,230.12,198.66,230.12z"/>
                <path class="st10" d="M201.19,230.87c0.01,0,0.02-0.01,0.03-0.02c0.03-0.02,0.05-0.06,0.04-0.11c-0.01-0.06-0.15-1.36-0.76-1.85c-0.19-0.15-0.39-0.21-0.62-0.18l0,0c-0.31,0.04-0.39,0.2-0.41,0.33c-0.08,0.55,1.07,1.57,1.64,1.83C201.13,230.88,201.16,230.88,201.19,230.87z M199.79,228.97c0.03-0.01,0.07-0.02,0.11-0.03l0,0c0.17-0.02,0.32,0.02,0.46,0.14c0.4,0.32,0.58,1.1,0.65,1.48c-0.59-0.35-1.36-1.15-1.32-1.48C199.69,229.05,199.7,229,199.79,228.97z M199.89,228.83L199.89,228.83L199.89,228.83z"/>
	        </g>
            <path class="st10" d="M215,182.53c6.56-23.18,9.33-40.1,7.89-51.6l-15.53-0.73c0,0-2.27,37.07-3.16,51.81c-0.85,14.13-2.38,46.12-2.38,46.12l7.09,0.1C208.91,228.23,216.84,198.32,215,182.53z"/>
            <path class="st11" d="M223.3,135.58l-2.38-1.66l-2.1,7.14c0,0-4.71,19.45-1.41,32.59C221.31,158.9,223.73,143.95,223.3,135.58z"/>
            <polygon class="st9" points="236.92,231.86 232.14,231.78 231.07,220.39 235.85,220.46 	"/>
            <path class="st10" d="M231.74,230.28l5.4-0.11c0.2,0,0.37,0.14,0.39,0.34l0.63,4.53c0.07,0.47-0.31,0.92-0.78,0.93c-1.95,0.01-2.56-0.13-5.01-0.08c-2.86,0.06-1.54,0.13-4.9,0.2c-2.03,0.04-2.43-2.06-1.57-2.28c2.73-0.7,3.41-1.85,4.61-3.1C230.83,230.38,231.31,230.29,231.74,230.28z"/>
            <g>
		        <path class="st10" d="M231.17,230.67c0.04-0.01,0.07-0.05,0.08-0.09c0.01-0.05-0.02-0.1-0.06-0.12c-0.22-0.12-2.12-1.15-2.7-0.82c-0.11,0.06-0.18,0.17-0.19,0.31h0c-0.02,0.23,0.05,0.42,0.2,0.57c0.52,0.48,1.89,0.31,2.66,0.16C231.16,230.67,231.17,230.67,231.17,230.67z M228.64,229.82c0.36-0.12,1.4,0.3,2.15,0.68c-1.06,0.16-1.84,0.11-2.13-0.1c-0.1-0.1-0.14-0.22-0.13-0.38c0.01-0.06,0.03-0.1,0.07-0.12C228.61,229.83,228.62,229.82,228.64,229.82z"/>
                <path class="st10" d="M231.17,230.67c0.01,0,0.02-0.01,0.03-0.02c0.03-0.02,0.05-0.06,0.05-0.1c0-0.06-0.11-1.38-0.71-1.9c-0.18-0.16-0.39-0.23-0.62-0.21l0,0c-0.31,0.03-0.4,0.19-0.43,0.32c-0.1,0.55,1.03,1.63,1.6,1.91C231.12,230.68,231.15,230.68,231.17,230.67z M229.82,228.69c0.03-0.01,0.07-0.02,0.12-0.02l0,0c0.17-0.02,0.32,0.04,0.46,0.15c0.39,0.34,0.55,1.14,0.61,1.52c-0.58-0.37-1.35-1.22-1.29-1.55C229.72,228.78,229.72,228.72,229.82,228.69z M229.93,228.55L229.93,228.55L229.93,228.55z"/>
	        </g>
            <path class="st10" d="M233.48,182.22c0.12-11.18,0.85-23.48,0.84-30.17c-0.01-7.47-1.04-21.37-1.04-21.37l-16.21,0.45c0,0,2.44,33.85,5.07,51.4c2.15,14.34,8.76,45.6,8.76,45.6l6.44,0.1C237.34,228.23,238.15,198.01,233.48,182.22z"/>
            <path class="st10" d="M207.28,130.87c0.66,0.73,3.55,0.68,7.17,1.13c0.76,0.09,2.06-1.07,2.06-1.07s1.46,1.43,2.05,1.47c5.47,0.4,12.34,0.46,14.68-0.3c2.58-19.95,1.15-32.74,0.24-39.23c-0.39-2.8-2.52-4.99-5.26-5.46c-5.12-0.88-10.1-1.14-14.87-0.55c-3.21,0.4-5.59,3.22-5.54,6.53c0.08,5.29,1.03,10.83,0.98,15.75C208.8,109.13,206.48,123.99,207.28,130.87z"/>
            <path class="st12" d="M207.28,130.87c0.66,0.73,3.55,0.68,7.17,1.13c0.76,0.09,2.06-1.07,2.06-1.07s1.46,1.43,2.05,1.47c5.47,0.4,12.34,0.46,14.68-0.3c2.58-19.95,1.15-32.74,0.24-39.23c-0.39-2.8-2.52-4.99-5.26-5.46c-5.12-0.88-10.1-1.14-14.87-0.55c-3.21,0.4-5.59,3.22-5.54,6.53c0.08,5.29,1.03,10.83,0.98,15.75C208.8,109.13,206.48,123.99,207.28,130.87z"/>
            <g>
		        <path class="st9" d="M196.54,109.37c0.12-0.74,2.95-5.6,5.28-10.07c0.79-1.52,4.79-10.4,8.3-9.52c0.07,0.43,0.09,0.84,0.14,1.26l0.06,1.23c0,0.01,0,0.02,0,0.02c0.07,1.42,0.26,3.64-0.43,4.91c-2.16,4.01-7.4,12.41-7.46,12.83c-0.03,0.14-0.05,0.45-0.05,0.47c0.01,0.02,0.01,0.04,0.01,0.06l-0.01,0.03c0-0.01,0-0.04,0-0.06L196.54,109.37z"/>
	        </g>
            <g>
		        <path class="st13" d="M211,95.64c-0.36,1.24-0.79,2.4-1.13,3.18c-1.76,4.03-4.25,6.71-4.25,6.71l-1.34-0.75c2.01-3.91,1.91-8.85,1.91-8.85l4.49-0.88L211,95.64z"/>
                <g>
			        <path class="st10" d="M209.14,89.15c-5.2,1.28-9.44,12.95-9.44,12.95l6.09,3.42c0,0,2.49-2.68,4.25-6.71C211.3,95.92,213.78,88.01,209.14,89.15z"/>
                    <path class="st12" d="M209.14,89.15c-5.2,1.28-9.44,12.95-9.44,12.95l6.09,3.42c0,0,2.49-2.68,4.25-6.71C211.3,95.92,213.78,88.01,209.14,89.15z"/>
		        </g>
                <polygon class="st10" points="206.38,105.25 199.43,101.3 198.91,103.22 205.17,106.74"/>
                <polygon class="st12" points="206.38,105.25 199.43,101.3 198.91,103.22 205.17,106.74"/>
	        </g>
            <path class="st9" d="M221.87,78.45c0.46,3.55,1.16,7.66,3.6,9.68c-0.91,2.45-3.19,4.65-6.54,4.83c-3.69,0.2-3.12-2.83-2.51-4.44c1.57-1.81,0.81-3.82,0.42-5.26L221.87,78.45z"/>
            <path class="st13" d="M220.85,80.18c-0.02-0.23-0.06-0.45-0.09-0.67l-3.92,3.75c0.24,0.86,0.6,1.93,0.52,3.03C219.3,84.95,221.09,82.33,220.85,80.18z"/>
            <g>
		        <g>
			        <path class="st14" d="M223.58,85.82c0.11,1.25-1.1,5.68-5.31,7.58c1.63,0.02,2.34,2.58,2.34,2.58s5.59-3.7,4.92-8.16C225.09,85.73,223.58,85.82,223.58,85.82z"/>
                    <g class="st15">
                        <path class="st14" d="M223.58,85.82c0.11,1.25-1.1,5.68-5.31,7.58c1.63,0.02,2.34,2.58,2.34,2.58s5.59-3.7,4.92-8.16C225.09,85.73,223.58,85.82,223.58,85.82z"/>
                    </g>
		        </g>
                <path class="st14" d="M217.03,86.27c0.12,1.22-1.25,3.79,1.58,7.24c-1.96,0.02-3.14,2.03-3.14,2.03s-2.61-3.83-0.56-8.26C215.96,86.34,217.03,86.27,217.03,86.27z"/>
	        </g>
            <path class="st13" d="M233.7,94.46c0.48,3.74,1.01,9.08,1.01,16.13c-3.74-3.33-7.02-12.4-7.02-12.4v-3.61l2.79-2.31L233.7,94.46z"/>
            <path class="st16" d="M214.8,86.42l0.02,2.29c0.05,5.5,1.44,10.89,4.04,15.69l0,0l-2.29,25.98c-0.38,4.29-4.35,7.24-8.42,6.26l-2.97-0.71L208,110.1c0,0,2.88-11.87,0.77-21.9C208.77,88.21,210.06,87.15,214.8,86.42z"/>
            <path class="st17" d="M214.24,120.85c-0.06-15.61-1.16-31.32-1.36-34.08c-0.79,0.17-1.45,0.34-1.98,0.51c0.25,3.52,1.28,18.58,1.33,33.54l-5.39-0.09l-0.23,2.07l10.6,0.18l0.18-2.07L214.24,120.85z"/>
            <g>
                <g>
                    <path class="st10" d="M231.44,88.12c6.7,2.17,9.92,15.7,9.92,15.7l-6.71,3.86c0,0-3.43-2.51-5.86-7.27C228.06,98.97,226.1,86.39,231.44,88.12z"/>
                </g>
                <g class="st18">
			        <path class="st14" d="M231.44,88.12c6.7,2.17,9.92,15.7,9.92,15.7l-6.71,3.86c0,0-3.43-2.51-5.86-7.27C228.06,98.97,226.1,86.39,231.44,88.12z"/>
                </g>
            </g>
            <path class="st16" d="M225.92,86.53l-7.32,15.17c-0.73,1.52-1.15,3.18-1.23,4.87l-1.05,23.91c-0.17,3.87,3,7.05,6.84,6.86l11.54-0.56c0,0-0.47-15.36,0.65-25.73c0,0-10.61-5.69-4.37-23.12L225.92,86.53z"/>
            <path class="st12" d="M225.28,121.04v-1.82c0-18.72,2.42-28.42,3.53-31.89l-1.96-0.54c-1.21,3.78-3.58,13.58-3.58,32.43V121l-6.52-0.11l-0.09,2.07l18.02,0.31c0.01-0.68,0.03-1.37,0.05-2.07L225.28,121.04z"/>
            <g>
                <g>
                    <path class="st9" d="M225.3,109.92l-3.84-2l-0.94,5.22c0,0,3.69,1.37,4.71-0.52L225.3,109.92z"/>
                    <path class="st9" d="M217.21,109.32l-0.03,1.89c-0.01,0.75,0.51,1.4,1.22,1.54l2.12,0.4l0.94-5.22l-2.71-0.11C217.92,107.78,217.22,108.46,217.21,109.32z"/>
                </g>
                <path class="st9" d="M241.62,105.5c-0.33-0.9-0.69-1.76-1.06-2.61l-6.25,3.15c0.19,0.41,0.63,1.33,0.8,1.73c0.55,1.29,1.54,2.8,1.65,3.77c0.03,0.21,0.03,0.37,0.03,0.51c-0.33,0.04-0.75,0.05-1.18,0.03c-0.64-0.04-1.34-0.1-2.04-0.21c-2.83-0.44-5.84-1.2-8.76-1.96l-0.06-0.02c-0.88-0.23-1.77,0.32-2,1.22c-0.19,0.75,0.15,1.52,0.78,1.89c2.82,1.66,5.68,3.04,8.9,4.06c0.81,0.25,4.38,0.76,5.6,0.79c7.05,0.15,4.62-8.81,4.4-9.74C242.19,107.2,241.91,106.34,241.62,105.5z"/>
	        </g>
            <g id="copybook2">
		        <polygon class="st10" points="205.01,105.46 217.1,105.46 225.6,120.7 225.27,121.04 213.18,121.04 204.68,105.8"/>
                <polygon class="st19" points="205.01,105.46 217.1,105.46 225.6,120.7 225.27,121.04 213.18,121.04 204.68,105.8"/>
                <polygon class="st10" points="213.18,121.04 225.27,121.04 216.78,105.8 204.68,105.8"/>
                <polygon class="st20" points="213.18,121.04 225.27,121.04 216.78,105.8 204.68,105.8"/>
                <g>
			        <polygon class="st21" points="208.11,105.24 212.71,105.24 213.69,106.99 213.55,107.13 208.95,107.13 207.97,105.38"/>
                    <polygon class="st22" points="208.11,105.24 212.71,105.24 213.69,106.99 213.55,107.13 208.95,107.13 207.97,105.38 			"/>
                    <polygon class="st19" points="208.95,107.13 207.97,105.38 212.58,105.38 213.55,107.13"/>
                </g>
            </g>
            <g id="copybook1">
		        <polygon class="st10" points="206.26,104.82 218.27,106.17 225.02,122.27 224.65,122.57 212.64,121.22 205.89,105.12"/>
                <polygon class="st19" points="206.26,104.82 218.27,106.17 225.02,122.27 224.65,122.57 212.64,121.22 205.89,105.12"/>
                <polygon class="st10" points="212.64,121.22 224.65,122.57 217.91,106.47 205.89,105.12"/>
                <polygon class="st20" points="212.64,121.22 224.65,122.57 217.91,106.47 205.89,105.12"/>
                <polygon class="st21" points="209.36,104.95 213.94,105.46 214.71,107.31 214.56,107.43 209.98,106.92 209.21,105.07"/>
                <polygon class="st19" points="209.98,106.92 209.21,105.07 213.78,105.58 214.56,107.43"/>
	        </g>
            <g>
		        <path class="st9" d="M201.7,109.66c-0.05-0.06-0.02-0.04-0.04-0.06c-0.02-0.02,0.05,0.04,0.2,0.12c4.03,1.34,6.11,2.23,7.57,1.54c1.09-0.51,1.29,3.62,0.13,3.93c-18.54,4.92-14.16-4.61-11.93-7.88C197.83,109.14,201.7,109.66,201.7,109.66z"/>
	        </g>
            <g>
		        <path class="st9" d="M209.5,115.16l4.71,0.72l-1.39-5.1c0,0-3.98-0.57-4.41,1.93L209.5,115.16z"/>
                <path class="st9" d="M216.75,113.8l-0.27-2.18c-0.09-0.78-0.8-1.32-1.55-1.19l-2.11,0.36l1.39,5.1l1.66-0.61C216.46,115.05,216.82,114.44,216.75,113.8z"/>
	        </g>
            <g>
		        <polygon class="st10" points="241.29,101.85 232.92,106.62 234.45,108.34 242.13,103.97"/>
                <polygon class="st12" points="241.29,101.85 232.92,106.62 234.45,108.34 242.13,103.97"/>
	        </g>
            <g id="headBoyCopybook2">
		        <path class="st9" d="M223.59,72.25c0.04,4.73,0.3,7.49-1.85,10.04c-3.24,3.83-9.04,1.98-10.46-2.66c-1.27-4.18-1.12-11.2,3.24-13.33C218.81,64.22,223.55,67.53,223.59,72.25z"/>
                <path class="st10" d="M221.88,76.81c0,0-1.36,0.32-1.25-1.73c0.11-2.05-2.06-3.78-1.77-5.76c0.29-1.98-5.21,1.72-5.83,0.29c-0.62-1.43-0.95,2.14-2.86,1.81c-1.65-0.29,0.86-2.61,0.86-2.61s-1.64,0.94-1.83-0.49c-0.19-1.4,1.99-1.65,2.97-2.03c2.8-1.1,8.64-5.44,11.61,3.25c0,0,2.96,1.59-0.22,7.77L221.88,76.81z"/>
                <path class="st10" d="M217.8,79.29c-3.15-0.24-2.23,1.54-3.72,1.42c-1.5-0.12-1.71,1.01-1.71,1.01s0.45,1.07,1.43,2.13c0.98,1.06,1.57,0.43,1.57,0.43c1.17,0.86,1.97-0.31,1.97-0.31c1.52,0.67,1.77-1.15,1.77-1.15C219.82,83.97,220.95,79.53,217.8,79.29z M217.97,81.54c-0.32,1.48-1.81,0.24-1.81,0.24l-0.79,0.32c0.55,1.58-1.46,0.57-1.64-0.11c-0.18-0.68,1.12-0.6,1.12-0.6c1.28-0.02,1.12-1.09,1.79-1.26C217.08,80.02,218.29,80.06,217.97,81.54z"/>
                <path class="st16" d="M224.41,74.16l0.94,1c0,0,2.72-8.95-3.55-12.47c-7.59-4.26-11.54,4.36-11.54,4.36l7.52,1.73l2.7,1.5l3.03,2.38L224.41,74.16z"/>
                <path class="st23" d="M224.41,74.16l0.94,1c0,0,2.72-8.95-3.55-12.47c-7.59-4.26-11.54,4.36-11.54,4.36l7.52,1.73l2.7,1.5l3.03,2.38L224.41,74.16z"/>
                <path class="st16" d="M222.79,70.41c0.73,0.72,1.94,0.11,1.77-0.92c-0.28-1.8-1.01-3.95-2.8-5.18c-3.17-2.17-4.86-0.59-5.64,0.76c-0.35,0.61-0.01,1.4,0.66,1.58C218.09,67.01,220.31,67.95,222.79,70.41z"/>
                <path class="st16" d="M224.41,74.16c0,0-6.21-8.47-14.28-4.37c0,0-4.99-2.51-2.09-3.51c5.24-1.82,11.14,2.93,11.14,2.93S224.58,72.03,224.41,74.16z"/>
                <path class="st9" d="M225.47,76.94c-0.5,1.16-1.49,1.96-2.45,2.3c-1.44,0.52-2.28-0.79-1.98-2.24c0.27-1.31,1.35-3.16,2.84-3.05C225.36,74.06,226.05,75.62,225.47,76.94z"/>
                <path class="st10" d="M215,81.93c-0.2,0.03-0.42,0.04-0.66,0.03c-0.06,0-0.11-0.06-0.11-0.12c0-0.06,0.05-0.12,0.12-0.11c1.71,0.09,2.57-1.11,2.57-1.12c0.04-0.05,0.11-0.07,0.16-0.03c0.05,0.04,0.06,0.11,0.03,0.16C217.08,80.78,216.39,81.75,215,81.93z"/>
                <path class="st24" d="M213.6,77.08c0,0-0.7,1.87-1.49,2.76c0.56,0.54,1.51,0.3,1.51,0.3L213.6,77.08z"/>
                <path class="st10" d="M216.7,75.84c0.03,0.38-0.15,0.71-0.39,0.72c-0.24,0.01-0.46-0.29-0.49-0.67c-0.03-0.38,0.15-0.71,0.39-0.72C216.46,75.16,216.68,75.46,216.7,75.84z"/>
                <path class="st10" d="M212.06,76.25c0.03,0.38-0.15,0.71-0.39,0.72c-0.24,0.01-0.46-0.29-0.49-0.67c-0.03-0.38,0.15-0.71,0.39-0.72C211.81,75.56,212.03,75.86,212.06,76.25z"/>
                <path class="st10" d="M217.32,73.64c0.03-0.01,0.06-0.04,0.09-0.07c0.08-0.1,0.06-0.24-0.04-0.32c-0.96-0.79-1.92-0.48-1.96-0.47c-0.12,0.04-0.18,0.16-0.14,0.28c0.04,0.12,0.16,0.18,0.28,0.15l0,0c0.03-0.01,0.79-0.24,1.55,0.39C217.17,73.66,217.25,73.67,217.32,73.64z"/>
                <path class="st10" d="M210.4,74.5c0.07,0,0.14-0.03,0.19-0.09c0.57-0.8,1.36-0.76,1.39-0.76c0.12,0.01,0.22-0.09,0.23-0.21c0.01-0.12-0.09-0.23-0.21-0.24c-0.04,0-1.06-0.06-1.77,0.94c-0.07,0.1-0.05,0.24,0.05,0.32C210.32,74.48,210.36,74.49,210.4,74.5z"/>
	        </g>
            <g id="headBoyCopybook1">
		        <path class="st9" d="M223.16,73.29c-0.58,4.69-0.69,7.47-3.15,9.71c-3.71,3.37-9.23,0.78-10.02-4.01c-0.71-4.31,0.35-11.26,4.96-12.79C219.48,64.7,223.74,68.6,223.16,73.29z"/>
                <path class="st10" d="M220.87,77.58c0,0-1.39,0.14-1.01-1.88c0.38-2.01-1.55-4.01-1-5.94c0.55-1.93-5.39,1.02-5.82-0.48c-0.43-1.5-1.23,1.99-3.07,1.42c-1.6-0.5,1.19-2.48,1.19-2.48s-1.75,0.72-1.75-0.72c0-1.42,2.19-1.37,3.21-1.62c2.92-0.72,9.28-4.26,11.08,4.74c0,0,2.73,1.96-1.24,7.68L220.87,77.58z"/>
                <path class="st10" d="M216.5,79.51c-3.09-0.65-2.41,1.24-3.88,0.92c-1.47-0.32-1.83,0.78-1.83,0.78s0.3,1.12,1.14,2.3c0.84,1.18,1.5,0.63,1.5,0.63c1.05,1.01,1.99-0.05,1.99-0.05c1.42,0.86,1.91-0.91,1.91-0.91C217.88,84.42,219.59,80.16,216.5,79.51z M216.38,81.76c-0.51,1.42-1.83,0-1.83,0l-0.82,0.22c0.34,1.63-1.53,0.38-1.61-0.32c-0.09-0.7,1.19-0.44,1.19-0.44c1.28,0.14,1.25-0.94,1.94-1.02C215.69,80.14,216.88,80.34,216.38,81.76z"/>
                <path class="st16" d="M223.73,75.29l0.8,1.12c0,0,3.87-8.52-1.89-12.83c-6.97-5.22-12.01,2.81-12.01,2.81l7.23,2.7l2.47,1.84l2.69,2.75L223.73,75.29z"/>
                <path class="st23" d="M223.73,75.29l0.8,1.12c0,0,3.87-8.52-1.89-12.83c-6.97-5.22-12.01,2.81-12.01,2.81l7.23,2.7l2.47,1.84l2.69,2.75L223.73,75.29z"/>
                <path class="st16" d="M222.61,71.36c0.63,0.81,1.91,0.36,1.88-0.68c-0.05-1.82-0.48-4.05-2.1-5.5c-2.86-2.56-4.74-1.22-5.69,0.02c-0.43,0.56-0.19,1.38,0.45,1.65C218.4,67.38,220.47,68.6,222.61,71.36z"/>
                <path class="st16" d="M223.73,75.29c0,0-5.04-9.21-13.58-6.2c0,0-4.61-3.14-1.62-3.76c5.43-1.11,10.66,4.37,10.66,4.37S224.17,73.2,223.73,75.29z"/>
                <path class="st9" d="M224.41,78.18c-0.65,1.08-1.73,1.74-2.73,1.96c-1.5,0.32-2.16-1.08-1.67-2.48c0.44-1.26,1.75-2.95,3.22-2.65C224.68,75.32,225.16,76.95,224.41,78.18z"/>
                <path class="st10" d="M213.37,81.76c-0.21,0-0.42-0.02-0.66-0.06c-0.06-0.01-0.1-0.07-0.09-0.14c0.01-0.06,0.07-0.11,0.13-0.1c1.69,0.31,2.69-0.76,2.7-0.77c0.04-0.05,0.12-0.05,0.16-0.01c0.05,0.04,0.05,0.12,0.01,0.17C215.58,80.9,214.78,81.76,213.37,81.76z"/>
                <path class="st24" d="M212.63,76.77c0,0-0.94,1.76-1.83,2.54c0.49,0.61,1.46,0.5,1.46,0.5L212.63,76.77z"/>
                <path class="st10" d="M215.86,75.95c-0.02,0.38-0.24,0.68-0.49,0.66c-0.24-0.02-0.42-0.34-0.4-0.73c0.02-0.38,0.24-0.68,0.49-0.66C215.71,75.24,215.89,75.56,215.86,75.95z"/>
                <path class="st10" d="M211.21,75.74c-0.02,0.38-0.24,0.68-0.49,0.66c-0.24-0.02-0.42-0.34-0.4-0.73c0.02-0.38,0.24-0.68,0.49-0.66C211.05,75.03,211.23,75.36,211.21,75.74z"/>
                <path class="st10" d="M216.76,73.84c0.04-0.01,0.07-0.03,0.1-0.06c0.09-0.09,0.09-0.23,0.01-0.32c-0.84-0.91-1.84-0.73-1.89-0.72c-0.12,0.02-0.2,0.14-0.17,0.26c0.02,0.12,0.14,0.2,0.26,0.18l0,0c0.03-0.01,0.81-0.14,1.49,0.59C216.61,73.84,216.69,73.86,216.76,73.84z"/>
                <path class="st10" d="M209.79,73.79c0.07,0.01,0.14-0.01,0.2-0.06c0.67-0.71,1.45-0.57,1.48-0.57c0.12,0.02,0.23-0.06,0.25-0.18c0.02-0.12-0.06-0.24-0.17-0.26c-0.04-0.01-1.04-0.2-1.88,0.7c-0.08,0.09-0.08,0.23,0.01,0.32C209.71,73.76,209.75,73.78,209.79,73.79z"/>
            </g>
        </g>
        <g>
	        <path class="st10" d="M137.67,227.42V131.2h-4.4v96.22h-17c-1.99,0-3.6,1.7-3.6,3.8l0,0h45.59l0,0c0-2.1-1.61-3.8-3.6-3.8H137.67z"/>
            <path class="st25" d="M137.67,227.42V131.2h-4.4v96.22h-17c-1.99,0-3.6,1.7-3.6,3.8l0,0h45.59l0,0c0-2.1-1.61-3.8-3.6-3.8H137.67z"/>
            <rect x="133.27" y="131.2" width="4.4" height="6.94"/>
            <rect x="91.69" y="67.44" class="st26" width="88.66" height="64.76"/>
            <rect x="91.69" y="67.44" class="st27" width="88.66" height="64.76"/>
            <g>
		        <rect x="88.39" y="65.7" class="st28" width="1.65" height="68.25"/>
                <rect x="177.76" y="68.27" class="st28" width="1.65" height="62.93"/>
	        </g>
            <g class="st13">
		        <rect x="88.39" y="65.7" class="st14" width="1.65" height="68.25"/>
                <rect x="177.76" y="68.27" class="st14" width="1.65" height="62.93"/>
	        </g>
            <g>
                <path class="st28" d="M182,133.94H90.04V65.7H182V133.94z M93.34,130.46h85.36V69.18H93.34V130.46z"/>
            </g>
            <g class="st15">
                <path class="st14" d="M182,133.94H90.04V65.7H182V133.94z M93.34,130.46h85.36V69.18H93.34V130.46z"/>
            </g>
            <path class="st16" d="M99.56,91.87l0.08,18.68l-2.38,0.01l0.04,9.26l0.39,0.05c1.66,0.22,2.95,1.6,3.16,3.31l-3.54,0.02l0.01,2.43l13.38-0.06l0.89,0l19.33-0.09l12.04-0.06l-0.06-15.07l-0.06-14.33l-24.68,0.11l-0.02-4.35L99.56,91.87z M131.8,124.53l-0.05-13.19l2.64-0.01l0.02,3.77l0.45,0c2.13-0.01,3.96-1.61,4.4-3.79l2.76-0.01l0.05,13.19L131.8,124.53z M111.58,124.63l-0.05-13.19l1.46-0.01l3.2-0.01l2.03-0.01l-0.06-14.33l23.79-0.11l0.06,13.39l-3.53,0.02l-0.05,0.41c-0.21,1.76-1.52,3.12-3.13,3.34l-0.02-3.74l-4.42,0.02l0.06,14.13L111.58,124.63z M113.13,110.49c0.37-1.52,1.57-2.66,3.04-2.86l0.01,2.85L113.13,110.49zM99.64,111.49l10.99-0.05l0.05,13.19l-12.49,0.06l0-0.55l3.56-0.02l0-0.47c-0.01-2.25-1.52-4.18-3.58-4.64l-0.03-7.51L99.64,111.49z M117.25,92.73l0.01,3.4l0.06,14.33l-0.25,0l-0.02-3.82l-0.45,0c-2.16,0.01-3.98,1.63-4.4,3.84l-0.68,0l-0.89,0l-10.1,0.05l-0.07-17.74L117.25,92.73z"/>
            <path class="st16" d="M173.84,79.59l-17.68,0.08l-0.01-2.52l-8.77,0.04l-0.05,0.41c-0.21,1.75-1.52,3.12-3.13,3.34l-0.02-3.74l-2.3,0.01l0.06,14.13l0,0.94l0.08,20.42l0.05,12.72l14.27-0.07l13.57-0.06l-0.11-26.07l4.11-0.02L173.84,79.59z M142.92,113.64l12.49-0.06l0.01,2.79l-3.57,0.02l0,0.47c0.01,2.25,1.53,4.18,3.59,4.64l0.01,2.92l-12.49,0.06L142.92,113.64z M142.83,92.28l12.49-0.06l0.01,1.54l0.01,3.38l0.01,2.14l13.57-0.06l0.1,25.13l-12.68,0.06l-0.02-3.73l-0.39-0.05c-1.66-0.22-2.96-1.6-3.16-3.31l3.54-0.02l-0.02-4.67l-13.38,0.06L142.83,92.28z M156.22,93.92c1.44,0.39,2.52,1.66,2.71,3.21l-2.7,0.01L156.22,93.92zM155.27,79.67l0.05,11.61l-12.49,0.06l-0.05-13.19l0.52,0l0.02,3.76l0.45,0c2.13-0.01,3.95-1.61,4.39-3.78l7.11-0.03L155.27,79.67z M173.03,98.27l-3.22,0.01l-13.57,0.06l0-0.26l3.62-0.02l0-0.47c-0.01-2.28-1.55-4.21-3.64-4.65l0-0.72l0-0.94l-0.04-10.67l16.79-0.08L173.03,98.27z"/>
            <g>
		        <ellipse class="st10" cx="112.67" cy="234.14" rx="3.06" ry="3.23"/>
                <ellipse class="st10" cx="158.26" cy="234.14" rx="3.06" ry="3.23"/>
	        </g>
            <path class="st10" d="M114.28,79.02h-12.95c-0.49,0-0.88-0.42-0.88-0.93l0,0c0-0.51,0.39-0.93,0.88-0.93h12.95c0.49,0,0.88,0.42,0.88,0.93l0,0C115.16,78.6,114.76,79.02,114.28,79.02z"/>
            <path class="st10" d="M110.78,82.02h-9.46c-0.49,0-0.88-0.42-0.88-0.93l0,0c0-0.51,0.39-0.93,0.88-0.93h9.46c0.49,0,0.88,0.42,0.88,0.93l0,0C111.66,81.61,111.27,82.02,110.78,82.02z"/>
            <path class="st10" d="M112.28,85.03h-10.95c-0.49,0-0.88-0.42-0.88-0.93l0,0c0-0.51,0.39-0.93,0.88-0.93h10.95c0.49,0,0.88,0.42,0.88,0.93l0,0C113.16,84.61,112.77,85.03,112.28,85.03z"/>
            <path class="st25" d="M114.28,79.02h-12.95c-0.49,0-0.88-0.42-0.88-0.93l0,0c0-0.51,0.39-0.93,0.88-0.93h12.95c0.49,0,0.88,0.42,0.88,0.93l0,0C115.16,78.6,114.76,79.02,114.28,79.02z"/>
            <path class="st25" d="M110.78,82.02h-9.46c-0.49,0-0.88-0.42-0.88-0.93l0,0c0-0.51,0.39-0.93,0.88-0.93h9.46c0.49,0,0.88,0.42,0.88,0.93l0,0C111.66,81.61,111.27,82.02,110.78,82.02z"/>
            <path class="st25" d="M112.28,85.03h-10.95c-0.49,0-0.88-0.42-0.88-0.93l0,0c0-0.51,0.39-0.93,0.88-0.93h10.95c0.49,0,0.88,0.42,0.88,0.93l0,0C113.16,84.61,112.77,85.03,112.28,85.03z"/>
            <g id="entrance">
                <g>
                    <path class="st29" d="M152.2,116.5c0.01,0.47,0.09,1.76,1,3c1.08,1.47,2.58,1.9,3,2c0-1.67,0-3.33,0-5C154.87,116.5,153.53,116.5,152.2,116.5z"/>
                    <rect x="155.5" y="116.88" class="st30" width="3" height="4.1"/>
                </g>
                <path class="st29" d="M117.08,107c-0.48,0.01-1.77,0.07-3.03,0.78c-1.49,0.84-1.92,2-2.02,2.33c1.68,0,3.37,0,5.05,0C117.08,109.07,117.08,108.04,117.08,107z"/>
                <path class="st29" d="M160,98c-0.01-0.47-0.09-1.76-1-3c-1.08-1.47-2.58-1.9-3-2c0,1.67,0,3.33,0,5C157.33,98,158.67,98,160,98z"/>
                <g>
			        <path class="st29" d="M143.31,81.79c0.47-0.04,1.75-0.21,2.93-1.19c1.4-1.17,1.72-2.7,1.8-3.12c-1.66,0.11-3.33,0.22-4.99,0.33C143.14,79.13,143.23,80.46,143.31,81.79z"/>
                    <rect x="143.92" y="74.79" transform="matrix(-0.0657 -0.9978 0.9978 -0.0657 78.298 226.9948)" class="st30" width="3" height="4.1"/>
		        </g>
                <g>
			        <path class="st29" d="M134.66,114.68c0.47-0.04,1.75-0.21,2.93-1.19c1.4-1.17,1.72-2.7,1.8-3.12c-1.66,0.11-3.33,0.22-4.99,0.33C134.48,112.02,134.57,113.35,134.66,114.68z"/>
                    <rect x="135.26" y="107.68" transform="matrix(-0.0657 -0.9978 0.9978 -0.0657 36.2482 253.4078)" class="st30" width="3" height="4.1"/>
                </g>
            </g>
        </g>
        <g>
            <g>
                <g>
                    <polygon class="st31" points="174.55,143.99 174.55,158.35 154.8,158.35 154.8,137.97"/>
                    <rect x="165.25" y="143.01" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 333.4209 289.3013)" class="st32" width="2.92" height="3.29"/>
                    <rect x="161.18" y="143.01" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 325.2736 289.3013)" class="st32" width="2.92" height="3.29"/>
                    <rect x="169.33" y="147.96" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 341.5681 299.2103)" class="st32" width="2.92" height="3.29"/>
                    <rect x="165.25" y="147.96" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 333.4209 299.2103)" class="st32" width="2.92" height="3.29"/>
                    <rect x="157.1" y="147.96" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 317.1263 299.2103)" class="st32" width="2.92" height="3.29"/>
                    <rect x="169.33" y="152.92" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 341.5681 309.1191)" class="st32" width="2.92" height="3.29"/>
                    <rect x="161.18" y="152.92" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 325.2736 309.1191)" class="st32" width="2.92" height="3.29"/>
                    <rect x="157.1" y="152.92" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 317.1263 309.1191)" class="st32" width="2.92" height="3.29"/>
                </g>
                <g>
                    <polygon class="st31" points="173.89,158.35 184.52,158.35 184.52,110.24 173.89,111.52"/>
                    <rect x="177.75" y="148.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 301.1556)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="152.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 308.4685)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="148.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 301.1556)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="152.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 308.4685)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="123.35" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 249.9657)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="127" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 257.2786)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="127" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 257.2786)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="127" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 257.2786)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="130.66" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 264.5914)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="130.66" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 264.5914)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="130.66" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 264.5914)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="134.32" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 271.9043)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="134.32" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 271.9043)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="134.32" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 271.9043)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="137.97" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 279.2171)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="137.97" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 279.2171)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="137.97" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 279.2171)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="116.03" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 235.3399)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="141.63" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 286.53)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="141.63" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 286.53)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="141.63" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 286.53)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="145.28" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 293.8428)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="145.28" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 293.8428)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="145.28" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 293.8428)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="152.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 308.4685)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="148.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 301.1556)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="112.38" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 228.0271)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="112.38" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 228.0271)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="112.38" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 228.0271)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="116.03" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 235.3399)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="116.03" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 235.3399)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="119.69" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 242.6529)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="119.69" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 242.6529)" class="st32" width="2.91" height="3.27"/>
                    <rect x="174.46" y="119.69" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 351.8267 242.6529)" class="st32" width="2.91" height="3.27"/>
                    <rect x="181.04" y="123.35" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 364.9924 249.9657)" class="st32" width="2.91" height="3.27"/>
                    <rect x="177.75" y="123.35" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 358.4095 249.9657)" class="st32" width="2.91" height="3.27"/>
                </g>
                <g>
                    <polygon class="st31" points="184.52,158.35 193.13,158.35 193.13,119.9 184.52,120.94"/>
                    <rect x="190.31" y="121.63" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 245.9031)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="124.59" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 251.8227)" class="st12" width="2.35" height="2.65"/>
                    <rect x="187.65" y="124.59" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 377.6536 251.8227)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="127.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 257.7425)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="127.55" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 257.7425)" class="st12" width="2.35" height="2.65"/>
                    <rect x="187.65" y="130.51" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 377.6536 263.6621)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="130.51" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 263.6621)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="133.47" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 269.5818)" class="st12" width="2.35" height="2.65"/>
                    <rect x="187.65" y="133.47" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 377.6536 269.5818)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="136.43" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 275.5015)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="136.43" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 275.5015)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="139.39" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 281.4212)" class="st12" width="2.35" height="2.65"/>
                    <rect x="187.65" y="139.39" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 377.6536 281.4212)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="139.39" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 281.4212)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="142.35" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 287.3409)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="142.35" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 287.3409)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="145.31" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 293.2605)" class="st12" width="2.35" height="2.65"/>
                    <rect x="187.65" y="145.31" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 377.6536 293.2605)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="145.31" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 293.2605)" class="st12" width="2.35" height="2.65"/>
                    <rect x="187.65" y="148.27" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 377.6536 299.1802)" class="st12" width="2.35" height="2.65"/>
                    <rect x="190.31" y="151.22" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 382.9825 305.0999)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="151.22" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 305.0999)" class="st12" width="2.35" height="2.65"/>
                    <rect x="187.65" y="154.18" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 377.6536 311.0196)" class="st12" width="2.35" height="2.65"/>
                    <rect x="184.99" y="154.18" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 372.3249 311.0196)" class="st12" width="2.35" height="2.65"/>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <rect x="220.18" y="160.87" class="st10" width="2.9" height="75.51"/>
                        <rect x="256.12" y="160.87" class="st10" width="2.9" height="75.51"/>
                        <rect x="146.65" y="160.87" class="st10" width="2.9" height="75.51"/>
                        <rect x="182.59" y="160.87" class="st10" width="2.9" height="75.51"/>
                    </g>
                    <g class="st15">
                        <rect x="220.18" y="160.87" class="st14" width="2.9" height="75.51"/>
                        <rect x="256.12" y="160.87" class="st14" width="2.9" height="75.51"/>
                        <rect x="146.65" y="160.87" class="st14" width="2.9" height="75.51"/>
                        <rect x="182.59" y="160.87" class="st14" width="2.9" height="75.51"/>
                    </g>
                </g>
                <g>
			        <rect x="142.56" y="158.05" class="st10" width="119.99" height="3.44"/>
                    <rect x="218.21" y="158.05" class="st10" width="44.35" height="3.44"/>
		        </g>
                <rect x="142.56" y="158.05" class="st25" width="119.99" height="3.44"/>
                <rect x="218.21" y="158.05" class="st19" width="44.35" height="3.44"/>
	        </g>
            <g>
		        <path class="st33" d="M199.25,157.97c-0.78,0-1.41-1.41-1.41-3.14c0-1.73,0.63-3.14,1.41-3.14h35.12c0.78,0,1.41,1.41,1.41,3.14c0,1.73-0.63,3.14-1.41,3.14H199.25z"/>
                <path class="st12" d="M199.25,157.97c-0.78,0-1.41-1.41-1.41-3.14c0-1.73,0.63-3.14,1.41-3.14h35.12c0.78,0,1.41,1.41,1.41,3.14c0,1.73-0.63,3.14-1.41,3.14H199.25z"/>
                <path class="st33" d="M200.67,154.83c0,1.73-0.63,3.14-1.41,3.14c-0.78,0-1.41-1.41-1.41-3.14c0-1.73,0.63-3.14,1.41-3.14C200.03,151.7,200.67,153.1,200.67,154.83z"/>
                <ellipse class="st13" cx="199.68" cy="155.11" rx="0.91" ry="2.03"/>
                <path class="st14" d="M199.25,157.97c0,0,1.46,0.44,2.67-3.14c0,0,7.63-0.8,16.13,1.17c8.5,1.97,11.63-1.11,18.53-1.17c0,0-0.41,3.14-2.21,3.14H199.25z"/>
                <path class="st25" d="M199.25,157.97c0,0,1.46,0.44,2.67-3.14c0,0,7.63-0.8,16.13,1.17c8.5,1.97,11.63-1.11,18.53-1.17c0,0-0.41,3.14-2.21,3.14H199.25z"/>
	        </g>
            <g>
		        <path class="st14" d="M212.07,153.42c-0.78,0-1.41-1.41-1.41-3.14s0.63-3.14,1.41-3.14h35.12c0.78,0,1.41,1.41,1.41,3.14s-0.63,3.14-1.41,3.14H212.07z"/>
                <path class="st12" d="M212.07,153.42c-0.78,0-1.41-1.41-1.41-3.14s0.63-3.14,1.41-3.14h35.12c0.78,0,1.41,1.41,1.41,3.14s-0.63,3.14-1.41,3.14H212.07z"/>
                <path class="st14" d="M213.49,150.29c0,1.73-0.63,3.14-1.41,3.14c-0.78,0-1.41-1.41-1.41-3.14s0.63-3.14,1.41-3.14C212.85,147.15,213.49,148.55,213.49,150.29z"/>
                <path class="st13" d="M213.42,150.57c0,1.12-0.41,2.03-0.91,2.03c-0.5,0-0.91-0.91-0.91-2.03c0-1.12,0.41-2.03,0.91-2.03C213.01,148.54,213.42,149.45,213.42,150.57z"/>
                <path class="st33" d="M212.07,153.42c0,0,1.46,0.44,2.67-3.14c0,0,7.63-0.8,16.13,1.17c8.5,1.97,11.63-1.11,18.53-1.17c0,0-0.41,3.14-2.21,3.14H212.07z"/>
                <path class="st25" d="M212.07,153.42c0,0,1.46,0.44,2.67-3.14c0,0,7.63-0.8,16.13,1.17c8.5,1.97,11.63-1.11,18.53-1.17c0,0-0.41,3.14-2.21,3.14H212.07z"/>
	        </g>
            <g>
		        <path class="st33" d="M216.37,157.97c-0.78,0-1.41-1.41-1.41-3.14c0-1.73,0.63-3.14,1.41-3.14h35.12c0.78,0,1.41,1.41,1.41,3.14c0,1.73-0.63,3.14-1.41,3.14H216.37z"/>
                <path class="st12" d="M216.37,157.97c-0.78,0-1.41-1.41-1.41-3.14c0-1.73,0.63-3.14,1.41-3.14h35.12c0.78,0,1.41,1.41,1.41,3.14c0,1.73-0.63,3.14-1.41,3.14H216.37z"/>
                <ellipse class="st14" cx="216.37" cy="154.83" rx="1.41" ry="3.14"/>
                <ellipse class="st13" cx="216.8" cy="155.11" rx="0.91" ry="2.03"/>
                <path class="st14" d="M216.37,157.97c0,0,1.46,0.44,2.67-3.14c0,0,7.63-0.8,16.13,1.17c8.5,1.97,11.63-1.11,18.53-1.17c0,0-0.41,3.14-2.21,3.14H216.37z"/>
                <path class="st25" d="M216.37,157.97c0,0,1.46,0.44,2.67-3.14c0,0,7.63-0.8,16.13,1.17c8.5,1.97,11.63-1.11,18.53-1.17c0,0-0.41,3.14-2.21,3.14H216.37z"/>
            </g>
        </g>
        <g>
	        <path class="st34" d="M95.24,230.82c-0.88,0.53-2.52,0.6-4.31,0.03c0,0-0.34-1.75-0.8-4.65l4.78-0.12L95.24,230.82z"/>
            <path class="st10" d="M99.95,235.21c-3.84,0-3.56-0.06-5.72-0.13c-1.85-0.07-2.27,0.17-3.76,0.13c-1.24-0.03-1.28-1.24-1-2.64c0.11-0.54,0.27-1.16,0.42-1.93c0.09-0.45,0.46-0.76,0.91-0.76h4.49c0.33,0,0.65,0.16,0.85,0.44c0.94,1.32,2.49,2.3,4.46,2.56C102.68,233.17,101.48,235.21,99.95,235.21z"/>
            <g id="handGirlDesing2">
		        <path class="st35" d="M116.99,112.11l-1.6,0.07c-0.6,0.03-1.15,0.35-1.48,0.87l-1.52,2.42c-0.61,0.97-0.72,2.18-0.3,3.24l0.26,0.66c2.66,0.06,5.57-1.87,5.57-1.87l0.89-0.46c0.79-0.41,1.11-1.33,0.74-2.12l-0.87-1.82C118.38,112.47,117.71,112.08,116.99,112.11z"/>
                <path class="st35" d="M94.76,102.77c0.62,2.5,1.34,5.11,2.13,7.58c0.4,1.24,0.85,2.44,1.33,3.59c0.47,1.14,1.03,2.24,1.59,3.01c0.26,0.38,0.56,0.64,0.58,0.66c-0.06,0.02,0.38,0.16,0.86,0.17c1.01,0.05,2.27-0.1,3.51-0.3c2.5-0.4,5.09-1.04,7.63-1.67l0.04-0.01c0.75-0.18,1.5,0.29,1.67,1.05c0.15,0.64-0.15,1.28-0.69,1.58c-2.45,1.37-4.95,2.48-7.66,3.37c-1.37,0.43-2.76,0.81-4.42,0.93c-0.84,0.04-1.78,0.07-3.07-0.45c-1.19-0.57-1.87-1.3-2.46-1.97c-1.13-1.36-1.82-2.7-2.49-4.04c-0.65-1.34-1.16-2.69-1.65-4.03c-0.97-2.7-1.73-5.37-2.34-8.2c-0.34-1.54,0.61-3.07,2.12-3.42c1.48-0.34,2.94,0.58,3.31,2.08L94.76,102.77z"/>
                <path class="st36" d="M89.79,98.4c2.31-0.5,4.56,0.75,6.39,8.15c1.88,7.59,4.42,10.6,4.42,10.6s0.42,0.91,7.22-0.49l1.11,4.14c0,0-7.8,4.14-11.64,1.44c-3.84-2.7-6.45-8.97-7.83-12.76C88.08,105.69,85.8,99.26,89.79,98.4z"/>
	        </g>
            <g id="handGirlDesing1">
		        <path class="st35" d="M116.96,110.21l-1.58,0.26c-0.59,0.1-1.1,0.48-1.37,1.03l-1.23,2.58c-0.49,1.03-0.47,2.24,0.07,3.26l0.33,0.63c2.65-0.24,5.32-2.5,5.32-2.5l0.83-0.56c0.74-0.5,0.95-1.45,0.49-2.19l-1.07-1.71C118.38,110.41,117.67,110.1,116.96,110.21z"/>
                <path class="st35" d="M93.8,103.49c0.9,2.41,1.92,4.92,2.99,7.28c0.54,1.18,1.13,2.33,1.73,3.41c0.6,1.07,1.28,2.1,1.92,2.81c0.3,0.35,0.63,0.57,0.65,0.59c-0.06,0.02,0.39,0.11,0.88,0.07c1.01-0.06,2.25-0.36,3.45-0.7c2.43-0.68,4.93-1.62,7.39-2.53l0.04-0.02c0.72-0.27,1.52,0.11,1.78,0.85c0.22,0.62,0,1.29-0.51,1.65c-2.28,1.64-4.63,3.03-7.23,4.23c-1.31,0.58-2.65,1.12-4.29,1.43c-0.83,0.13-1.76,0.28-3.1-0.09c-1.25-0.43-2.01-1.08-2.67-1.67c-1.28-1.22-2.12-2.48-2.93-3.73c-0.8-1.26-1.46-2.54-2.1-3.82c-1.28-2.57-2.34-5.13-3.27-7.87c-0.51-1.49,0.26-3.12,1.71-3.64c1.43-0.51,2.99,0.24,3.53,1.68L93.8,103.49z"/>
                <path class="st36" d="M88.36,99.72c2.23-0.76,4.61,0.22,7.29,7.36c2.74,7.33,5.61,10.02,5.61,10.02s0.52,0.85,7.11-1.32l1.58,3.99c0,0-7.27,5.01-11.39,2.77c-4.13-2.24-7.44-8.16-9.25-11.77C87.5,107.16,84.5,101.03,88.36,99.72z"/>
	        </g>
            <path class="st10" d="M72.91,84.36c0,0-0.17,1.24,0.08,2.49c0.22,1.12,0.13,2.26-0.29,3.32c-0.37,0.93-0.71,2.1-0.64,3.21c0.06,0.94-0.08,1.88-0.5,2.72c-0.65,1.32-1.24,3.4,0.14,5.17c2.24,2.87,17.71-0.54,17.71-0.54s0.88-3.35-0.78-5.23c-0.66-0.75-1.06-1.71-1-2.72c0.04-0.77-0.03-1.65-0.43-2.39c-0.49-0.92-0.51-2.02-0.26-3.03c0.45-1.79,0.44-4.84-2.17-9.15c0,0-3.95-6.73-8.15-3.48C72.42,77.97,72.91,84.36,72.91,84.36z"/>
            <path class="st10" d="M89.83,226.63c-2.76-9.5-7.67-25.76-5.27-45.82c-9.42-28.65-7.36-51.72-7.36-51.72l15.07,0.55c0,0,3.3,33.02,1.95,50.61c0.19,13.77,0.45,35.64,1.14,46.37L89.83,226.63z"/>
            <path class="st11" d="M82.57,174.25c4.26-17.39,0.89-25.65,0.89-25.65L79,138.04l-2.56,4.61C77.06,148.58,80.04,164.85,82.57,174.25z"/>
            <path class="st34" d="M70.42,226.07c-0.04,1.8,0,3.4,0.11,4.74c-0.88,0.53-2.52,0.6-4.31,0.03c0,0-0.34-1.75-0.8-4.65L70.42,226.07z"/>
            <path class="st10" d="M75.23,235.21c-3.84,0-3.56-0.06-5.72-0.13c-1.85-0.07-2.27,0.17-3.76,0.13c-1.24-0.03-1.28-1.24-1-2.64c0.11-0.54,0.27-1.16,0.42-1.93c0.09-0.45,0.46-0.76,0.91-0.76h4.49c0.33,0,0.65,0.16,0.85,0.44c0.94,1.32,2.49,2.3,4.46,2.56C77.96,233.17,76.76,235.21,75.23,235.21z"/>
            <path class="st10" d="M65.14,226.88c-1.76-22.59-1.94-34.17,3.62-47.67c-3.12-38.3,4-49.22,4-49.22h13.76c0,0-4.58,35.21-7.72,50.61c-2.89,14.15-5.96,31.71-7.98,46.16L65.14,226.88z"/>
            <path class="st37" d="M94.63,105.69c0.06,2.25,0.15,5.11-0.14,10.49c-0.16,4.56,0.51,17.53-2.23,18.35c-2.12,1.46-12.85,0.93-19.71-0.12c-3.26-1.13,2.27-11.2-3.79-30.08c-1.06-3.29,1.06-6.61,4.13-6.95c0.82-0.09,1.69-0.17,2.58-0.22c3.47-0.19,6.32-0.31,9.4,0c1.02,0.1,2.08,0.25,3.09,0.4C91.63,98.13,94.52,101.65,94.63,105.69z"/>
            <path class="st23" d="M94.63,105.69c0.06,2.25,0.15,5.11-0.14,10.49c-0.16,4.56,0.51,17.53-2.23,18.35c-2.12,1.46-12.85,0.93-19.71-0.12c-3.26-1.13,2.27-11.2-3.79-30.08c-1.06-3.29,1.06-6.61,4.13-6.95c0.82-0.09,1.69-0.17,2.58-0.22c3.47-0.19,6.32-0.31,9.4,0c1.02,0.1,2.08,0.25,3.09,0.4C91.63,98.13,94.52,101.65,94.63,105.69z"/>
            <path class="st13" d="M73.56,107.72c0.34,3.63-1.21,7.27-1.89,8.66c-0.13-1.84-0.4-3.86-0.87-6.08L73.56,107.72z"/>
            <path class="st35" d="M83.73,99.3c4.63-0.33,1.12-2.14,1.12-2.14c-3.09-0.74-3.18-3.05-2.81-5.21l-5.52-4.54c0.74,2.79,1.64,7.9-0.13,9.76c0,0-1.27,2.53,5.92,2.22C82.65,99.37,83.37,99.33,83.73,99.3z"/>
            <path class="st13" d="M78.76,89.26l3.27,2.69c-0.09,0.51-0.15,1.03-0.15,1.54c-1.17-0.17-2.86-1.48-3.07-2.72C78.7,90.14,78.68,89.55,78.76,89.26z"/>
            <g id="headGirlDesign2">
		        <path class="st35" d="M74.18,81.81c0.83,4.5,1.08,6.42,3.59,8.58c3.77,3.25,8.96,1.53,9.45-3.06c0.45-4.13-0.98-10.8-5.51-12.28C77.25,73.58,73.36,77.31,74.18,81.81z"/>
                <g>
			        <path class="st16" d="M73.61,84.87l-0.74,1.03c0,0-3.58-7.84,1.75-11.82c6.45-4.81,11.12,2.59,11.12,2.59l-6.7,2.49l-2.29,1.7l-2.49,2.54L73.61,84.87z"/>
                    <path class="st23" d="M73.61,84.87l-0.74,1.03c0,0-3.58-7.84,1.75-11.82c6.45-4.81,11.12,2.59,11.12,2.59l-6.7,2.49l-2.29,1.7l-2.49,2.54L73.61,84.87z"/>
                    <path class="st16" d="M74.65,81.25c-0.58,0.75-1.76,0.33-1.74-0.63c0.04-1.68,0.45-3.73,1.94-5.06c2.65-2.36,4.39-1.12,5.27,0.02c0.4,0.51,0.18,1.27-0.42,1.52C78.55,77.58,76.63,78.7,74.65,81.25z"/>
                    <path class="st16" d="M73.61,84.87c0,0,4.67-8.48,12.58-5.71c0,0,4.27-2.89,1.5-3.46c-5.03-1.03-9.87,4.02-9.87,4.02S73.2,82.95,73.61,84.87z"/>
                    <path class="st16" d="M83.91,91.73c-5.5,1.35-8.52-4.45-9.34-9.31c-0.08-0.47-0.78-0.27-0.7,0.2c0.7,4.16,2.9,9.16,6.8,9.83C81.4,92.58,82.48,92.37,83.91,91.73z"/>
		        </g>
                <path class="st10" d="M81.15,82.56c0.05,0.38,0.29,0.68,0.53,0.66c0.24-0.02,0.39-0.34,0.33-0.72c-0.05-0.38-0.29-0.68-0.53-0.66C81.24,81.86,81.09,82.18,81.15,82.56z"/>
                <path class="st10" d="M85.87,81.6l0.84-0.33C86.71,81.27,86.36,82.01,85.87,81.6z"/>
                <path class="st10" d="M85.64,82.29c0.05,0.38,0.29,0.68,0.53,0.66c0.24-0.02,0.39-0.34,0.33-0.72c-0.05-0.38-0.29-0.68-0.53-0.66C85.73,81.58,85.58,81.91,85.64,82.29z"/>
                <path class="st10" d="M79.89,80.51c0.06,0,0.11-0.02,0.15-0.07c0.62-0.71,1.41-0.56,1.42-0.56c0.12,0.02,0.23-0.05,0.24-0.18c0.01-0.12-0.07-0.24-0.19-0.26c-0.04-0.01-1.03-0.2-1.81,0.69c-0.08,0.09-0.07,0.23,0.03,0.32C79.78,80.49,79.84,80.51,79.89,80.51z"/>
                <path class="st10" d="M86.65,80.1c0.05,0,0.11-0.01,0.15-0.05c0.09-0.08,0.09-0.22,0.01-0.31c-0.83-0.95-1.82-0.85-1.86-0.85c-0.12,0.01-0.2,0.12-0.17,0.25c0.02,0.12,0.14,0.21,0.25,0.2c0.04,0,0.81-0.07,1.47,0.69C86.53,80.07,86.59,80.1,86.65,80.1z"/>
                <path class="st38" d="M84.48,83.01c0,0,0.85,1.59,1.64,2.33c-0.42,0.51-1.26,0.35-1.26,0.35L84.48,83.01z"/>
                <path class="st10" d="M83.63,87.6c0.19,0.02,0.4,0.03,0.62,0.01c0.06,0,0.1-0.06,0.09-0.12c-0.01-0.06-0.06-0.11-0.12-0.1c-1.6,0.12-2.51-0.99-2.52-1c-0.04-0.05-0.11-0.06-0.15-0.02c-0.04,0.04-0.05,0.11-0.01,0.16C81.57,86.57,82.3,87.46,83.63,87.6z"/>
                <path class="st39" d="M73.76,86.15c0.45,0.92,1.19,1.49,1.87,1.68c1.02,0.28,1.47-0.92,1.13-2.12c-0.31-1.08-1.21-2.53-2.21-2.26C73.56,83.7,73.24,85.09,73.76,86.15z"/>
                <path class="st10" d="M81.38,81.87l0.84-0.33C82.22,81.54,81.87,82.28,81.38,81.87z"/>
                <polygon class="st10" points="76.03,87.75 77.82,79.72 74.57,82.43 74.57,84.13"/>
	        </g>
            <g id="headGirlDesign1">
		        <path class="st35" d="M74.72,81.49c0.32,4.57,0.35,6.5,2.6,8.93c3.38,3.65,8.73,2.53,9.74-1.98c0.91-4.05,0.24-10.84-4.09-12.83C78.7,73.65,74.41,76.92,74.72,81.49z"/>
                <g>
                    <path class="st16" d="M73.81,84.46l-0.85,0.94c0,0-2.68-8.2,3.06-11.54c6.95-4.05,10.76,3.82,10.76,3.82l-6.94,1.72l-2.47,1.43l-2.76,2.24L73.81,84.46z"/>
                    <path class="st23" d="M73.81,84.46l-0.85,0.94c0,0-2.68-8.2,3.06-11.54c6.95-4.05,10.76,3.82,10.76,3.82l-6.94,1.72l-2.47,1.43l-2.76,2.24L73.81,84.46z"/>
                    <path class="st16" d="M75.25,80.98c-0.66,0.68-1.79,0.13-1.66-0.82c0.23-1.66,0.86-3.66,2.5-4.81c2.9-2.05,4.48-0.62,5.24,0.61c0.34,0.56,0.03,1.29-0.58,1.47C79.54,77.78,77.5,78.68,75.25,80.98z"/>
                    <path class="st16" d="M73.81,84.46c0,0,5.59-7.9,13.14-4.26c0,0,4.57-2.39,1.87-3.27c-4.88-1.59-10.26,2.89-10.26,2.89S73.62,82.51,73.81,84.46z"/>
                    <path class="st16" d="M83.28,92.44c-5.61,0.72-7.97-5.38-8.24-10.3c-0.03-0.48-0.75-0.36-0.72,0.12c0.23,4.22,1.85,9.43,5.65,10.53C80.68,93,81.78,92.91,83.28,92.44z"/>
		        </g>
                <path class="st10" d="M81.56,83.01c0.01,0.38,0.21,0.7,0.45,0.71c0.24,0.01,0.42-0.3,0.41-0.68c-0.01-0.38-0.21-0.7-0.45-0.71C81.73,82.32,81.55,82.63,81.56,83.01z"/>
                <path class="st10" d="M86.36,82.59l0.87-0.23C87.23,82.35,86.8,83.06,86.36,82.59z"/>
                <path class="st10" d="M86.05,83.25c0.01,0.38,0.21,0.7,0.45,0.71c0.24,0.01,0.42-0.3,0.41-0.68c-0.01-0.38-0.21-0.7-0.45-0.71C86.22,82.56,86.04,82.86,86.05,83.25z"/>
                <path class="st10" d="M80.54,80.84c0.06,0.01,0.11-0.01,0.16-0.05c0.7-0.64,1.47-0.4,1.48-0.4c0.11,0.04,0.23-0.03,0.26-0.15c0.03-0.12-0.04-0.25-0.16-0.28c-0.04-0.01-1-0.32-1.87,0.48c-0.09,0.08-0.09,0.22-0.01,0.32C80.44,80.8,80.49,80.83,80.54,80.84z"/>
                <path class="st10" d="M87.3,81.19c0.05,0.01,0.11,0,0.15-0.03c0.1-0.07,0.11-0.21,0.04-0.31c-0.72-1.04-1.71-1.05-1.75-1.05c-0.12,0-0.21,0.1-0.2,0.22c0.01,0.12,0.11,0.22,0.23,0.22c0.04,0,0.81,0.02,1.38,0.85C87.19,81.14,87.24,81.18,87.3,81.19z"/>
                <path class="st38" d="M84.81,83.83c0,0,0.66,1.68,1.37,2.5c-0.47,0.46-1.29,0.21-1.29,0.21L84.81,83.83z"/>
                <path class="st10" d="M83.46,88.3c0.19,0.04,0.39,0.07,0.61,0.08c0.06,0,0.11-0.05,0.1-0.11c0-0.06-0.05-0.11-0.11-0.12c-1.6-0.06-2.38-1.27-2.39-1.28c-0.03-0.05-0.1-0.07-0.15-0.04c-0.05,0.03-0.06,0.1-0.03,0.15C81.53,87.04,82.15,88.02,83.46,88.3z"/>
                <path class="st39" d="M73.81,85.75c0.34,0.97,1.01,1.62,1.67,1.88c0.99,0.39,1.56-0.75,1.36-1.98c-0.18-1.11-0.92-2.64-1.94-2.5C73.89,83.29,73.42,84.64,73.81,85.75z"/>
                <path class="st10" d="M81.87,82.35l0.87-0.23C82.74,82.12,82.3,82.82,81.87,82.35z"/>
                <polygon class="st10" points="75.89,87.6 78.57,79.82 75.04,82.14 74.85,83.84"/>
	        </g>
            <path class="st36" d="M76,97.15l6.82,20.49c0,0-1.65,44.97-12.96,43.25l-5.03-2.07c0,0-1.5-8.09,6.38-29.08c0,0,1.15-10.17-1.8-21.12C66.46,97.65,70.55,96.82,76,97.15z"/>
            <path class="st13" d="M81,117.31c-0.61,0.04-1.16,0.39-1.48,0.92l-1.49,2.49c-0.12,0.2-0.21,0.41-0.29,0.63c-0.95,0.03-1.89,0.04-2.83,0.04l-0.08-0.44c-1.43-0.04-2.56-0.1-3.45-0.16c-0.02,0.72-0.05,1.4-0.1,2.05c0.06,1.24,0.07,2.38,0.07,3.4c2.38-0.15,4.29-0.69,4.29-0.69l-0.05-0.28c0.96-0.18,1.9-0.37,2.84-0.61c1.84-0.12,3.71-1.09,4.63-1.64c0.18-2.71,0.24-4.38,0.24-4.38l-0.48-1.43c-0.07,0-0.15-0.01-0.22-0.01L81,117.31z"/>
            <path class="st13" d="M71.81,98.66c-1.07-0.41-1.88-0.07-2.7,0.62c-0.94,1.67-1,4.52,0.3,9.33c0.32,1.18,0.58,2.34,0.81,3.49C73.64,105.63,76.75,100.56,71.81,98.66z"/>
            <path class="st35" d="M72.74,104.63c-1.36,2.24-2.72,4.65-3.9,7c-0.59,1.18-1.11,2.39-1.56,3.55c-0.44,1.14-0.77,2.37-0.81,3.16c-0.04,0.38,0.09,0.55-0.11,0.31c-0.14-0.08,0.18,0.21,0.62,0.38c0.91,0.37,2.18,0.61,3.44,0.77c2.54,0.34,5.28,0.41,7.94,0.47l0.05,0c0.77,0.02,1.37,0.67,1.35,1.45c-0.02,0.66-0.47,1.21-1.07,1.35c-2.79,0.67-5.55,1.05-8.49,1.13c-1.48,0.01-2.96-0.03-4.68-0.49c-0.85-0.27-1.84-0.55-2.92-1.69c-0.56-0.64-0.86-1.33-1.03-1.94c-0.17-0.62-0.23-1.18-0.25-1.7c-0.03-1.03,0.1-1.9,0.27-2.72c0.17-0.82,0.38-1.59,0.62-2.33c0.47-1.48,1.06-2.85,1.67-4.19c1.24-2.69,2.61-5.17,4.21-7.65c0.85-1.31,2.57-1.68,3.86-0.81c1.26,0.85,1.63,2.57,0.83,3.88L72.74,104.63z"/>
            <path class="st35" d="M82.93,116.43l-1.62,0.08c-0.61,0.03-1.17,0.36-1.49,0.88l-1.54,2.45c-0.61,0.98-0.73,2.21-0.3,3.29l0.26,0.67c2.69,0.06,5.64-1.92,5.64-1.92l0.9-0.47c0.8-0.42,1.13-1.35,0.74-2.15l-0.88-1.84C84.34,116.79,83.66,116.39,82.93,116.43z"/>
            <path class="st36" d="M73.47,124.73c0,0-13.71,3.78-13.91-3.19c-0.18-6.51,6.3-25.5,13.91-23.9c6.67,1.4-3.45,9.41-6.64,19.38c-0.36,1.13,0.33,2.32,1.47,2.51c1,0.17,2.47,0.36,4.64,0.56L73.47,124.73z"/>
            <path class="st13" d="M94.99,108.76l-1.6-2.68l-2.41,1.88v5.57c1.72,3.3,4.1,6.99,7.02,8.76C95.94,121.17,94.99,108.76,94.99,108.76z"/>
            <path class="st36" d="M90.22,117.3c0,0-0.59,44.79,5.9,42.8l1.85-0.6c-2.13-21.22-3.43-28.28-3.43-37.75c0-3.77,0.71-10.81,0.63-15.07c-0.07-3.25-0.37-9.91-9.96-9.59C85.2,97.09,88.7,98.57,90.22,117.3z"/>
            <polygon class="st40" points="75.25,97.99 75.19,104.07 77.19,104.68 75.43,106.62 80.37,112.54 76.29,106.62 78.06,104.37 75.79,103.64"/>
            <polygon class="st40" points="86.7,97.64 91.08,103.09 89.73,104.16 92.08,104.77 90.01,111.86 91.32,105.2 88.75,104.37 90.35,102.99"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "ServicesSvg"
    }
</script>

<style scoped lang="scss">
    #ServicesSvg{
        .st0{fill:#D1EBFF;}
        .st1{fill:#BBBBBC;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st2{fill:#A7A7AA;}
        .st3{fill:#E1E1E1;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st4{fill:#E7E7E7;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st5{fill:#F0F0F0;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st6{fill:#ECECEC;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st7{fill:#F5F5F5;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st8{fill:#FAFAFA;stroke:#8E8E8E;stroke-width:0.25;stroke-miterlimit:10;}
        .st9{fill:#F9C0BB;}
        .st10{fill:#1A2E35;}
        .st11{opacity:0.4;}
        .st12{opacity:0.8;fill:#FFFFFF;}
        .st13{opacity:0.2;}
        .st14{fill:#FFFFFF;}
        .st15{opacity:0.5;}
        .st16{fill:#FFAC1A;}
        .st17{opacity:0.7;fill:#FFFFFF;}
        .st18{opacity:0.8;}
        .st19{opacity:0.2;fill:#FFFFFF;}
        .st20{opacity:0.4;fill:#FFFFFF;}
        .st21{fill:#007CFF;}
        .st22{display:none;opacity:0.5;fill:#FFFFFF;}
        .st23{opacity:0.3;fill:#FFFFFF;}
        .st24{fill:#DDA09E;}
        .st25{opacity:0.5;fill:#FFFFFF;}
        .st26{fill:#CCCCCC;}
        .st27{opacity:0.8;fill:#EEEEEE;}
        .st28{fill:#5B5B5B;}
        .st29{fill:#E7E7E7;stroke:#2872A0;stroke-width:0.6;stroke-miterlimit:10;}
        .st30{fill:#E7E7E7;}
        .st31{fill:#7E7E7E;}
        .st32{opacity:0.9;fill:#FFFFFF;}
        .st33{fill:#BBBBBB;}
        .st34{fill:#F0877C;}
        .st35{fill:#FCB373;}
        .st36{fill:#949DA2;}
        .st37{fill:#EEEEEE;}
        .st38{fill:#DD776F;}
        .st39{fill:#FFB570;}
        .st40{opacity:0.3;}
    }
</style>