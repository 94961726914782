<template>
    <svg version="1.1" id="ViewSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
        <path class="st0" d="M28.87,18.94c-7.91,10.3-17.48,10.6-26.24,1.64c-0.79-0.81-0.84-2.2-0.11-3.08c9.33-11.21,18.3-8.89,26.33,0.61C29.04,18.34,29.05,18.7,28.87,18.94z"/>
        <path class="st1" d="M28.17,18.75C20.53,29.04,10.99,28.58,3,19.94c-0.44-0.48-0.42-1.29,0.05-1.74c9.85-9.29,16.29-7.99,25.08,0.1C28.25,18.42,28.27,18.62,28.17,18.75z"/>
        <ellipse class="st2" cx="15.44" cy="18.9" rx="6.45" ry="7.03"/>
        <ellipse class="st0" cx="15.25" cy="18.88" rx="3.65" ry="3.98"/>
    </svg>
</template>

<script>
    export default {
        name: "ViewSvg"
    }
</script>

<style scoped lang="scss">
    #ViewSvg{
        .st0{fill:#007CFF;}
        .st1{fill:#FFFFFF;}
        .st2{fill:#66B0FF;}
    }
</style>