<template>
	<svg version="1.1" id="CallSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
		<g>
			<g>
				<g>
					<path class="st0" d="M18.52,21.33l-0.79,0.79c-0.47,0.47-1.23,0.47-1.7,0l-7.73-7.73c-0.47-0.47-0.47-1.23,0-1.7l0.79-0.79L4.61,7.41L3.43,8.99c-1.8,2.39-1.56,5.74,0.56,7.86l9.59,9.59c2.12,2.12,5.47,2.35,7.86,0.56L23,25.81L18.52,21.33z"/>
				</g>
				<g>
					<path class="st0" d="M20.36,19.17l4.71,4.71c0.3,0.29,0.3,0.77,0,1.07l-0.49,0.49c-0.3,0.29-0.77,0.29-1.07,0l-4.71-4.71c-0.29-0.3-0.29-0.77,0-1.07l0.49-0.49C19.58,18.88,20.06,18.88,20.36,19.17z"/>
					<path class="st0" d="M6.53,5.35l4.71,4.71c0.3,0.29,0.3,0.77,0,1.07l-0.49,0.49c-0.3,0.29-0.77,0.29-1.07,0L4.98,6.91c-0.29-0.3-0.29-0.77,0-1.07l0.49-0.49C5.76,5.05,6.24,5.05,6.53,5.35z"/>
				</g>
			</g>
			<g>
				<path class="st1" d="M16.18,9.27c2.74,0,4.97,2.23,4.97,4.97"/>
				<path class="st1" d="M16.18,5.75c4.69,0,8.49,3.8,8.49,8.49"/>
				<path class="st1" d="M16.18,2.13c6.69,0,12.11,5.42,12.11,12.11"/>
			</g>
		</g>
	</svg>
</template>

<script>
    export default {
        name: "CallSvg"
    }
</script>

<style scoped lang="scss">
	#CallSvg{
		.st0{fill:#66B0FF;}
		.st1{fill:none;stroke:#007CFF;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	}
</style>