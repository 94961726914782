<template>
    <svg version="1.1" id="DownloadSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
        <rect x="13" y="6" class="st0" width="6" height="9.82"/>
        <polygon class="st1" points="16,22.94 23.29,13.2 8.71,13.2 "/>
        <rect x="7" y="24.33" class="st0" width="18" height="1.67"/>
        <rect x="7" y="22.87" class="st1" width="4.46" height="1.46"/>
        <rect x="20.54" y="22.87" class="st1" width="4.46" height="1.46"/>
    </svg>
</template>

<script>
    export default {
        name: "DownloadSvg"
    }
</script>

<style scoped lang="scss">
    #DownloadSvg{
        .st0{fill:#007CFF;}
        .st1{fill:#66B0FF;}
    }
</style>